import React from 'react'
import Styles from './FullScreenLoader.module.scss'
import { Loader } from '../Loader/Loader'

export const FullScreenLoader = () => {
  return (
    <div className={Styles['full-screen-loader']}>
    <Loader size="lg" />
</div>
  )
}
