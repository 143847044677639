import { Box, Stack } from '@mui/material';
import React, { useCallback, useContext } from 'react';
import { Tile } from 'components/v2/atoms/Tile/Tile';
import { Link, useNavigate } from 'react-router-dom';
import { Loader } from 'components/v2/atoms/Loader/Loader';
import { useAdasMember } from 'hooks/useAdasMember';
import { MemberPortalConfigContext } from 'contexts/MemberPortalConfigContext';

export type SupportTileProps = {
    history: any;
};

export const SupportTile = ( ) => {
    const { loadingMember, member } = useAdasMember();
    const { memberPortalConfig } = useContext(MemberPortalConfigContext);
    const navigate = useNavigate();
    const handleClickViewDetails = useCallback(() => {
        navigate('/virtual-care');
    }, []);
    return (
        <Tile
            title="MEMBER SUPPORT"
            viewDetailsText="View Details"
            onViewDetailsClick={handleClickViewDetails}
            bodySize="fixed"
        >
            {loadingMember ? (
                <Stack height="100%" alignItems="center" justifyContent="center">
                    <Loader size="md" />
                </Stack>
            ) : (
                <>
                    <Stack direction="column" justifyContent="center" spacing={2} height="100%">
                        <h4 className="paragraph-title">
                            <strong>Concierge Services</strong>
                        </h4>
                        <Link to="/message-center" className="body-link">
                            Send your concierge a message
                        </Link>
                        <Box>
                            <strong>24/7 Concierge</strong>: {memberPortalConfig.conciergePhone}
                        </Box>
                        {member?.agentName && (
                            <Box>
                                <strong>Agent Name</strong>: {member.agentName}
                            </Box>
                        )}
                        <hr />
                        <h4 className="paragraph-title">
                            <strong>Member Services</strong>
                        </h4>
                        <Box>
                            <strong>{member?.language || 'English'}</strong>:{' '}
                            {memberPortalConfig.memberServicesPhone}
                        </Box>
                    </Stack>
                </>
            )}
        </Tile>
    );
};
