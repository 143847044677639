import React from 'react';
import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import {
    Button,
    Container,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from 'reactstrap';
import DropdownItemWithAria from 'hoc/DropdownMenu/DropdownItemWithAria';
import withIdentityData from 'hoc/withIdentityData';
import withPolicyData from 'hoc/withPolicyData';
import withUnreadMessageCount from 'hoc/withUnreadMessageCount';
import MessageCenterLink from 'components/molecules/MessageCenterLink';
import { sendCustomEvent } from 'common/utility';
import { AUDITEVENTTYPE } from 'services/trackService';
import { NotificationsDropdown } from '../NotificationsDropdown/NotificationsDropdown';
import withMemberNotifications from 'hoc/withMemberNotifications';
import useFeatureFlag from 'hooks/useFeatureFlag';

const UI_NOTIFICATIONS_FLAG = 'ui-notifications';
const Header = (props) => {
    const { instance: msalInstance } = useMsal();
    const { enabled, isFeatureFlagLoading } = useFeatureFlag(UI_NOTIFICATIONS_FLAG);
    return (
        <Container
            className="header d-none d-lg-flex"
            role="complementary"
            aria-label="Most top header"
        >
            <div className="d-flex flex-grow-0 align-items-center">
                <Link to="/">
                    <img src="img/logo.jpg" height={40} alt="Logo" />
                </Link>
            </div>
            <div className="d-flex flex-grow-1 ml-5 align-items-center">
                <span className="ml-5 mr-4 bold-text">
                    {props.member && props.member.first_name}{' '}
                    {props.member && props.member.last_name}
                </span>
                <span>
                    ID {props.identity && props.identity.memberId} |{' '}
                    {props.activePolicy && props.activePolicy.policy_name
                        ? `${props.activePolicy.policy_name} ${props.activePolicy.policy_number}`
                        : 'N/A'}
                </span>
            </div>
            <div className="d-flex flex-grow-0 align-items-center">
                {!isFeatureFlagLoading && enabled && (
                    <NotificationsDropdown
                        newNotificationsCount={props.newNotificationsCount}
                        memberNotifications={props.memberNotifications}
                        markAsReadMemberNotification={props.markAsReadMemberNotification}
                    />
                )}
                <MessageCenterLink unreadMessageCount={props.unreadMessageCount} />
                <UncontrolledDropdown className="mr-4">
                    <DropdownToggle nav aria-label="My Information">
                        <FontAwesomeIcon
                            icon={faUserCircle}
                            size="3x"
                            aria-label="User Profile Avatar"
                        />
                        <FontAwesomeIcon icon={faChevronDown} aria-label="Toggle Dropdown Arrow" />
                    </DropdownToggle>
                    <DropdownMenu end>
                        <Link className="text-dark" to="/account-information">
                            <DropdownItemWithAria>My Information</DropdownItemWithAria>
                        </Link>
                        <DropdownItem divider />
                        <Link className="text-dark" to="/communication-preferences">
                            <DropdownItemWithAria>Communication Preferences</DropdownItemWithAria>
                        </Link>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <Button
                    className="d-none d-lg-block"
                    color="primary"
                    onClick={(_) => {
                        sendCustomEvent({ eventType: AUDITEVENTTYPE.MemberPortalLogOut });
                        msalInstance.logoutRedirect();
                    }}
                >
                    Log Out
                </Button>
            </div>
        </Container>
    );
};

export default withMemberNotifications(
    withUnreadMessageCount(withPolicyData(withIdentityData(Header)))
);

export { Header };

Header.propTypes = {
    identity: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }),
};
