import React, { PureComponent } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleEnterKey } from '../../../common/accessibilityHelpers';

import styles from './CheckboxQuestion.module.scss';

class ComboCheckboxQuestion extends PureComponent {
    static displayName = ComboCheckboxQuestion.name;

    static propTypes = {
        error: PropTypes.bool,
        question: PropTypes.shape({
            title: PropTypes.string,
            info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
                icon: PropTypes.bool,
                disabled: PropTypes.bool,
            })
        ),
        onChange: PropTypes.func,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.array,
            PropTypes.object,
        ]),
        isColumn: PropTypes.bool,
        hraFlag: PropTypes.bool,
    };

    render() {
        const isChecked = (optionValue) => {
            const { value, hraFlag } = this.props;
            if (hraFlag) {
                const optionId = Object.keys(optionValue)[0];
                return value[optionId];
            } else {
                return value.includes(optionValue);
            }
        };
        return (
            <>
                {this.props.question ? (
                    <Row>
                        <Col>
                            <p
                                id={`label-combocheckbox-${this.props.id}`}
                                className={styles['question-title']}
                                data-testid="checkboxTitle"
                            >
                                {this.props.question.title}
                            </p>
                            <div className={styles.meta}>{this.props.question.info}</div>
                        </Col>
                    </Row>
                ) : null}
                <Row
                    role="group"
                    aria-labelledby={`label-combocheckbox-${this.props.id}`}
                    xs="1"
                    md="2"
                >
                    {this.props.options.map((option, i) => (
                        <Col key={i} sm={this.props.isColumn ? '12' : '3'}>
                            <input
                                className={styles.checkbox}
                                type="checkbox"
                                checked={isChecked(option.value)}
                                name={option.label}
                                onChange={(_) => this.props.onChange(option.value)}
                                id={option.label}
                                data-testid={option.label}
                                value={option.value}
                                disabled={option.disabled}
                                aria-labelledby={`label-combocheckbox-${i}-${option.label}`}
                            />
                            <label
                                className={styles.label}
                                htmlFor={option.label}
                                id={`label-combocheckbox-${i}-${option.label}`}
                                tabIndex="0"
                                onKeyUp={handleEnterKey(() => this.props.onChange(option.value))}
                            >
                                {option.label}
                            </label>
                            {option.icon && (
                                <FontAwesomeIcon icon={option.icon} className={styles.icon} />
                            )}
                        </Col>
                    ))}
                </Row>
                {this.props.error && (
                    <p style={{ color: 'red', marginTop: '2%' }}>This field is required</p>
                )}
            </>
        );
    }
}

export default ComboCheckboxQuestion;
