import React, { useCallback, useContext, useEffect, useRef } from 'react';
import PageHeader from '../molecules/PageHeader';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import ServerResponsiveTable from 'components/organisms/ServerResponsiveTable';
import useServerPagination from 'hooks/useServerPagination';
import { cmLetters$ } from 'services/subjects';
import { PDFViewerContext } from 'contexts/PdfViewerContext';
import { CMLetter } from 'interfaces/CMLetter';

const SERVICE_URL = 'api/healthsummary/caremanagementletters';
const INITIAL_TAKE = 10;

export type CareManagementLettersGridProps = {
    member?: {
        isSNP: boolean;
    };
    onLoad?: () => void;
};
export default function CareManagementLettersGrid({ member, onLoad }: CareManagementLettersGridProps) {
    const onLoadFired = useRef(false);
    const { addAndOpenDocument } = useContext(PDFViewerContext);
    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewHealthSummary })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);
    const { take, count, page, setPage, data, loading, dataLoadedOnce } = useServerPagination<CMLetter>({
        getData: cmLetters$,
        initialTake: INITIAL_TAKE,
        initialSortBy: 'createdAt',
        initialSortDirection: 'desc',
    });

    const handleDocumentClick = useCallback(
        (document: CMLetter) => {
            addAndOpenDocument({
                serviceUrl: SERVICE_URL,
                documentPath: document.id,
                displayName: document.fileName || document.templateName,
            });
        },
        [addAndOpenDocument]
    );

    useEffect(() => {
        if(!dataLoadedOnce || onLoadFired.current || !onLoad) {
            return;
        }
        onLoadFired.current = true;
        onLoad();
    }, [dataLoadedOnce]);

    return (
        <section id="caremanagement">
            <PageHeader>
                <span className="bold-text">Care Management Letters</span>
            </PageHeader>
            <p>
            This section contains important information from your care management team.  Here you can view letters from your care manager, your care plan including recommendations, next steps, health education, and updates regarding your ongoing medical needs.
            </p>
            <ServerResponsiveTable<CMLetter>
                data={data}
                loading={loading}
                boldRowIf={(data: CMLetter) => !data.memberPortalRead}
                fields={[
                    {
                        header: 'Name',
                        format: 'link',
                        name: 'category',
                        sortable: false,
                        onClick: handleDocumentClick,
                    },
                    {
                        header: 'Sent By',
                        name: 'createdByName',
                        sortable: false,
                        align: "center",
                    },
                    {
                        header: 'Created On',
                        format: 'date',
                        skeleton: 'YYYY-MM-DD',
                        name: 'createdAt',
                        sortable: false,
                        align: "right",
                    },
                ]}
                sortBy="createdAt"
                sortDirection="desc"
                pageNumber={page}
                setPageNumber={setPage}
                tableSize={count}
                pageSettings={{
                    pageSize: 10,
                    pageCount: take,
                }}
            />
        </section>
    );
}