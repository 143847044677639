import { memberAdas$ } from '../services/subjects';
import { AdasMember } from 'types/AdasMember';
import { useObservableQuery } from './useObservableQuery';
import { Observable } from 'rxjs';

export const useAdasMember = () => {
    const {
        data: member,
        loading: loadingMember,
        errorMessage: memberErrorMessage,
        reload: reloadMember,
    } = useObservableQuery({
        dependencies: [],
        query: (() => memberAdas$) as unknown as () => Observable<AdasMember>,
        initialValue: null,
        enabled: true,
    });

    return {
        member,
        loadingMember,
        memberErrorMessage,
        reloadMember,
    };
};
