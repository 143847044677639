import React from 'react';
import { Controller } from 'react-hook-form';
import RadioQuestion from '../../molecules/input/RadioQuestion';
import { useFormContext } from 'react-hook-form';
import { Question, QuestionTypes } from './util';
import ComboCheckboxQuestion from 'components/molecules/input/ComboCheckboxQuestion';
import styles from 'components/molecules/input/CheckboxQuestion.module.scss';
import { HraCheckBox } from './HRAInputs/HraCheckBox';
import { HraInputText } from './HRAInputs/HraTextInput';
import { Row } from 'reactstrap';
interface Props {
    question: Question;
}

export const HraQuestion = ({ question }: Props) => {
    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext();
    const values = watch();
    const questionValue = values[question.id];
    const questionNumber = question.questionNumber ? `${question.questionNumber}. ` : '';
    const questionTitle = `${questionNumber}${question.title}`;

    if (question.options.length == 0) {
        return <p className={styles['question-title']}>{questionTitle}</p>;
    }

    const getQuestionToDisplay = () => {
        const isRadioButton = question.options.some((o) => o.type == QuestionTypes.RADIO_BUTTON);
        if (isRadioButton) {
            return (
                <Controller
                    rules={{ required: question.required }}
                    key={question.id}
                    control={control}
                    name={question.id}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value }}) => (
                        <RadioQuestion
                            error={!!errors[question.id]}
                            hraFlag={true}
                            id={question.id}
                            key={question.id}
                            onChange={(e: any) => {
                                onChange(e);
                            }}
                            onBlur={onBlur}
                            value={value || ''}
                            question={questionTitle}
                            options={question.options.map((q) => ({
                                id: q.optionId,
                                label: q.text,
                                value: { [q.optionId]: q.text },
                            }))}
                        />
                    )}
                />
            );
        }
        return (
            <Controller
                rules={{
                    required: question.required,
                    validate: (value) =>
                        !question.required
                            ? true
                            : (value && Object.keys(value).length > 0) || 'error',
                }}
                control={control}
                name={question.id}
                defaultValue=""
                render={({ field: { onChange, onBlur, value }}) => (
                    <ComboCheckboxQuestion
                        error={errors[question.id]}
                        hraFlag={true}
                        isColumn={true}
                        onChange={(e: any) => {
                            const optionId = Object.keys(e)[0];
                            if (value[optionId]) {
                                const currentValue = value;
                                delete currentValue[optionId];
                                onChange(currentValue);
                            } else {
                                if (typeof value == 'object') {
                                    value[optionId] = e[optionId];
                                    onChange(value);
                                } else {
                                    onChange(e);
                                }
                            }
                        }}
                        question={{ title: questionTitle }}
                        onBlur={onBlur}
                        value={value || ''}
                        name={questionTitle}
                        options={question.options.map((o) => ({
                            label: o.text,
                            value: { [o.optionId]: o.text },
                        }))}
                    />
                )}
            />
        );
    };

    const followUpQuestionsValueHandler = (questionValues: any, fqValue: any, onChange: any) => {
        if (!questionValues) {
            onChange(fqValue);
            return;
        }
        const key = Object.keys(fqValue)[0];
        const value = fqValue[key];

        if (questionValues.hasOwnProperty(key)) {
            delete questionValues[key as any];
        } else {
            questionValues[key as any] = value;
        }
        onChange(questionValues);
    };
    const isFollowUpQuestionSelected = (questionValues: any, key: any) => {
        if (!questionValues) {
            return false;
        }
        if (questionValues?.hasOwnProperty(key)) {
            return true;
        } else {
            return false;
        }
    };

    const followUpQuestionTextValueHandler = (questionValues: any, fqValue: any, onChange: any) => {
        if (!questionValues) {
            onChange(fqValue);
            return;
        }

        const key = Object.keys(fqValue)[0];
        const value = fqValue[key];

        if (value?.length == 0) {
            delete questionValues[key as any];
        } else {
            questionValues[key as any] = value;
        }

        onChange(questionValues);
    };
    const displayFollowUpOptions = () => {
        if (!questionValue) return null;
        if (question.followUpQuestions?.length == 0) return null;
        const valuesSelected = Object.keys(questionValue);

        const followUpOptions = valuesSelected.some((value) =>
            question.followUpQuestions?.some((item) => item.item1.includes(value))
        );
        if (followUpOptions) {
            const followUpQuestionsArray = question.followUpQuestions[0]?.item2;
            return followUpQuestionsArray.map((followUpQuestions, i) => {
                const isRadioButton = followUpQuestions.options.some(
                    (o) => o.type == QuestionTypes.RADIO_BUTTON
                );

                if (isRadioButton) {
                    return (
                        <Controller
                            rules={{ required: followUpQuestions.required }}
                            key={followUpQuestions.id}
                            control={control}
                            name={followUpQuestions.id}
                            defaultValue=""
                            render={({ field: { onChange, onBlur, value }}) => (
                                <RadioQuestion
                                    error={!!errors[followUpQuestions.id]}
                                    hraFlag={true}
                                    id={followUpQuestions.id}
                                    key={followUpQuestions.id}
                                    onChange={(e: any) => {
                                        onChange(e);
                                    }}
                                    onBlur={onBlur}
                                    value={value || ''}
                                    question={followUpQuestions.title}
                                    options={followUpQuestions.options.map((q) => ({
                                        id: q.optionId,
                                        label: q.text,
                                        value: { [q.optionId]: q.text },
                                    }))}
                                />
                            )}
                        />
                    );
                }

                return (
                    <div style={{ padding: '2%' }} key={`div ${i}`}>
                        <p className={styles['question-title']} key={`question-title ${i}`}>
                            {followUpQuestions.title}
                        </p>
                        <Row xs="1" sm="3" md="3" xl="3" key={`question-title Row ${i}`}>
                            <Controller
                                key={`Controller Row ${i}`}
                                rules={{
                                    required: followUpQuestions.required,
                                    validate: (value) =>
                                        !followUpQuestions.required
                                            ? true
                                            : (value && Object.keys(value).length > 0) || 'error',
                                }}
                                control={control}
                                name={followUpQuestions.id}
                                defaultValue={false}
                                render={({ field: { onChange, value }}) => {
                                    return (
                                        <>
                                            {followUpQuestions.options.map((question, i) => {
                                                if (question.type == QuestionTypes.CHECK_BOX) {
                                                    const questionValue = values[question.optionId];
                                                    return (
                                                        <HraCheckBox
                                                            checked={isFollowUpQuestionSelected(
                                                                value,
                                                                question.optionId
                                                            )}
                                                            key={'HraCheckBox' + i}
                                                            question={question}
                                                            value={questionValue}
                                                            onChange={(fqValue) => {
                                                                followUpQuestionsValueHandler(
                                                                    value,
                                                                    fqValue,
                                                                    onChange
                                                                );
                                                            }}
                                                        />
                                                    );
                                                }

                                                if (question.type == QuestionTypes.TEXT) {
                                                    const questionSelected =
                                                        values[followUpQuestions.id];
                                                    const questionValue = questionSelected
                                                        ? questionSelected[question.optionId]
                                                        : '';
                                                    return (
                                                        <HraInputText
                                                            key={'HraInputText' + i}
                                                            question={question}
                                                            value={questionValue}
                                                            onChange={(fqValue) => {
                                                                followUpQuestionTextValueHandler(
                                                                    value,
                                                                    fqValue,
                                                                    onChange
                                                                );
                                                            }}
                                                        />
                                                    );
                                                }
                                                return null;
                                            })}
                                        </>
                                    );
                                }}
                            />
                        </Row>
                        {errors[followUpQuestions.id] && (
                            <p style={{ color: 'red', marginTop: '2%' }} key={`error Row ${i}`}>
                                This field is required
                            </p>
                        )}
                    </div>
                );
            });
        }
    };

    return (
        <>
            {getQuestionToDisplay()}
            {displayFollowUpOptions()}
        </>
    );
};
