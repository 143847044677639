import React from 'react';
import { Grid } from '@mui/material';
import { CardTile } from 'components/v2/molecules/CardTile/CardTile';
import { SupportTile } from 'components/v2/molecules/SupportTile/SupportTile';
import { PCPTile } from 'components/v2/molecules/PcpTile/PcpTile';

export const HomeTiles = () => {
    return (
        <section>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <CardTile />
            </Grid>
            <Grid item xs={12} md={6}>
                <SupportTile />
            </Grid>
            <Grid item xs={12} md={6}>
                <PCPTile />
            </Grid>
        </Grid>
        </section>
    );
};
