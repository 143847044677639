import JsaAppointmentDateAndTimeStep from './JsaAppointmentDateAndTimeStep';
import JsaAppointmentContactandDetailsStep from './JsaAppointmentContactandDetailsStep';
import JsaAppointmentSubmitStep from './JsaAppointmentSubmitStep';

const patientFormDefaultValues = {
    patientEmail: '',
    patientPhone: '',
    notificationPreferences: 0,
    appointmentNotes: '',
    consent: 1,
    translatorPreferredLanguage: '',
    translatorRequired: 0,
    dateTimeOption: 0,
    preferredDate: '',
    preferredTimeOfDay: [],
};

const PAGES = {
    DATE_AND_TIME: 0,
    CONTACT_AND_DETAILS: 1,
    CONFIRM_AND_BOOK: 2,
};

const steps = [
    { title: 'Date and Time', page: PAGES.DATE_AND_TIME },
    { title: 'Contact and Details', page: PAGES.CONTACT_AND_DETAILS },
    { title: 'Confirm & Book', page: PAGES.CONFIRM_AND_BOOK },
];


// It's not redux, but similar. These are Actions used with useReducer in Wizard.
const ACTIONS = {
    RESET: 'RESET',
    FINISHED_DATE_AND_TIME: 'FINISHED_DATE_AND_TIME',
    FINISHED_CONTACT_AND_DETAILS: 'FINISHED_CONTACT_AND_DETAILS',
    FINISHED_CONFIRM_AND_BOOK: 'FINISHED_CONFIRM_AND_BOOK',
    GOTO: 'GOTO',
};

// This stores the entire form data. React Hook Forms will pass this as argument in handleSubmit((here)=>{}).
const initialFormState = {
    ...patientFormDefaultValues,
    submitted: false,
    page: PAGES.DATE_AND_TIME,
};

// This gives the metadata of page component, and what actions to dispatch for going back / prev page.
const pageMetaData = {
    [PAGES.DATE_AND_TIME]: {
        showProgressBar: true,
        component: JsaAppointmentDateAndTimeStep,
        nextAction: ACTIONS.FINISHED_DATE_AND_TIME,
        prevPage: PAGES.DATE_AND_TIME,
    },
    [PAGES.CONTACT_AND_DETAILS]: {
        showProgressBar: true,
        component: JsaAppointmentContactandDetailsStep,
        nextAction: ACTIONS.FINISHED_CONTACT_AND_DETAILS,
        prevPage: PAGES.DATE_AND_TIME,
    },
    [PAGES.CONFIRM_AND_BOOK]: {
        showProgressBar: true,
        component: JsaAppointmentSubmitStep,
        nextAction: ACTIONS.FINISHED_CONFIRM_AND_BOOK,
        prevPage: PAGES.CONTACT_AND_DETAILS,
    },
};

// Reducer, exactly like how redux work. Actions will affect the state of the initialState data.
const reducer = (state: any, action: any) => {
    switch (action.type) {
        case ACTIONS.RESET:
            return initialFormState;
        case ACTIONS.FINISHED_DATE_AND_TIME:
            return {
                ...state,
                ...action.payload,
                page: PAGES.CONTACT_AND_DETAILS,
            };
        case ACTIONS.FINISHED_CONTACT_AND_DETAILS:
            return {
                ...state,
                ...action.payload,
                page: PAGES.CONFIRM_AND_BOOK,
            };
        case ACTIONS.FINISHED_CONFIRM_AND_BOOK:
            return {
                ...state,
                submitted: true,
            };
        case ACTIONS.GOTO:
            return {
                ...state,
                page: action.payload.page,
                ...action.payload,
            };
        default:
            return state;
    }
};
export { initialFormState, ACTIONS, reducer, pageMetaData, steps, PAGES };
