import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
const ALIAS_FOR_EMAIL_COMMUNICATION_TYPE = 'GO PAPERLESS';

const PreferenceOptionRadio = ({
    option,
    campaignName,
    category,
    invalidEmail,
    onChange,
    cmUMFeatureFlagIsActive,
}) => {
    return (
        <FormGroup>
            <Input
                disabled={(option.communicationType === 'EMAIL' && invalidEmail) || !option.enabled}
                key={`${option.communicationType}_${campaignName}`}
                multiple={true}
                id={`${option.communicationType}_${campaignName}`}
                className="d-flex"
                type="radio"
                name={`${category}_${campaignName}`}
                checked={option.active}
                value={`${category}_${campaignName}_${option.communicationType}`}
                onChange={() => {
                    if (option.checked) {
                        return;
                    }
                    onChange({ ...option, active: true }, option.communicationType === 'EMAIL');
                }}
            />
            <Label htmlFor={option.communicationType} check>
                {option.communicationType === 'EMAIL' && cmUMFeatureFlagIsActive ? ALIAS_FOR_EMAIL_COMMUNICATION_TYPE : option.communicationType}
            </Label>
        </FormGroup>
    );
};

export default PreferenceOptionRadio;
