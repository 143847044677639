import React from 'react';
import { VirtualAppointmentCard } from '../../../../../interfaces/VirtualAppointmentCard';
import { Col, Row } from 'reactstrap';
import styles from './AppointmentCard.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPhone, faVideo } from '@fortawesome/free-solid-svg-icons';
import promptService from '../../../../../services/promptService';
import loadOverlayService from '../../../../../services/loadOverlayService';
import { Subject } from 'rxjs';
import { useSubscription } from '../../../../../hooks/useSubscription';
import { map } from 'rxjs/operators';
import { handleEnterKeyToClick } from '../../../../../common/accessibilityHelpers';
import { formatIdString } from '../../../../../utils/accessibility';
import ics from '../../../../../common/ics';
import { dateToStr } from '../../../../../common/utility';

interface AppointmentCardProps {
    appointment: VirtualAppointmentCard;
    cancelAppointment: (cancelRequest: { id: string; isRequest: boolean }) => void;
    onAppointmentCancellationCompleted: () => void;
    refreshAppointments: () => void;
}

export const AppointmentCard = ({
    appointment,
    cancelAppointment,
    onAppointmentCancellationCompleted,
    refreshAppointments,
}: AppointmentCardProps) => {
    const cancelAppointmentClicked$ = new Subject<{ id: string; isRequest: boolean }>();

    useSubscription(
        cancelAppointmentClicked$.pipe(map(cancelAppointment)),
        (_) => {
            onAppointmentCancellationCompleted();
            loadOverlayService.hide();
            refreshAppointments();
        },
        () => {
            loadOverlayService.hide();
        }
    );

    const getAppointmentIcon = () => {
        if (appointment.visitType == 'Phone') return faPhone;
        if (appointment.visitType == 'Video') return faVideo;
        return faHome;
    };

    const onCancelDialogShow = () => {
        promptService.open({
            title:
                'Are you sure you want to cancel your ' +
                (appointment.isRequest ? 'request?' : 'virtual appointment?'),
            onActionConfirmed: handleCancellationConfirmed,
            onActionDeclined: handleCancellationDeclined,
        });
    };

    const handleCancellationConfirmed = () => {
        promptService.close();
        loadOverlayService.show('Canceling...');
        cancelAppointmentClicked$.next({ id: appointment.id, isRequest: appointment.isRequest });
    };

    const handleCancellationDeclined = () => {
        promptService.close();
    };

    const generateAriaLabels = (id: string) => {
        const idStr = formatIdString(id);
        return {
            labelContainerIdStr: `label-appointment-container-${idStr}`,
            labelIdStr: `label-appointment-${idStr}`,
            labelFooterIdStr: `label-appointment-footer-${idStr}`,
        };
    };

    /** download the ICS file for the appointment we clicked */
    const onClickDownloadICS = () => {
        let startTime = new Date(appointment.startDate),
            endTime = new Date(appointment.endDate);
        const newEvent = {
            subject: 'Virtual Care',
            description: `Type: ${appointment.visitTypeDescription}; Reason: ${appointment.description}`,
            location: 'Online',
            begin: startTime.toISOString(),
            stop: endTime.toISOString(),
        };
        let cal = ics();
        if (!cal) {
            return;
        }
        cal.addEvent(
            newEvent.subject,
            newEvent.description,
            newEvent.location,
            newEvent.begin,
            newEvent.stop,
            null
        );
        cal.download(`virtual-care-${dateToStr(startTime)}`, 'ics');
    };

    const { labelContainerIdStr, labelFooterIdStr, labelIdStr } = generateAriaLabels(
        appointment.id
    );

    return (
        <div>
            <article
                className={styles.appointment}
                style={appointment.isRequest ? { border: '1px solid rgb(255,215,215)' } : {}}
                aria-label={labelContainerIdStr}
            >
                <article className={styles['content-container']} aria-label={labelIdStr}>
                    <Row>
                        <Col xs="3">
                            <div data-testid="appointmentDate">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    {appointment.startDateTime ? (
                                        <>
                                            <span className={styles.month}>
                                                {appointment.month}
                                            </span>
                                            <span className={styles.day}>{appointment.day}</span>
                                            <span className={styles.date}>{appointment.date}</span>
                                        </>
                                    ) : (
                                        <span className={styles.date}>Date Pending</span>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col xs="6">
                            <div data-testid="appointmentDesc">
                                <div className={styles['appointment-desc']}>
                                    {appointment.description}
                                </div>
                                <div>{appointment.visitReason}</div>
                                <div>{appointment?.phoneNumber}</div>
                                {appointment.time ? (
                                    <>
                                        <div className={styles['appointment-desc']}>
                                            {appointment.timeRange}
                                        </div>
                                        <div className="mt-2">
                                            <span
                                                className={styles['link-alike']}
                                                onClick={onClickDownloadICS}
                                                onKeyUp={handleEnterKeyToClick}
                                                tabIndex={0}
                                            >
                                                Add to Calendar
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <div className={styles['appointment-desc']}>Time Pending</div>
                                )}
                            </div>
                        </Col>
                        <Col xs="3">
                            {appointment.joinUrl ? (
                                <div
                                    data-testid="appointmentIcon"
                                    onClick={() =>
                                        appointment.joinUrl && window.open(appointment.joinUrl)
                                    }
                                    onKeyUp={handleEnterKeyToClick}
                                    tabIndex={0}
                                    aria-label="Click to open Telehealth site"
                                >
                                    <div
                                        className={`${styles['icon-container']} ${styles['pointer']} `}
                                    >
                                        <FontAwesomeIcon
                                            icon={getAppointmentIcon()}
                                            className={styles.icon}
                                            aria-label="Go to Telehealth site"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className={styles['icon-container']}>
                                    <FontAwesomeIcon
                                        icon={getAppointmentIcon()}
                                        className={styles.icon}
                                        aria-label="Go to Telehealth site"
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </article>

                <article
                    className={
                        `${styles.footer} ` +
                        (appointment.isRequest ? `${styles['footer-request']}` : '')
                    }
                    aria-label={labelFooterIdStr}
                >
                    {appointment.canCancel && (
                        <span
                            data-testid="cancelAppointmentLink"
                            className={styles['link-alike']}
                            onClick={onCancelDialogShow}
                            onKeyUp={handleEnterKeyToClick}
                            tabIndex={0}
                        >
                            Cancel {appointment.isRequest ? 'Request' : 'Appointment'}
                        </span>
                    )}
                </article>
            </article>
        </div>
    );
};
