import React from 'react';
import { memberAdas$ } from '../services/subjects';
import BaseComponent from '../components/BaseComponent';

export default function withAdasMemberData(WrappedComponent) {
    return class extends BaseComponent {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            this.subscribe(memberAdas$, { next: (member) => this.setState({ member }) });

            memberAdas$.connect();
        }

        render() {
            const { member } = this.state;
            return <WrappedComponent member={member} {...this.props} />;
        }
    };
}
