import React from 'react';
import { member$ } from '../services/subjects';
import BaseComponent from '../components/BaseComponent';

export default function withMemberData(WrappedComponent) {
    return class extends BaseComponent {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            this.subscribe(member$, {
                next: (member) => {
                    this.setState({ ...this.state.member, member });
                },
            });
            member$.connect();
        }

        render() {
            const { member } = this.state;
            return <WrappedComponent member={member} {...this.props} />;
        }
    };
}
