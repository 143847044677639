/** libs */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faPhone, faHome } from '@fortawesome/free-solid-svg-icons';
import ics from '../../common/ics';
import { dateToStr, getTimeZone } from '../../common/utility';
import BaseComponent from '../BaseComponent';
import { formatIdString } from '../../utils/accessibility';
import { handleEnterKeyToClick } from '../../common/accessibilityHelpers';
import loadOverlayService from '../../services/loadOverlayService';

/** styles */
import styles from './Appointment.module.scss';
import promptService from 'services/promptService';

/** @typedef State
 * @property {boolean} isCancelDialogShown
 * @property {string[]} types
 */

/**
 * Appointment component to present appointment info before user
 * @component Appointment
 * */
class Appointment extends BaseComponent {
    static displayName = Appointment.name;
    static propTypes = {
        /** appointment object */
        appointment: PropTypes.shape({
            startTime: PropTypes.oneOfType([
                PropTypes.instanceOf(Date),
                PropTypes.number,
                PropTypes.string,
                PropTypes.object,
            ]),
            endTime: PropTypes.oneOfType([
                PropTypes.instanceOf(Date),
                PropTypes.number,
                PropTypes.string,
                PropTypes.object,
            ]),
            complaint: PropTypes.string,
            memberNotes: PropTypes.string,
            handleGoToAppointment: PropTypes.func,
            visitSummary: PropTypes.shape({
                mainComplaint: PropTypes.string,
                prescribedMedications: PropTypes.string,
                notes: PropTypes.string,
            }),
            reason: PropTypes.string,
            type: PropTypes.string,
            providerFullName: PropTypes.string,
            memberPhone: PropTypes.string,
        }),
    };

    /** If future date map it to a proper description
     * @param {Date} date
     * @returns {string} - Date as string
     */
    mapDate = (date) => {
        const today = moment().endOf('day');
        return date < today ? 'Today' : moment(date).format('dddd');
    };

    constructor(props) {
        super(props);

        /** @type {State} */
        this.state = {
            isCancelDialogShown: false,
            types: [],
        };
    }

    /** download the ICS file for the appointment we clicked */
    onClickDownloadICS = () => {
        const { appointment } = this.props;

        let startTime = new Date(appointment.startTime),
            endTime = new Date(appointment.endTime);
        const newEvent = {
            subject: 'Virtual Care',
            description: `Type: ${appointment.type}; Reason: ${appointment.reason}`,
            location: 'Online',
            begin: startTime,
            stop: endTime,
        };
        let cal = ics();
        cal.addEvent(
            newEvent.subject,
            newEvent.description,
            newEvent.location,
            newEvent.begin,
            newEvent.stop
        );
        cal.download(`virtual-care-${dateToStr(startTime)}`);
    };

    getAppointmentIcon = () => {
        const { appointment } = this.props;
        if (appointment.type == 'Phone') return faPhone;
        if (appointment.type == 'Video') return faVideo;
        return faHome;
    };

    onCancelDialogShow = () => {
        promptService.open({
            title:
                'Are you sure you want to cancel your ' +
                (this.props.appointment.startTime !== undefined
                    ? 'virtual appointment?'
                    : 'request?'),
            onActionConfirmed: this.handleCancellationConfirmed,
            onActionDeclined: this.handleCancellationDeclined,
        });
    };

    handleCancellationConfirmed = () => {
        promptService.close();
        loadOverlayService.show('Canceling...');
        this.subscriptions.push(
            this.props.cancelAppointment(this.props.appointment.id).subscribe({
                next: () => {
                    this.props.onAppointmentCancellationCompleted();
                    loadOverlayService.hide();
                    this.props.refreshAppointments();
                },
                error: () => {
                    loadOverlayService.hide();
                },
            })
        );
    };

    handleCancellationDeclined = () => {
        promptService.close();
    };

    /**
     * ARIA label generator function
     */
    generateAriaLabels = (id) => {
        const idStr = formatIdString(id);
        return {
            labelContainerIdStr: `label-appointment-container-${idStr}`,
            labelIdStr: `label-appointment-${idStr}`,
            labelFooterIdStr: `label-appointment-footer-${idStr}`,
        };
    };

    render() {
        const { appointment, onGoToAppointment } = this.props;

        if (!appointment) {
            return;
        }

        const momentStartTime = moment(appointment?.startTime);
        const momentEndTime = moment(appointment?.endTime);
        const momentDate = moment(appointment?.startTime);

        const { labelContainerIdStr, labelFooterIdStr, labelIdStr } = this.generateAriaLabels(
            appointment.id
        );
        return (
            <article
                className={styles.appointment}
                style={
                    appointment.startTime === undefined
                        ? { border: '1px solid rgb(255,215,215)' }
                        : {}
                }
                aria-label={labelContainerIdStr}
            >
                <article className={styles['content-container']} aria-label={labelIdStr}>
                    <Row>
                        <Col xs="3">
                            <div data-testid="appointmentDate">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    {appointment.startTime ? (
                                        <>
                                            <span className={styles.month}>
                                                {momentDate.format('MMM')}
                                            </span>
                                            <span className={styles.day}>
                                                {momentDate.format('D')}
                                            </span>
                                            <span className={styles.date}>
                                                {this.mapDate(appointment?.startTime)}
                                            </span>
                                        </>
                                    ) : (
                                        <span className={styles.date}>Date Pending</span>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col xs="6">
                            <div data-testid="appointmentDesc">
                                <div className={styles['appointment-desc']}>
                                    {appointment.type} Appointment
                                    {appointment.providerFullName
                                        ? ` with ${appointment.providerFullName}`
                                        : ''}
                                </div>
                                <div>
                                    {(appointment?.isRequest
                                        ? appointment?.requestReasonDescription
                                        : appointment?.memberPortalCreateAppointmentRequest
                                              ?.reasonDescription ||
                                          appointment?.reasonDescription) || 'Unknown'}
                                </div>
                                <div>{appointment?.memberPhone}</div>
                                {appointment.startTime ? (
                                    <>
                                        <div className={styles['appointment-desc']}>
                                            {momentStartTime.format('h:mm a')} -{' '}
                                            {momentEndTime.format('h:mm a')} {getTimeZone()}
                                        </div>
                                        <div className="mt-2">
                                            <span
                                                className={styles['link-alike']}
                                                onClick={this.onClickDownloadICS}
                                                onKeyUp={handleEnterKeyToClick}
                                                tabIndex="0"
                                            >
                                                Add to Calendar
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <div className={styles['appointment-desc']}>Time Pending</div>
                                )}
                            </div>
                        </Col>
                        <Col xs="3">
                            {onGoToAppointment ? (
                                <div
                                    data-testid="appointmentIcon"
                                    onClick={() => onGoToAppointment(appointment)}
                                    onKeyUp={handleEnterKeyToClick}
                                    tabIndex="0"
                                    aria-label="Click to open Telehealth site"
                                >
                                    <div
                                        className={`${styles['icon-container']} ${styles['pointer']} `}
                                    >
                                        <FontAwesomeIcon
                                            icon={this.getAppointmentIcon()}
                                            className={styles.icon}
                                            aria-label="Go to Telehealth site"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className={styles['icon-container']}>
                                    <FontAwesomeIcon
                                        icon={this.getAppointmentIcon()}
                                        className={styles.icon}
                                        aria-label="Go to Telehealth site"
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </article>
                <article
                    className={
                        `${styles.footer} ` +
                        (appointment.startTime === undefined ? `${styles['footer-request']}` : '')
                    }
                    aria-label={labelFooterIdStr}
                >
                    {(!['Completed', 'Started'].includes(appointment.status) || null) && (
                        <span
                            data-testid="cancelAppointmentLink"
                            className={styles['link-alike']}
                            onClick={this.onCancelDialogShow}
                            onKeyUp={handleEnterKeyToClick}
                            tabIndex="0"
                        >
                            Cancel {appointment.startTime === undefined ? 'Request' : 'Appointment'}
                        </span>
                    )}
                </article>
            </article>
        );
    }
}

export default Appointment;
