import React, { Component } from 'react';
import {
    Collapse,
    Container,
    Navbar,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Row,
    Col,
    Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { withMsal } from '@azure/msal-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { featureFlagsNames } from 'common/constants';
import DropdownItemWithAria from 'hoc/DropdownMenu/DropdownItemWithAria';
import withFeatureFlags from 'hoc/withFeatureFlags';
import withIdentityData from 'hoc/withIdentityData';
import withMemberData from 'hoc/withMemberData';
import withPolicyData from 'hoc/withPolicyData';
import withUnreadMessageCount from 'hoc/withUnreadMessageCount';
import MessageCenterLink from 'components/molecules/MessageCenterLink';
import { env$ } from 'services/subjects';
import {
    getPrimeLink,
    PrimeLandingPages,
    payspanLink,
    accessCardLink,
    SafeRideLandingPages,
    getSafeRideLink,
    getOptumLink,
    getNationsLink,
} from 'services/ssoService';
import './NavMenu.scss';
import { AUDITEVENTTYPE } from 'services/trackService';
import { sendCustomEvent } from 'common/utility';
import configurationService from 'services/configurationService';
import { map } from 'rxjs';

const CM_UM_NOTIFICATIONS_FLAG = 'cm-um-notifications';
const HEALTH_RISK_ASSESSMENT = 'member-health-risk-assessment';

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            windowWidth: null,
            cmUmNotificationFlagEnabled: false,
            healthRiskAssessmentFeatureFlag: false,
        };
        window.addEventListener('resize', this.setWindowWidth);
    }

    componentDidMount() {
        env$.subscribe({
            next: (env) => this.setState({ env }),
        });
        this.setWindowWidth();
        const getFeatureFlagsSubscription = configurationService
            .getFeatureFlags$()
            .pipe(
                map((response) => response.response),
                map((flags) => {
                    if (!flags) {
                        flags = [];
                    }

                    var result = {};

                    for (var i = 0; i < flags.length; i++) {
                        result[flags[i].name] = flags[i].isActive;
                    }

                    return result;
                })
            )
            .subscribe(
                (featureFlags) => {
                    getFeatureFlagsSubscription.unsubscribe();
                    this.setState({ 
                        cmUmNotificationFlagEnabled: featureFlags?.[CM_UM_NOTIFICATIONS_FLAG] || false,
                        healthRiskAssessmentFeatureFlag: featureFlags?.[HEALTH_RISK_ASSESSMENT] || false,
                    });
                },
                (error) => {
                    console.error(error);
                    getFeatureFlagsSubscription.unsubscribe();
                }
            );
    }

    setWindowWidth = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    getIsOpen = () => {
        if (this.state.windowWidth !== null && this.state.windowWidth >= 992) {
            return true;
        }
        return !this.state.collapsed;
    };

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    CollapseLinkItem = ({ children, ...rest }) => (
        <Link onClick={() => this.toggleNavbar()} {...rest}>
            <DropdownItemWithAria>{children}</DropdownItemWithAria>
        </Link>
    );

    render() {
        let alignmentWebSite = `https://${
            this.state.env === 'prod' ? 'www' : 'test'
        }.alignmenthealthplan.com`;

        let providerSearchSite =
            this.state.env === 'prod'
                ? 'https://providersearch.alignmenthealthplan.com'
                : 'https://ava-providersearch-uat.azurewebsites.net';
        return (
            <header>
                <Navbar
                    className="navbar-expand-lg navbar-toggleable-lg ng-white border-bottom box-shadow"
                    light
                    aria-label="Main navigation menus"
                >
                    <Container>
                        <Row className="flex-grow-1 d-lg-none">
                            <Col
                                xs="12"
                                className="d-flex flex-grow-1 justify-content-between align-items-center pb-2 pt-2"
                            >
                                <img
                                    className="d-lg-none"
                                    src="img/logo.jpg"
                                    height={40}
                                    alt="Logo"
                                />
                                <MessageCenterLink
                                    unreadMessageCount={this.props.unreadMessageCount}
                                />
                                <NavbarToggler
                                    onClick={this.toggleNavbar}
                                    className={`${this.state.collapsed ? '' : 'ahc--expanded'}`}
                                />
                            </Col>
                            <Col
                                xs="12"
                                className={`d-flex d-lg-none flex-column align-items-stretch flex-grow-1 border-top pb-2 pt-2`}
                            >
                                <span className="bold-text">
                                    Welcome {this.props.member && this.props.member.first_name}
                                </span>
                                <span>
                                    <span className="bold-text">Member ID: </span>
                                    {this.props.identity && this.props.identity.memberId}
                                </span>
                                <span className="bold-text">
                                    {this.props.policies &&
                                        this.props.policies.length &&
                                        `${this.props.policies[0].policy_name}-${this.props.policies[0].policy_number}`}
                                </span>
                            </Col>
                        </Row>
                        <Collapse
                            className="d-lg-inline-flex flex-lg-row-reverse row"
                            isOpen={this.getIsOpen()}
                            navbar
                        >
                            <ul className="navbar-nav flex-grow col-12 justify-items-between ">
                                <NavItem>
                                    <NavLink onClick={() => this.toggleNavbar()} tag={Link} to="/">
                                        Home
                                    </NavLink>
                                </NavItem>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav>
                                        Plan &amp; Benefits
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            aria-label="Toggle Dropdown Arrow"
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <this.CollapseLinkItem className="text-dark" to="/benefits">
                                            Benefit Highlights
                                        </this.CollapseLinkItem>
                                        <DropdownItem divider />
                                        <this.CollapseLinkItem className="text-dark" to="/id-card">
                                            My ID Card
                                        </this.CollapseLinkItem>
                                        <DropdownItem divider />
                                        <DropdownItemWithAria
                                            href={accessCardLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={() => {
                                                sendCustomEvent({
                                                    eventType:
                                                        AUDITEVENTTYPE.MemberPortalViewMyAccessCardDropdown,
                                                });
                                                sendCustomEvent({
                                                    eventType: AUDITEVENTTYPE.MemberPortalSSOInComm,
                                                });
                                            }}
                                        >
                                            My ACCESS card balance
                                        </DropdownItemWithAria>
                                        {this.props.featureFlags[
                                            featureFlagsNames.mailOrderOtcLink
                                        ] && (
                                            <>
                                                <DropdownItem divider />
                                                <DropdownItemWithAria
                                                    href={getNationsLink()}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onClick={sendCustomEvent.bind(
                                                        {},
                                                        {
                                                            eventType:
                                                                AUDITEVENTTYPE.MemberPortalSSOtoOTCMailOrder,
                                                        }
                                                    )}
                                                >
                                                    Mail Order OTC
                                                </DropdownItemWithAria>
                                            </>
                                        )}
                                        {this.props.instacartUrl && (
                                            <>
                                                <DropdownItem divider />
                                                <DropdownItemWithAria
                                                    href={this.props.instacartUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onClick={sendCustomEvent.bind(
                                                        {},
                                                        {
                                                            eventType:
                                                                AUDITEVENTTYPE.MemberPortalSSOtoInstacart,
                                                        }
                                                    )}
                                                >
                                                    Shop Instacart
                                                </DropdownItemWithAria>
                                            </>
                                        )}
                                        <DropdownItem divider />
                                        <this.CollapseLinkItem
                                            className="text-dark"
                                            to="/out-of-pocket"
                                        >
                                            Maximum Out-of-Pocket Expenses
                                        </this.CollapseLinkItem>
                                        {this.props.featureFlags[
                                            featureFlagsNames.memberSafeRideSSO
                                        ] && (
                                            <>
                                                <DropdownItem divider />
                                                <DropdownItemWithAria
                                                    href={getSafeRideLink(
                                                        SafeRideLandingPages.SCHEDULETRANSPORTATION
                                                    )}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onClick={sendCustomEvent.bind(
                                                        {},
                                                        {
                                                            eventType:
                                                                AUDITEVENTTYPE.MemberPortalSSOSafeRide,
                                                        }
                                                    )}
                                                >
                                                    Schedule Transportation
                                                </DropdownItemWithAria>
                                            </>
                                        )}
                                        {this.props.hasOptumBehavioralHealth ? (
                                            <>
                                                <DropdownItem divider />
                                                <DropdownItemWithAria
                                                    href={getOptumLink()}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onClick={sendCustomEvent.bind(
                                                        {},
                                                        {
                                                            eventType:
                                                                AUDITEVENTTYPE.MemberPortalSSOtoOptum,
                                                        }
                                                    )}
                                                >
                                                    Behavioral Health
                                                </DropdownItemWithAria>
                                            </>
                                        ) : null}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav>
                                        Doctors &amp; Care
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            aria-label="Toggle Dropdown Arrow"
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <this.CollapseLinkItem
                                            className="text-dark"
                                            onClick={sendCustomEvent.bind(
                                                {},
                                                {
                                                    eventType:
                                                        AUDITEVENTTYPE.MemberPortalSSOToTeledoc,
                                                }
                                            )}
                                            to="/urgent-care-appointment-form"
                                        >
                                            Access 24/7 Doctor
                                        </this.CollapseLinkItem>
                                        <DropdownItem divider />
                                        <DropdownItemWithAria
                                            href={providerSearchSite}
                                            target="_blank"
                                            onClick={sendCustomEvent.bind(
                                                {},
                                                {
                                                    eventType:
                                                        AUDITEVENTTYPE.MemberPortalFindAProvider,
                                                }
                                            )}
                                        >
                                            Find a provider
                                        </DropdownItemWithAria>
                                        {!this.props.featureFlags[
                                            featureFlagsNames.disablePcpChange
                                        ] && (
                                            <>
                                                <DropdownItem divider />
                                                <this.CollapseLinkItem
                                                    className="text-dark"
                                                    to="/pcp-change"
                                                >
                                                    Change My PCP
                                                </this.CollapseLinkItem>
                                            </>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav>
                                        My Health
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            aria-label="Toggle Dropdown Arrow"
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <this.CollapseLinkItem
                                            className="text-dark"
                                            to="/health-summary"
                                        >
                                            My Health Summary
                                        </this.CollapseLinkItem>
                                        {this.state.cmUmNotificationFlagEnabled && (
                                            <>
                                                <DropdownItem divider />
                                                <this.CollapseLinkItem
                                                    className="text-dark"
                                                    to="/health-summary#caremanagement"
                                                >
                                                    Care Management Letters
                                                </this.CollapseLinkItem>
                                                <DropdownItem divider />
                                                <this.CollapseLinkItem
                                                    className="text-dark"
                                                    to="/health-summary#referrals"
                                                >
                                                    Referral Notifications
                                                </this.CollapseLinkItem>
                                            </>
                                        )}
                                        {this.state.healthRiskAssessmentFeatureFlag && this.props.member?.isSNP && (
                                            <>
                                                <DropdownItem divider />
                                                <this.CollapseLinkItem
                                                    className="text-dark"
                                                    to="/health-risk-assessment"
                                                >
                                                    Health Risk Assessment
                                                </this.CollapseLinkItem>
                                            </>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav>
                                        Prescriptions
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            aria-label="Toggle Dropdown Arrow"
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItemWithAria
                                            href={getPrimeLink(PrimeLandingPages.FINDAPHARMACY)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Find a Pharmacy
                                        </DropdownItemWithAria>
                                        <DropdownItem divider />
                                        <DropdownItemWithAria
                                            href={getPrimeLink(PrimeLandingPages.FINDADRUG)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={sendCustomEvent.bind(
                                                {},
                                                {
                                                    eventType:
                                                        AUDITEVENTTYPE.MemberPortalSSOtoPrime,
                                                }
                                            )}
                                        >
                                            Find a Drug
                                        </DropdownItemWithAria>
                                        <DropdownItem divider />
                                        <DropdownItemWithAria
                                            href={getPrimeLink(
                                                PrimeLandingPages.PRESCRIPTIONHISTORY
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={sendCustomEvent.bind(
                                                {},
                                                {
                                                    eventType:
                                                        AUDITEVENTTYPE.MemberPortalSSOtoPrime,
                                                }
                                            )}
                                        >
                                            See Prescription History
                                        </DropdownItemWithAria>
                                        <DropdownItem divider />
                                        <DropdownItemWithAria
                                            href={`${alignmentWebSite}/members/medicare-part-d`}
                                            target="_blank"
                                        >
                                            Medicare Part D
                                        </DropdownItemWithAria>
                                        <DropdownItem divider />
                                        <DropdownItemWithAria
                                            href={`${alignmentWebSite}/members/determination-request`}
                                            target="_blank"
                                        >
                                            Determination Request
                                        </DropdownItemWithAria>
                                        <DropdownItem divider />
                                        <DropdownItemWithAria
                                            href={`${alignmentWebSite}/members/redetermination-request`}
                                            target="_blank"
                                        >
                                            Redetermination Request
                                        </DropdownItemWithAria>
                                        <DropdownItem divider />
                                        <DropdownItemWithAria
                                            href={`${alignmentWebSite}/members/medication-therapy-management`}
                                            target="_blank"
                                        >
                                            Medication Therapy Management
                                        </DropdownItemWithAria>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav>
                                        Payments
                                        {this.props.policies !== undefined ? (
                                            <FontAwesomeIcon
                                                icon={faChevronDown}
                                                aria-label="Toggle Dropdown Arrow"
                                            />
                                        ) : null}
                                    </DropdownToggle>
                                    {this.props.policies === undefined ? null : (
                                        <DropdownMenu>
                                            <DropdownItemWithAria
                                                href={payspanLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={sendCustomEvent.bind(
                                                    {},
                                                    {
                                                        eventType:
                                                            AUDITEVENTTYPE.MemberPortalSSOtoPayspan,
                                                    }
                                                )}
                                            >
                                                Pay My Premium
                                            </DropdownItemWithAria>
                                            <this.CollapseLinkItem
                                                className="text-dark"
                                                to="/claims"
                                            >
                                                View My Claims
                                            </this.CollapseLinkItem>
                                        </DropdownMenu>
                                    )}
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav>
                                        Forms &amp; Resources
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            aria-label="Toggle Dropdown Arrow"
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <this.CollapseLinkItem
                                            className="text-dark"
                                            to="/plan-documents"
                                        >
                                            Plan Documents
                                        </this.CollapseLinkItem>
                                        <DropdownItem divider />
                                        <this.CollapseLinkItem
                                            className="text-dark"
                                            to="/explanation-of-benefits"
                                        >
                                            Explanation of Benefits (EOB)
                                        </this.CollapseLinkItem>
                                        <DropdownItem divider />
                                        <DropdownItemWithAria
                                            href={`${alignmentWebSite}/members/rights-and-responsibilities`}
                                            target="_blank"
                                        >
                                            Rights and Responsibilities
                                        </DropdownItemWithAria>
                                        <DropdownItem divider />
                                        <DropdownItemWithAria
                                            href={`${alignmentWebSite}/members/member-services`}
                                            target="_blank"
                                        >
                                            Member Services
                                        </DropdownItemWithAria>
                                        <DropdownItem divider />
                                        <DropdownItemWithAria
                                            href={`${alignmentWebSite}/members/grievance-and-appeals`}
                                            target="_blank"
                                        >
                                            Grievances and Appeals
                                        </DropdownItemWithAria>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav>
                                        Contact Us
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            aria-label="Toggle Dropdown Arrow"
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <this.CollapseLinkItem
                                            className="text-dark"
                                            to="message-center"
                                        >
                                            My Message Center
                                        </this.CollapseLinkItem>
                                        <DropdownItem divider />
                                        <this.CollapseLinkItem
                                            className="text-dark"
                                            to="/contact-us"
                                            onClick={sendCustomEvent.bind(
                                                {},
                                                {
                                                    eventType:
                                                        AUDITEVENTTYPE.MemberPortalViewCallUs,
                                                }
                                            )}
                                        >
                                            Call Us
                                        </this.CollapseLinkItem>
                                        <DropdownItem divider />
                                        <this.CollapseLinkItem className="text-dark" to="/faq">
                                            FAQs
                                        </this.CollapseLinkItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar className="d-lg-none">
                                    <DropdownToggle nav>
                                        My Account
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            aria-label="Toggle Dropdown Arrow"
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <this.CollapseLinkItem
                                            className="text-dark"
                                            to="/account-information"
                                        >
                                            My Information
                                        </this.CollapseLinkItem>
                                        <DropdownItem divider />
                                        <this.CollapseLinkItem
                                            className="text-dark"
                                            to="/communication-preferences"
                                        >
                                            Communication Preferences
                                        </this.CollapseLinkItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </ul>
                            <Button
                                className="d-lg-none flex-grow-1 m-4 pt-2"
                                color="primary"
                                onClick={(_) => {
                                    sendCustomEvent({
                                        eventType: AUDITEVENTTYPE.MemberPortalLogOut,
                                    });
                                    this.props.msalContext.instance.logoutRedirect();
                                }}
                            >
                                <h4>Log Out</h4>
                            </Button>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

export default withFeatureFlags(
    withUnreadMessageCount(withPolicyData(withMemberData(withIdentityData(withMsal(NavMenu)))))
);
