import { MemberNotification } from "types/notifications";

export interface MemberPortalConfig {
    messageCenterCategories: string[];
    messageCenterPcpCategories: string[];
    homeBanners: Banner[];
    memberCardTemplates: any[];
    memberServicesPhone?: string;
    conciergePhone?: string;
    campaignEligibilityConfigs: MemberPortalCampaignEligibilityConfig[];
}

export type MemberPortalCampaignEligibilityConfig = {
    name: string;
    campaignId: string;
    messageCenterCategory: string;
};

export interface MemberPortalConfigContextType {
    memberPortalConfig: MemberPortalConfig;
    markAsReadMemberNotifications: (number:number) => void;
    archiveNotification: (notificationId:string) =>void;
    memberNotifications: MemberNotificationType;
}

export type Banner = {
    buttonText: string;
    description: string;
    color: string;
    image: string;
    redirectTo: string;
    title: string;
};

export const MemberPortalConfigInitialValue = {
    memberPortalConfig: {
        homeBanners: [],
        memberCardTemplates: [],
        messageCenterCategories: [],
        messageCenterPcpCategories: [],
        campaignEligibilityConfigs: [],
    },
    markAsReadMemberNotifications: (number:number) => {},
    archiveNotification: (notificationId:string) => {},
    memberNotifications: {
        dropdownNotifications: [],
        notifications: [],
        archivedNotifications: []
    },
};

export type MemberNotificationType = {
    dropdownNotifications: MemberNotification[];
    notifications: MemberNotification[];
    archivedNotifications: MemberNotification[];
}

export const MemberNotificationTypeEmpty = {
    dropdownNotifications: [],
    notifications: [],
    archivedNotifications: [],
}