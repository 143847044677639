import React, { JSXElementConstructor, useState, useEffect } from 'react';
import { healthRiskAssessmentService } from 'services/HealthRiskAssessmentService';
import { Subscription } from 'rxjs';
import { getCMProblemsSelected } from 'components/pages/HRAForm/util';
import loadOverlayService from 'services/loadOverlayService';
import { submittedHRA$ } from 'services/subjects';

export type WithHealthRiskAssessmentDataState = {
    healthRiskAssessmentForm: any;
    loading: boolean;
};

export default function WithHealthRiskAssessmentData(
    WrappedComponent: JSXElementConstructor<WithHealthRiskAssessmentDataState & unknown>
) {
    return (props: any) => {
        const [hraForm, setHraForm] = useState(false);
        const [loading, setLoading] = useState(true);
        const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
        const [cmProblems, setCMProblems] = useState<any>(false);

        const getHRAForm = () => {
            const getFormTemplatesSub = healthRiskAssessmentService
                .getHealthRiskAssessmentForm$()
                .subscribe({
                    next: (response) => {
                        if (response) {
                            setHraForm(response.sections);
                        } else {
                            console.error('Error while retrieving form template');
                            setLoading(false);
                        }
                        setLoading(false);
                        return;
                    },
                    error: () => {
                        console.error('Error while retrieving form template');
                        setLoading(false);
                        return;
                    },
                });
            setSubscriptions((prev) => [...prev, getFormTemplatesSub]);
            return;
        };

        const getCMProblems = () => {
            const getCMProblems = healthRiskAssessmentService.getCMProblems$().subscribe({
                next: (response: any) => {
                    if (response) {
                        setCMProblems(response.problems);
                    } else {
                        console.error('Error while retrieving form template');
                    }
                },
                error: () => {
                    console.error('Error while retrieving form template');
                },
            });
            setSubscriptions((prev) => [...prev, getCMProblems]);
        };

        const createHRA = async (member: any, state: any) => {
            loadOverlayService.show('Submitting Health Risk Assessment');
            const filteredAnswers = Object.fromEntries(
                Object.entries(state.answers).filter(([id, obj]) => obj !== '')
            );
            const cmProblemsArray = getCMProblemsSelected(filteredAnswers, cmProblems);
            const createHRASub = healthRiskAssessmentService
                .createHRA$({ medicareId: member.hic, memberId: member.member_number, type: 10 })
                .subscribe({
                    next: (response: any) => {
                        if (response) {
                            const id = response.id;
                            const completeHRASub = healthRiskAssessmentService
                                .completeHRAInformation$(id, {
                                    assessment: {
                                        active: true,
                                        answers: filteredAnswers,
                                        id: id,
                                        memberId: member.member_number,
                                        type: 10,
                                    },
                                    cmProblems: cmProblemsArray,
                                    cmTasks: [],
                                    complete: true,
                                })
                                .subscribe({
                                    next: () => {
                                        submittedHRA$.next(true);
                                        loadOverlayService.hide();
                                    },
                                    error: () => {
                                        submittedHRA$.next(true);
                                        loadOverlayService.hide();
                                        console.error('Error while retrieving form template');
                                    },
                                });
                            setSubscriptions((prev) => [...prev, completeHRASub]);
                        }
                    },
                    error: () => {
                        submittedHRA$.next(true);
                        loadOverlayService.hide();
                        console.error('Error while retrieving form template');
                    },
                });
            setSubscriptions((prev) => [...prev, createHRASub]);
        };

        useEffect(() => {
            getHRAForm();
            getCMProblems();
            return () => {
                subscriptions.forEach((sub) => sub.unsubscribe());
            };
        }, []);

        return (
            <WrappedComponent
                loading={loading}
                hraForm={hraForm}
                cmProblems={cmProblems}
                createHRA={createHRA}
                {...props}
            />
        );
    };
}
