import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { TextInput } from 'components/v2/organisms/TextInput/TextInput';
import { ReactComponent as EditIcon } from 'icons/v2/Edit.svg';
import { ReactComponent as SaveIcon } from 'icons/v2/Save.svg';
import { ReactComponent as CloseIcon } from 'icons/v2/Cancel.svg';
import Styles from './ContactInfomationForm.module.scss';
import { MemberContactInformationForm, emptyMemberContactInformationForm } from 'types/Member';
import { isValidPhone, validateEmail } from 'utils/validations';
import memberService from 'services/memberService';
import { MemberInformationToaster } from '../MemberInformationToaster/MemberInformationToaster';
import { sendCustomEvent } from 'common/utility';
import { AUDITEVENTTYPE } from 'services/trackService';

interface Props {
    member_phone: string;
    cell_phone: string;
    email: string;
    memberId: string;
    setLoading: (show:boolean) => void
}
const defaultValiations = {
    cellError: false,
    phoneError: false,
    mailError: false,
    phoneErrorMessage: 'Error! - Please provide a valid US phone number',
    mailErrorMessage: 'Please enter correct email format',
};

export const ContactInformationForm = ({ member_phone, cell_phone, email, memberId, setLoading }: Props) => {
    const [formData, setFormData] = useState<MemberContactInformationForm>(
        emptyMemberContactInformationForm
    );
    const [editing, setEditing] = useState(false);
    const [alert, setAlert] = useState<string | boolean>(false);
    const [pageValidations, setPageValidations] = useState(defaultValiations);

    const onChangeForm = (property: string, value: any) => {
        const tempForm = { ...formData };
        tempForm[property as keyof typeof formData] = value;
        setFormData(tempForm);
    };

    const initializeValues = () => {
        setFormData({
            cell_phone: cell_phone,
            email: email,
            member_phone: member_phone,
        });
        setPageValidations(defaultValiations);
    };

    useEffect(() => {
        initializeValues();
    }, [member_phone, cell_phone, email]);

    const onHandleSubmit = () => {
        const phoneValidated = isValidPhone(formData.member_phone || '');
        const cellValidated = isValidPhone(formData.cell_phone || '');
        const mailValidated = validateEmail(formData.email || '');

        if (!phoneValidated ||!cellValidated  || !mailValidated) {
            setPageValidations({ ...pageValidations, phoneError: !phoneValidated, cellError: !cellValidated, mailError: !mailValidated });
             return;
        }
        setPageValidations(defaultValiations);
        setLoading(true);
        sendCustomEvent({
            eventType: AUDITEVENTTYPE.MemberPortalMyInformationUpdated,
        });
        memberService.updateContactInformation$(
                {
                    memberId: memberId,
                    homePhone:formData.member_phone,
                    cellPhone:formData.cell_phone,
                    email:  formData.email
                }
            )
            .subscribe({
                next: (res) => {
                    setAlert('success');
                    setEditing(false);
                    setLoading(false);
                },
                error: () => {
                    setAlert('error');
                    setEditing(false);
                    setLoading(false);
                },
            });

    };

    const getIconToDisplay = () => {
        if (editing) {
            return (
                <>
                    <span
                        className={`body-link  ${Styles['section-title-button']}`}
                        onClick={onHandleSubmit}
                    >
                        <SaveIcon className={Styles['section-title-edit-icon']} /> Save
                    </span>
                    <span
                        className={`body-link  ${Styles['section-title-button']}`}
                        onClick={() => {
                            initializeValues();
                            setEditing(false);
                        }}
                    >
                        <CloseIcon
                            className={`${Styles['section-title-edit-icon']} ${Styles['section-title-x-icon']}`}
                        />
                    </span>
                </>
            );
        } else {
            return (
                <span
                    className={`body-link  ${Styles['section-title-button']}`}
                    onClick={() => setEditing(true)}
                >
                    <EditIcon className={Styles['section-title-save-icon']} /> Edit
                </span>
            );
        }
    };

    return (
        <>
            <header className={Styles['section-title-action-button-container']}>
                <h1 className={`section-title-compressed`}>CONTACT INFORMATION</h1>{' '}
                {getIconToDisplay()}
            </header>
            <Row className="mb-4">
                <Col md="4">
                    <TextInput
                        error={pageValidations.phoneError}
                        errorMesssage={pageValidations.phoneErrorMessage}
                        id="member_phone"
                        label="Home Phone"
                        disabled={!editing}
                        defaultValue={formData.member_phone || ''}
                        onChange={(value) => onChangeForm('member_phone', value)}
                    />
                </Col>
                <Col md="4">
                    <TextInput
                        error={pageValidations.cellError}
                        errorMesssage={pageValidations.phoneErrorMessage}
                        id="cell_phone"
                        label="Mobile Phone"
                        disabled={!editing}
                        defaultValue={formData.cell_phone || ''}
                        onChange={(value) => onChangeForm('cell_phone', value)}
                    />
                </Col>
                <Col md="4">
                    <TextInput
                        id="emailAddress"
                        label="Email Address"
                        disabled={!editing}
                        defaultValue={formData.email || ''}
                        onChange={(value) => onChangeForm('email', value)}
                        error={pageValidations.mailError}
                        errorMesssage={pageValidations.mailErrorMessage}
                    />
                </Col>
            </Row>
            <MemberInformationToaster
                type={alert}
                onError={() => {
                    setEditing(false);
                    initializeValues();
                    setAlert(false);
                }}
                onSuccess={() => setAlert(false)}
            />
        </>
    );
};
