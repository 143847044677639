import React, { useState, useEffect } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Subscription } from 'rxjs';
import { dateToStr, getFirstOfMonthRange } from 'common/utility';
import PageHeader from 'components/molecules/PageHeader';
import ProviderReview from 'components/molecules/ProviderSearch/ProviderReview';
import ProviderSearch from 'components/organisms/ProviderSearch';
import withMemberData from 'hoc/withMemberData';
import withMemberPbpAssignmentHistory from 'hoc/withMemberPbpAssignmentHistory';
import { AHRecord, AHRecordAddress, Providers } from 'interfaces/Provider';
import { PcpChangeForm, ProviderSearchParameters } from 'interfaces/SelfService';
import memberService from 'services/memberService';
import providerService from 'services/providerService';
import { selfServeService } from 'services/selfServeService';
import { getIsEffective } from './util';

export interface MedicalGroup {
    record: AHRecord;
    address: AHRecordAddress;
}

interface ProviderSearchProps {
    member: any;
    pbpAssignmentHistory: any;
}

const PcpChange = ({ member, pbpAssignmentHistory }: ProviderSearchProps) => {
    const memberHomeAddress = member?.addresses?.find(
        (address: any) => address.address_type === 'Home'
    );
    const effectiveDates = getFirstOfMonthRange(0, 3).map((jsDate) =>
        dateToStr(new Date(jsDate), 'm/d/yyyy')
    );
    const memberPbpHistory =
        pbpAssignmentHistory?.flatMap((plan: any, i: number) =>
            plan.pbpAssignmentHistory.map((pbpAssignment: any) => {
                const coverage = {
                    ...plan,
                    ...pbpAssignment,
                    rowIndex: i,
                };
                return coverage;
            })
        ) ?? null;

    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [workflowReasons, setWorkflowReasons] = useState<string[]>([]);
    const [selectedWorkflowReason, setSelectedWorkflowReason] = useState<string | null>(null);
    const [effectiveDate, setEffectiveDate] = useState<string>(effectiveDates[0]);
    const [searchValues, setSearchValues] = useState<ProviderSearchParameters>({
        pbpId: null,
        healthplanid: null,
        searchterm: null,
        fromAddress: memberHomeAddress?.zip_code ?? null,
        gender: null,
        language: null,
        withinmiles: '30',
        acceptingNewPatients: 'yes',
        ipa: null,
        sortby: 'distance',
        pageNumber: 1,
        totalNpiCount: null,
        visitType: null,
        planYear: new Date().getFullYear().toString(),
    });
    const [pbpOptions, setPbpOptions] = useState<{ text: string; value: string }[]>([]);
    const [providers, setProviders] = useState<Providers[] | null>(null);
    const [zipCodesForPbp, setZipCodesForPbp] = useState<{ text: string; value: string }[]>([]);
    const [ipasForPbp, setIpasForPbp] = useState<{ id: string; desc: string }[]>([]);

    const [selectedProvider, setSelectedProvider] = useState<Providers | null>(null);
    const [selectedMedicalGroup, setSelectedMedicalGroup] = useState<MedicalGroup | null>(null);
    const [submitSeverity, setSubmitSeverity] = useState<AlertColor | undefined>(undefined);
    const [submitMessage, setSubmitMessage] = useState<string | null>(null);

    useEffect(() => {
        getWorkflowReasons();

        return () => {
            subscriptions.forEach((sub) => sub.unsubscribe());
        };
    }, []);

    useEffect(() => {
        setLoading(true);

        const isReady = (item: any) => {
            return Array.isArray(item) && item.length > 0;
        };

        if (
            isReady(workflowReasons) &&
            isReady(pbpOptions) &&
            isReady(zipCodesForPbp) &&
            isReady(ipasForPbp)
        ) {
            setLoading(false);
        }
    }, [workflowReasons, pbpOptions, zipCodesForPbp, ipasForPbp]);

    useEffect(() => {
        if (memberPbpHistory) {
            setLoading(true);

            // auto fill search values using member pbp history
            const pbp = memberPbpHistory.find((historyItem: any) => {
                if (
                    getIsEffective(
                        effectiveDate,
                        historyItem.pbpEffectiveFrom,
                        historyItem.pbpEffectiveTill
                    )
                ) {
                    return historyItem;
                }
            });
            const visitType =
                memberPbpHistory.find((historyItem: any) => historyItem.pbp === pbp?.pbpID)
                    ?.pbpType === 'Virtual'
                    ? 'Virtual'
                    : '';

            if (!pbp) {
                setSearchValues({
                    ...searchValues,
                    pbpId: null,
                    fromAddress: null,
                    visitType,
                    planYear: new Date().getFullYear().toString(),
                });
            } else {
                if (searchValues.pbpId !== pbp?.pbpID) {
                    setSearchValues({
                        ...searchValues,
                        pbpId: pbp.pbpID,
                        fromAddress: memberHomeAddress?.zip_code ?? null,
                        visitType,
                        planYear: new Date(effectiveDate).getFullYear().toString(),
                    });
                }
            }

            // get data for pbp dropdown
            const data = memberPbpHistory
                .map((historyItem: any) => ({
                    text: `${historyItem.pbpName} - ${historyItem.pbpID}`,
                    value: historyItem.pbpID,
                }))
                .filter(
                    (obj: any, index: number, self: any) =>
                        index ===
                        self.findIndex((x: any) => x.text === obj.text && x.value === obj.value)
                );
            setPbpOptions(data);
        } else {
            setPbpOptions([]);
        }
    }, [effectiveDate, pbpAssignmentHistory]);

    useEffect(() => {
        if (searchValues.pbpId) {
            getZipCodesForPbp();
        } else {
            setZipCodesForPbp([]);
        }
    }, [searchValues.pbpId]);

    useEffect(() => {
        if (searchValues.pbpId && memberHomeAddress) {
            getIpasForPbp();
        } else {
            setIpasForPbp([]);
        }
    }, [searchValues.pbpId, memberHomeAddress]);

    const getWorkflowReasons = () => {
        const getReasonsSub = selfServeService
            .getWorkflowReasonsForServiceFlow$('PCP Change')
            .subscribe({
                next: (response: any) => {
                    if (response && response.response) {
                        setWorkflowReasons(response.response);
                    }
                },
            });

        setSubscriptions([...subscriptions, getReasonsSub]);
    };

    const getZipCodesForPbp = () => {
        const getHealthPlansSub = memberService
            .getHealthPlansForPbp$(searchValues.pbpId, new Date(effectiveDate).getFullYear())
            .subscribe({
                next: (response) => {
                    if (response && response.response) {
                        const zipMap = response.response
                            .map((hp: any) =>
                                hp.pbp.map((p: any) =>
                                    p.county.map((c: any) =>
                                        c.zip.map((z: any) => ({
                                            county: c.name,
                                            zipCode: z.code,
                                        }))
                                    )
                                )
                            )
                            .flat(3)
                            .filter(
                                (obj: any, index: number, self: any) =>
                                    index ===
                                    self.findIndex(
                                        (x: any) =>
                                            x.county === obj.county && x.zipCode === obj.zipCode
                                    )
                            )
                            // combine zip codes that cross county lines
                            .reduce((accumulator: any, currentValue: any) => {
                                if (accumulator[currentValue.zipCode]) {
                                    accumulator[currentValue.zipCode] = `${
                                        accumulator[currentValue.zipCode]
                                    }/${currentValue.county}`;
                                } else {
                                    accumulator[currentValue.zipCode] = currentValue.county;
                                }
                                return accumulator;
                            }, {});

                        const zips = Object.keys(zipMap).map((zipCode) => ({
                            text: `${zipCode} (${zipMap[zipCode]})`,
                            value: zipCode,
                        }));

                        setZipCodesForPbp(zips);
                    }
                },
            });

        setSubscriptions([...subscriptions, getHealthPlansSub]);
    };

    const getIpasForPbp = () => {
        const getMedicalGroupsSub = providerService
            .searchProviders$({
                pbp: searchValues.pbpId,
                fromAddress: searchValues.fromAddress,
                planYear: searchValues.planYear,
                withinmiles: searchValues.withinmiles,
            })
            .subscribe({
                next: (response) => {
                    if (response && response.response) {
                        setIpasForPbp(response.response.distinctValues.distinctIPA);
                    }
                },
            });
        setSubscriptions([...subscriptions, getMedicalGroupsSub]);
    };

    const onSearchValueChange = (property: string, value: any) => {
        let tempSearchValues = { ...searchValues };
        // @ts-ignore
        tempSearchValues[property] = value;
        setSearchValues(tempSearchValues);
    };

    const processPcpChange = () => {
        if (!selectedProvider || !selectedMedicalGroup) {
            return;
        }

        setLoading(true);
        const pcpChangeForm: PcpChangeForm = {
            closedPanelOverride: false,
            confirmationCode: null,
            effectiveDate: effectiveDate,
            pcpAddress: {
                type: 'None',
                addressLine1: selectedMedicalGroup.address.address1,
                addressLine2: selectedMedicalGroup.address.address2,
                city: selectedMedicalGroup.address.city,
                state: selectedMedicalGroup.address.state,
                zip: selectedMedicalGroup.address.zip,
                countyName: selectedMedicalGroup.address.county,
            },
            pcpId: selectedProvider.id,
            providerSearchParameters: searchValues,
            retroactiveDateOverride: false,
            selectedProvider: {
                npi: selectedProvider.npi,
                nppes: selectedProvider.nppes,
                primaryLanguage: selectedProvider.primaryLanguage,
            },
            selectedMedicalGroup: selectedMedicalGroup.record,
            selectedMedicalGroupAddress: selectedMedicalGroup.address,
            termDate: selectedMedicalGroup.record.termDate,
        };
        const pcpChangeSub = selfServeService
            .processPcpChange$(pcpChangeForm, selectedWorkflowReason)
            .subscribe({
                next: (response: any) => {
                    if (response && response.response) {
                        if (!response.response.success) {
                            setSubmitSeverity('error');
                        } else {
                            setSubmitSeverity(
                                response.response.pcpChangeSuccess ? 'success' : 'info'
                            );
                        }
                        setSubmitMessage(response.response.message);
                    }
                    setLoading(false);
                },
                error: () => {
                    setSubmitSeverity('error');
                    setSubmitMessage('Sorry, we ran into an issue. Please try again later.');
                    setLoading(false);
                },
            });

        setSubscriptions([...subscriptions, pcpChangeSub]);
    };

    return (
        <div style={{ display: 'relative' }}>
            <PageHeader>Change My PCP</PageHeader>
            <div>
                {!(selectedProvider && selectedMedicalGroup) ? (
                    <div>
                        <div className="mb-3">
                            <TextField
                                fullWidth
                                select
                                id="workflowReason"
                                label="Why are you changing your PCP?"
                                onChange={(args) => {
                                    setSelectedWorkflowReason(args.target.value);
                                }}
                                value={selectedWorkflowReason ?? ''}
                            >
                                {workflowReasons?.map((reason, i) => (
                                    <MenuItem
                                        key={`pcp-change-reason-${reason}-${i}`}
                                        value={reason}
                                    >
                                        {reason}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className="mb-3">
                            <TextField
                                select
                                id="effectiveDate"
                                label="Effective Date"
                                onChange={(args) => {
                                    setEffectiveDate(args.target.value);
                                }}
                                value={effectiveDate}
                            >
                                {effectiveDates.map((date) => (
                                    <MenuItem key={`effective-date-${date}`} value={date}>
                                        {date}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <ProviderSearch
                            effectiveDate={effectiveDate}
                            ipasForPbp={ipasForPbp}
                            onMedicalGroupSelect={setSelectedMedicalGroup}
                            onProviderSelect={setSelectedProvider}
                            onSearchValueChange={onSearchValueChange}
                            pbpOptions={pbpOptions}
                            providers={providers}
                            searchValues={searchValues}
                            selectedProvider={selectedProvider}
                            setProviders={setProviders}
                            setSearchValues={setSearchValues}
                            zipCodesForPbp={zipCodesForPbp}
                        />
                    </div>
                ) : (
                    <div>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setSelectedMedicalGroup(null);
                                setSubmitSeverity(undefined);
                                setSubmitMessage(null);
                            }}
                        >
                            Back
                        </Button>
                        <ProviderReview
                            effectiveDate={effectiveDate}
                            medicalGroup={selectedMedicalGroup}
                            provider={selectedProvider}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                processPcpChange();
                            }}
                        >
                            Submit Change
                        </Button>
                        {submitMessage && (
                            <div className="mt-3">
                                <Alert severity={submitSeverity}>{submitMessage}</Alert>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {loading && (
                <CircularProgress
                    style={{
                        position: 'absolute',
                        top: 'calc(50% - 20px)',
                        left: 'calc(50% - 20px)',
                        height: '40px',
                        width: '40px',
                    }}
                />
            )}
        </div>
    );
};

export default withMemberData(withMemberPbpAssignmentHistory(PcpChange));
