import React from 'react';
import { Col, Input } from 'reactstrap';
import styles from 'components/molecules/input/CheckboxQuestion.module.scss';
import { QuestionOption } from '../util';

interface Props {
    value?: string;
    disabled?: boolean;
    question: QuestionOption;
    onChange: (value:any) => void;
}

export const HraInputText = ({ question, onChange, value }: Props) => {
                return (
                    <Col
                        key={question.optionId}
                        style={{ padding: 0, display: 'inline-flex', margin: 'auto'}}
                    >
                        <label
                            style={{ paddingRight: '10px', marginTop: 'auto' }}
                            className={styles.label}
                            htmlFor={question.text}
                            id={`label-combocheckbox-${question.optionId}-${question.text}`}
                        >
                            {question.text}
                        </label>
                        <Input
                            style={{ width: '80%' }}
                            id={question.optionId}
                            name="phone"
                            type="text"
                            value={value}
                            onChange={(e: any) => onChange({ [question.optionId]: e.target.value })}
                        />
                    </Col>
                );
};
