import React, { useState, useEffect, createContext } from 'react';
import {
    MemberNotificationType,
    MemberNotificationTypeEmpty,
    MemberPortalConfig,
    MemberPortalConfigContextType,
    MemberPortalConfigInitialValue,
} from 'interfaces/MemberPortalConfig';
import configurationService from 'services/configurationService';
import { memberPortalConfig$ } from 'services/subjects';
import { Subscription, merge, of } from 'rxjs';
import { memberNotificationsService } from 'services/memberNotificationsService';

export const MemberPortalConfigContext = createContext<MemberPortalConfigContextType>(
    MemberPortalConfigInitialValue
);
interface Props {
    children: React.ReactNode;
    member: any;
}

export const MemberPortalConfigProvider = ({ children, member }: Props) => {
    const [memberPortalConfig, setMemberPortalConfig] = useState<MemberPortalConfig>(
        MemberPortalConfigInitialValue.memberPortalConfig
    );

    const [memberNotifications, setMemberNotifications] = useState<MemberNotificationType>(MemberNotificationTypeEmpty);

    let getMemberPortalConfigSubscription: Subscription | null = null;
    let memberPortalConfigSubject: Subscription | null = null;

    const getMemberPortalConfig = () => {
        getMemberPortalConfigSubscription = configurationService
            .getMemberPortalConfig$()
            .subscribe({
                next: (response) => {
                    memberPortalConfig$.next(response?.response ?? null);
                },
            });
        memberPortalConfigSubject = memberPortalConfig$.subscribe({
            next: (value) => {
                setMemberPortalConfig(value || MemberPortalConfigInitialValue.memberPortalConfig);
            },
        });
    };

    const getMemberNotifications = () => {
        memberNotificationsService
            .get$(member.member_number)
            .pipe()
            .subscribe({
                next: (response) => {
                    if (response?.length > 0) {
                        const archivedNotifications = response.filter((n) => n.isArchived) || [];
                        const dropdownNotifications = response.filter((n) => !n.isRead) || [];
                        const memberNotifications = response.filter((n) => !n.isArchived) || [];
                        setMemberNotifications({archivedNotifications: archivedNotifications, dropdownNotifications:dropdownNotifications, notifications:memberNotifications})
                    }
                },
                error: (error: Error) => {
                    // TODO implement toast
                },
            });
    };
    const markAsReadMemberNotifications =  (notificationsToMark: number = 3) => {

        const {dropdownNotifications} = memberNotifications;

        const notificationsToMarkAsRead = dropdownNotifications.slice(0, notificationsToMark);
        const markDropdownNotificationsAsRead = notificationsToMarkAsRead.map((notification) =>
            of(
                memberNotificationsService
                    .markNotificationAsRead$(notification.id)
                    .pipe().subscribe()
            )
        );

        merge(markDropdownNotificationsAsRead).subscribe(()=> getMemberNotifications());
      
    };

    const archiveNotification = (notificationId: string) => {
        memberNotificationsService
            .archiveNotification$(notificationId)
            .pipe()
            .subscribe({
                next: (response) => {
                        getMemberNotifications();
                },
                error: (error: Error) => {
                    // TODO implement toast
                },
            });
    };

 
    useEffect(() => {
        getMemberPortalConfig();
        return () => {
            getMemberPortalConfigSubscription?.unsubscribe();
            memberPortalConfigSubject?.unsubscribe();
        };
    }, []);

    useEffect(() => {
      if(!member) return;
      getMemberNotifications();
    }, [member]);

    useEffect(() => {
      if(!member) return;
      getMemberNotifications();
    }, [member]);

    return (
        <MemberPortalConfigContext.Provider
            value={{
                memberPortalConfig,
                markAsReadMemberNotifications,
                archiveNotification,
                memberNotifications,
            }}
        >
            {children}
        </MemberPortalConfigContext.Provider>
    );
};
