import React from 'react';
import { Row, Col } from 'reactstrap';
import { TextInput } from 'components/v2/organisms/TextInput/TextInput';
import { FaEnvelope } from "react-icons/fa";
import Styles from 'components/v2/organisms/ContactInformationForm/ContactInfomationForm.module.scss';
import { useNavigate } from 'react-router-dom';
import { MemberAddress } from 'types/Member';

 interface Props {
    homeAddress: MemberAddress;
 }
const preselectedMessageCenterOption = 'Address Change Request'
 const MemberAddressForm = ({homeAddress}: Props) => {
const {city_name, state, street_name_1, street_name_2, zip_code } = homeAddress;
    const navigate = useNavigate();
    const getIconToDisplay = () => (
        <span
            className={`body-link  ${Styles['section-title-button']}`}
            onClick={() =>
                navigate('/message-center', {
                    state: {
                        data: preselectedMessageCenterOption,
                      },
                })
            }
        >
            <FaEnvelope
                style={{ color: '#2C53BB' }}
                className={Styles['section-title-save-icon']}
            />{' '}
            Send a Message
        </span>
    );

    return (
        <>
            <header className={Styles['section-title-action-button-container']}>
                <h1 className={`section-title-compressed`}>PERMANENT ADDRESS</h1>{' '}
                {getIconToDisplay()}
            </header>
            <Row className="mb-4">
                <Col md="4">
                    <TextInput
                        id="address_1"
                        label="Address 1"
                        defaultValue={street_name_1}
                        disabled={true}
                    />
                </Col>
                <Col md="4">
                    <TextInput
                        id="address_2"
                        label="Address 2"
                        defaultValue={street_name_2}
                        disabled={true}
                    />
                </Col>
                <Col md="4">
                    <TextInput
                        id="City"
                        label="City"
                        defaultValue={city_name}
                        disabled={true}
                    />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col md="4">
                    <TextInput
                        id="State"
                        label="State"
                        defaultValue={state}
                        disabled={true}
                    />
                </Col>
                <Col md="4">
                    <TextInput
                        id="Zip Code"
                        label="Zip Code"
                        defaultValue={zip_code}
                        disabled={true}
                    />
                </Col>
            </Row>
        </>
    );
};

export default MemberAddressForm;