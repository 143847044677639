import React from 'react';
import { PreferenceOption } from 'types/Preference';
import PreferenceOptionCheckbox from './PreferenceOptionCheckbox';


export type PreferenceOptionCheckboxProps = {
    options: PreferenceOption[];
    campaignName: string;
    campaignCategory: string;
    invalidEmail: boolean;
    onChange: (newOption: PreferenceOption, email: boolean) => void;
}

export default function PreferenceOptionCheckboxes({
    options,
    ...rest
}: PreferenceOptionCheckboxProps) {
    return (
        <>
        {options.map((option) => (
            <PreferenceOptionCheckbox
                key={option.id}
                option={option}
                {...rest}
            />
        ))}
        </>
    );
}
