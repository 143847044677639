import React, { useState, Dispatch, SetStateAction } from 'react';
import Styles from './CheckBoxInput.module.scss';
import { FormGroup, Input, Tooltip, FormFeedback } from 'reactstrap';
interface Props {
    label: string;
    disabled: boolean;
    onChange?: (a:any) => void | Dispatch<SetStateAction<string>>;
    value?: boolean;
    id: string;
    tooltip?: string | null;
    error?: boolean;
    errorMesssage?: string;
}
export const CheckBoxInput = ({
    label,
    onChange = () => {},
    disabled = false,
    id,
    value,
    tooltip = null,
    error = false,
    errorMesssage = 'Invalid Value'
}: Props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <FormGroup check style={{display:'flex', alignItems: 'center'}}>
            <Input
                checked={value}
                invalid={error}
                aria-labelledby={`label-for${id}`}
                name={id}
                id={id}
                onChange={onChange}
                disabled={disabled}
                type="checkbox"
                className={Styles['form-checkbox-component']}
            />
            <span  id={`label-for${id}`}className={Styles['form-checkbox-label']}>
                {label}
            </span>
            <FormFeedback> {errorMesssage} </FormFeedback>
            {tooltip && (
                <Tooltip target={id} toggle={toggle} isOpen={tooltipOpen} placement="right">
                    <span className={Styles['form-checkbox-tooltip']}> {tooltip} </span>
                </Tooltip>
            )}
        </FormGroup>
    );
};


