import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Alert } from 'reactstrap';
import withPreferenceData from '../../hoc/withPreferenceData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import withMemberData from '../../hoc/withMemberData';
import PageHeader from '../molecules/PageHeader';
import { getPrimeLink, PrimeLandingPages } from '../../services/ssoService';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import PreferenceOptionInput from 'components/molecules/input/PreferenceOptionInput';
import useFeatureFlag from 'hooks/useFeatureFlag';
import Loading from 'components/atoms/Loading';
import { Skeleton } from '@mui/material';

const CM_UM_COMM_PREFERENCES_FEATURE_FLAG = 'cm-um-communication-preferences';
const COMMUNICATIONS_CATEGORY = 'Communications';
const CARE_MANAGEMENT_CATEGORY = 'Care Management';
function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function filterAndMapCareManagementNames(userPreferences = []) {
    return (
        userPreferences
            .find((preference) => preference.category === CARE_MANAGEMENT_CATEGORY)
            ?.names?.map((preference) => {
                if (preference.campaignName === 'ALL') {
                    return {
                        ...preference,
                        options: preference.options.filter(
                            (option) =>
                                option.communicationType === 'MAIL' ||
                                option.communicationType === 'EMAIL'
                        ),
                    };
                }
                return preference;
            }) || []
    );
}

function filterCommunicationNames(userPreferences = []) {
    return (
        userPreferences.find((preference) => preference.category === COMMUNICATIONS_CATEGORY)
            ?.names || []
    );
}

const Preferences = (props) => {
    const [changed, setChanged] = useState(false);
    const [communicationPreferences, setCommunicationPreferences] = useState(
        props.userPreferences && filterCommunicationNames(props.userPreferences)
    );
    const [careManagementPreferences, setCareManagementPreferences] = useState(
        props.userPreferences && filterAndMapCareManagementNames(props.userPreferences)
    );
    const [invalidEmail, setInvalidEmail] = useState(false);
    const cmUmCommunicationPreferencesFeatureFlag = useFeatureFlag(
        CM_UM_COMM_PREFERENCES_FEATURE_FLAG
    );
    const handleChangecommunicationPreferences = (newPreference, email) => {
        setChanged(true);

        if (email && !validateEmail(props.member?.email || '')) {
            setInvalidEmail(true);
            return;
        }

        setCommunicationPreferences((latestcommunicationPreferences) =>
            latestcommunicationPreferences.map((preference) => {
                if (
                    preference.campaignCategory === newPreference.campaignCategory &&
                    newPreference.campaignName === preference.campaignName
                ) {
                    return newPreference;
                }
                return preference;
            })
        );
    };

    const handleChangeCareManagementPreferences = (newPreference, email) => {
        setChanged(true);
        if (email && !validateEmail(props.member?.email || '')) {
            setInvalidEmail(true);
            return;
        }
        setCareManagementPreferences((latestcommunicationPreferences) =>
            latestcommunicationPreferences.map((preference) => {
                if (
                    preference.campaignCategory === newPreference.campaignCategory &&
                    newPreference.campaignName === preference.campaignName
                ) {
                    return newPreference;
                }
                return preference;
            })
        );
    };

    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewCommunicationPreferences })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (props.userPreferences && !communicationPreferences) {
            setCommunicationPreferences(filterCommunicationNames(props.userPreferences));
        }
    }, [props.userPreferences, communicationPreferences]);

    useEffect(() => {
        if (props.userPreferences && !careManagementPreferences) {
            setCareManagementPreferences(filterAndMapCareManagementNames(props.userPreferences));
        }
    }, [props.userPreferences, careManagementPreferences]);

    function renderVerbiage() {
        if(cmUmCommunicationPreferencesFeatureFlag.isFeatureFlagLoading) {
            return (<>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </>);
        }
        else if(cmUmCommunicationPreferencesFeatureFlag.enabled) {
            return (
                <>
                    <p>
                        By selecting "GO PAPERLESS" and opting out of "MAIL" for any of the documents
                        below, you acknowledge that you will no longer receive a mailed hard copy of the
                        required communications. With the "GO PAPERLESS" option, you will be alerted by
                        email that a new document is available online in your member portal. You can
                        access these documents in your member portal for up to 12 months after your plan
                        coverage ends.
                    </p>
                    <p>
                        To start receiving mail again, select "MAIL" or if you have any questions, call
                        your ACCESS On-Demand Concierge at <strong>1-833-242-2223, TTY 711</strong>, 24
                        hours a day, seven days a week.
                    </p>
                    <p>
                        <strong>NOTE:</strong> Not all health plan coverage documents and/or
                        communications may be available via the Alignment Health Plan Mobile App. As
                        additional health plan communications are added to those already available
                        electronically, we will notify you.
                    </p>
                </>
            );
        }
        return (
            <>
                <p>
                    Per Medicare rules, we are required to send you certain information about your
                    health plan benefits. Your annual notice of change (ANOC) and explanation of
                    benefits (EOB) are some examples.
                </p>
                <p>
                    By choosing "Email" to go paperless, you acknowledge that you will no longer receive
                    a mailed copy of those required communications. Once you select email, you can
                    access your documents online for up to 12 months after your plan coverage ends
                </p>
                <p>
                    To stop paperless delivery and start receiving mail again, select "Mail" below or call
                    your ACCESS On-Demand Concierge.
                </p>
            </>
        );
    }

    function renderLetters() {
        if(cmUmCommunicationPreferencesFeatureFlag.isFeatureFlagLoading) {
            return <Loading size="2x" />;
        }
        else if(cmUmCommunicationPreferencesFeatureFlag.enabled) {
            return (
                <>
                        <h2>Letters</h2>
                        <hr />
                        {careManagementPreferences ? (
                            careManagementPreferences?.map((preference, i) => (
                                <Row
                                    key={`${preference?.campaignName}_${i}`}
                                    className="align-items-center"
                                >
                                    <Col md="6">
                                        <h3>{preference?.label}</h3>
                                        <p>{preference?.description}</p>
                                        {preference?.secondaryLabel && (
                                            <h3>{preference?.secondaryLabel}</h3>
                                        )}
                                        {preference?.secondaryDescription && (
                                            <p>{preference?.secondaryDescription}</p>
                                        )}
                                    </Col>
                                    <Col
                                        xs={{ size: 8, offset: 2 }}
                                        md={{ size: 4, offset: 1 }}
                                        className="d-flex justify-content-between mt-3 mb-3"
                                    >
                                        <PreferenceOptionInput
                                            cmUMFeatureFlagIsActive={
                                                cmUmCommunicationPreferencesFeatureFlag.enabled
                                            }
                                            preference={preference}
                                            invalidEmail={invalidEmail}
                                            onChange={handleChangeCareManagementPreferences}
                                        />
                                    </Col>
                                </Row>
                            ))
                        ) : (
                            <div className="d-flex align-items-center justify-content-center">
                                <FontAwesomeIcon
                                    icon={faCircleNotch}
                                    aria-label="Currently Loading Content"
                                    size="2x"
                                />
                            </div>
                        )}{' '}
                    </>
            );
        }
        return null;
    }

    return (
        <div>
            <PageHeader>My Communication Preferences</PageHeader>
            <p dangerouslySetInnerHTML={{ __html: props.preferenceData?.description }}></p>
            <div>
                <Alert
                    isOpen={invalidEmail}
                    // TODO what about this
                    toggle={() => setInvalidEmail(false)}
                    color="danger"
                    aria-atomic="true"
                >
                    The email address we have on file is invalid. Please call On Demand Concierge
                    [1-833-242-2223 (TTY 711)] to get the email address added to the file.
                </Alert>
                {renderVerbiage()}
                {renderLetters()}

                <h2>Health Plan Materials</h2>
                <hr />
                {communicationPreferences &&
                !cmUmCommunicationPreferencesFeatureFlag.isFeatureFlagLoading ? (
                    communicationPreferences?.map((preference, i) => {
                        return (
                            <Row
                                key={`${preference?.campaignName}_${i}`}
                                className="align-items-center"
                            >
                                <Col md="6">
                                    <h3>{preference?.label}</h3>
                                    <p>{preference?.description}</p>
                                    {preference?.secondaryLabel && (
                                        <h3>{preference?.secondaryLabel}</h3>
                                    )}
                                    {preference?.secondaryDescription && (
                                        <p>{preference?.secondaryDescription}</p>
                                    )}
                                </Col>
                                <Col
                                    xs={{ size: 8, offset: 2 }}
                                    md={{ size: 4, offset: 1 }}
                                    className="d-flex justify-content-between mt-3 mb-3"
                                >
                                    {preference?.campaignName === 'PartD EOB' ? (
                                        <a
                                            href={getPrimeLink(
                                                PrimeLandingPages.COMMUNICATIONPREFERENCES
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <b>View Part D EOB Communication Preferences</b>
                                        </a>
                                    ) : (
                                        <PreferenceOptionInput
                                            cmUMFeatureFlagIsActive={
                                                cmUmCommunicationPreferencesFeatureFlag.enabled
                                            }
                                            preference={preference}
                                            invalidEmail={invalidEmail}
                                            onChange={handleChangecommunicationPreferences}
                                        />
                                    )}
                                </Col>
                                <Col md="12">
                                    <hr />
                                </Col>
                            </Row>
                        );
                    })
                ) : (
                    <div className="d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            aria-label="Currently Loading Content"
                            size="2x"
                        />
                    </div>
                )}
                <Row>
                    <Col className="text-center mt-4 mb-4">
                        <Button
                            color="primary"
                            onClick={async () => {
                                await props.updatePreference([
                                    ...communicationPreferences,
                                    ...careManagementPreferences,
                                ]);
                                setChanged(false);
                            }}
                            disabled={!changed}
                        >
                            Save Changes
                        </Button>
                        {props.updating ? (
                            <FontAwesomeIcon
                                className="button-icon"
                                icon={faCircleNotch}
                                aria-label="Update in progress.."
                            />
                        ) : props.updateFailure ? (
                            <FontAwesomeIcon
                                className="button-icon"
                                icon={faTimesCircle}
                                aria-label="Update failed"
                            />
                        ) : (
                            <FontAwesomeIcon
                                className="button-icon fade-out"
                                icon={faCheckCircle}
                                aria-label="Update successful"
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default withPreferenceData(withMemberData(Preferences));

Preferences.propTypes = {
    communicationPreferences: PropTypes.array,
    member: PropTypes.shape({
        email: PropTypes.string,
    }),
};
