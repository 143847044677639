import { useEffect } from "react";
import { Observable } from "rxjs";

export const useSubscription = <TValue,>(source$: Observable<TValue> | null, next: (value: TValue) => void, error: (error: Error) => void) => {
    useEffect(() => {
        if (source$) {
            const subscription = source$.subscribe({
                next,
                error,
            });

            return () => {
                subscription.unsubscribe();
            }
        }
    }, [source$]);
}