import React, { useState } from 'react';
import PrescriptionsImag from '../../../../assets/prescriptions.png';
import { Banner } from 'components/v2/organisms/Banner/Banner';
import { Container } from 'components/v2/atoms/Container/Container';
import PrescriptionsGridContainer from 'components/v2/organisms/PrescriptionsGridContainer/PrescriptionsGridContainer';
import { SectionTitle } from 'components/v2/atoms/SectionTitle/SectionTitle';
import { FullScreenLoader } from 'components/v2/atoms/FullScreenLoader/FullScreenLoader';

interface PrescriptionsProps {}

const Prescriptions = ({}: PrescriptionsProps) => {
    const [loading, setLoading] = useState(false);
    return (
        <>
            {loading && <FullScreenLoader />}
            <Banner
                image={PrescriptionsImag}
                title="PRESCRIPTIONS"
                description="Welcome to your prescription overview, a dedicated space to understand and manage your medications effectively. We understand that prescriptions play a crucial role in your well-being, and our goal is to provide you with clarity, convenience, and peace of mind."
                color="#fdbd61"
            />
            <Container>
                <header>
                    <h1 className="page-title">PRESCRIPTION MANAGEMENT</h1>
                </header>

                <SectionTitle as="h1">PRESCRIPTION DRUGS</SectionTitle>
                <span>
                    Not all healt plans cover the same prescription drugs, Alignment Health Plan has
                    a list of all the drugs it covers, called a formulary. But if you need a drug
                    that Alignment Health Plan does not cover, you will have to pay more - possibly
                    the full price - out of your own pocket.
                </span>
                <br />
                <br />
                <span>
                    How much you pay depends on the type of drug and the tier the grug is in,{' '}
                    <a style={{ color: '#2C53BB', fontWeight: 'bold' }} onClick={() => {}}>
                        Learn more about your prescription coverage
                    </a>
                    , or if you have any questions about your prescriptions,{' '}
                    <a style={{ color: '#2C53BB', fontWeight: 'bold' }} onClick={() => {}}>
                        please send us a message
                    </a>
                    .
                </span>
                <br />
                <br />
                <PrescriptionsGridContainer setLoading={setLoading} />
            </Container>
        </>
    );
};

export default Prescriptions;
