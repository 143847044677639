import React, { useState, useEffect } from 'react';
import { memberId$ } from '../services/subjects';
import { memberNotificationsService } from 'services/memberNotificationsService';

export default function withMemberNotifications(
    WrappedComponent
) {
    return (props) => {
        const [memberNotifications, setMemberNotifications] = useState([])
        const [newNotificationsCount, setNewNotificationsCount] = useState(0)
        const [memberId, setMemberId] = useState(false)

        const getMemberNotifications = () => {
            memberNotificationsService
                .get$(memberId)
                .pipe()
                .subscribe({
                    next: (response) => {
                        if (response?.length > 0) {
                            const archivedNotifications = response.filter((n) => n.isArchived) || [];
                            const dropdownNotifications = response.filter((n) => !n.isRead) || [];
                            setNewNotificationsCount(dropdownNotifications.length)
                            const memberNotifications = response.filter((n) => !n.isArchived) || [];

                            setMemberNotifications({archivedNotifications: archivedNotifications, dropdownNotifications:dropdownNotifications, notifications:memberNotifications})
                        }
                    },
                    error: () => {},
                });
        };

        useEffect(() => {
            if (!memberId) return;
            const interval = setInterval(() => {
                getMemberNotifications();
            }, 10000);

            return () => clearInterval(interval);
        }, [memberId]);

        useEffect(() => {
            const memberIdSub = memberId$.subscribe({
                next: (memberId) => {
                    setMemberId(memberId);
                },
            });
            return () => {
                memberIdSub.unsubscribe();
            };
        }, []);

        const markAsReadMemberNotification = (notificationId) => {
            memberNotificationsService
                .markNotificationAsRead$(notificationId)
                .subscribe({
                    next: () => {
                        getMemberNotifications();
                    },
                    error: () => {},
                });
        };
        

        return (
            <WrappedComponent
                memberNotifications={memberNotifications?.notifications}
                newNotificationsCount={newNotificationsCount}
                markAsReadMemberNotification={markAsReadMemberNotification}
                {...props}
            />
        );
    };
}
