import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface LoadingProps {
    message?: string;
    color?: string;
    size?: '2x' | '3x';
}

const Loading = ({ message, color, size ='2x' }: LoadingProps) => {
    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ height: '100%' }}
        >
            <p className="mb-2 hra-confirmation-submit-text">{message}</p>
            <FontAwesomeIcon
                icon={faCircleNotch}
                aria-label={message || 'Currently loading content'}
                size={size}
                style={{
                    color: color,
                }}
                data-testid="loadingIcon"
            />
        </div>
    );
};

export default Loading;
