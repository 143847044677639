import React, { useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import withDocumentData, { WithDocumentDataState } from '../../hoc/withDocumentData';
import { DocumentLink } from '../molecules/DocumentLink';
import PageHeader from '../molecules/PageHeader';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { PDFViewerContext } from 'contexts/PdfViewerContext';
import withEducationalResourcesData, {
    WithEducationalResourcesDataState,
} from 'hoc/withEducationalResourcesData';
import SearchBar from 'components/molecules/SearchBar';
import { usePlanDocumentsSearchFilter } from './usePlanDocumentsSearchFilter';
import { DocumentHeader } from 'types/documents/DocumentHeader';
import { sendCustomEvent } from 'common/utility';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useAdditionalResourcesData } from 'hooks/useAdditionalResources';

const SERVICE_URL = 'api/members/documents';
export type PlanDocumentsProps = WithDocumentDataState & WithEducationalResourcesDataState;

const PlanDocuments = (props: PlanDocumentsProps) => {
    const { addAndOpenDocument } = useContext(PDFViewerContext);
    const { additionalResources = [], loadingAdditionalResources } = useAdditionalResourcesData();
    const {
        filteredDocuments,
        filteredEducationalResources,
        filteredAdditionalResources,
        setSearchTerm,
    } = usePlanDocumentsSearchFilter(
        props.documents,
        props.educationalResources,
        additionalResources
    );

    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewPlanDocuments })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);
    const handleDocumentClick = useCallback(
        (document: DocumentHeader) => {
            const field = document.download_custom_event_name;
            const customEventType = AUDITEVENTTYPE[field as keyof typeof AUDITEVENTTYPE];
            customEventType && sendCustomEvent({ eventType: customEventType });
            addAndOpenDocument({
                serviceUrl: SERVICE_URL,
                documentPath: document.id,
                displayName: document.filename || '',
            });
        },
        [addAndOpenDocument]
    );

    return (
        <div>
            <div className="my-2">
                <SearchBar
                    change={(term) => {
                        setSearchTerm(term);
                    }}
                    placeholder="Search..."
                />
            </div>
            <PageHeader>
                <span className="bold-text">Plan Documents</span>
            </PageHeader>
            <ul className="file-list mb-4">
                {filteredDocuments
                    ?.filter((document) => document.data.document_type !== 'eob')
                    .map((document, i) => (
                        <DocumentLink
                            key={i}
                            document={document}
                            onDocumentClick={handleDocumentClick}
                        />
                    ))}
            </ul>
            <PageHeader>
                <span className="bold-text">Educational Resources</span>
            </PageHeader>
            <ul className="file-list mb-5">
                {props.loadingEducationalResources ? (
                    <div className="d-flex my-5">
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            aria-label="Currently Loading Content"
                            size="2x"
                        />
                    </div>
                ) : (
                    filteredEducationalResources?.map((resource, i) => (
                        <DocumentLink
                            key={i}
                            document={resource}
                            onDocumentClick={handleDocumentClick}
                        />
                    ))
                )}
            </ul>
            <PageHeader>
                <span className="bold-text">Additional Resources</span>
            </PageHeader>
            <ul className="file-list mb-5">
                {loadingAdditionalResources ? (
                    <div className="d-flex my-5">
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            aria-label="Currently Loading Content"
                            size="2x"
                        />
                    </div>
                ) : (
                    (filteredAdditionalResources || []).map((resource, i) =>
                        'href' in resource ? (
                            <li>
                                <a
                                    key={resource.filename}
                                    className="button-link"
                                    href="https://alignment.access.mcg.com/index"
                                    target="_alignment_mcg"
                                    onClick={() => {
                                        const customEvent =
                                            AUDITEVENTTYPE[
                                                resource.customEvent as keyof typeof AUDITEVENTTYPE
                                            ];
                                        if (customEvent) {
                                            sendCustomEvent({
                                                eventType: customEvent,
                                            });
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faExternalLinkAlt}
                                        aria-label="External Link"
                                        size='sm'
                                    />
                                    {resource.filename}
                                </a>
                            </li>
                        ) : (
                            <DocumentLink
                                key={i}
                                document={resource}
                                onDocumentClick={handleDocumentClick}
                            />
                        )
                    )
                )}
            </ul>
            <p>
                For additional member forms and resources, visit our{' '}
                <a href="https://www.alignmenthealthplan.com/members/member-forms-resources">
                    Members &amp; Resources Page
                </a>
            </p>
        </div>
    );
};

export default withEducationalResourcesData(withDocumentData(PlanDocuments));

PlanDocuments.propTypes = {
    documents: PropTypes.array,
};
