import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Icon from '../../atoms/Icon';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import FormHOC from '../../Form/FormHOC';
import loadOverlayService from '../../../services/loadOverlayService';
import appointmentService from '../../../services/appointmentService';
import appointmentRequestService from '../../../services/appointmentRequestService';
import { createdAppointment$, createdRequest$, jsaMemberInfo$ } from '../../../services/subjects';
import {
    fromDateTimeToMeetingTime,
    getMeetingTimeStringFromDateTime,
    getTimeZone,
    getDateTimeFormat,
} from 'common/utility';
import { pick } from 'lodash';
import CheckboxQuestion from '../../molecules/input/CheckboxQuestion';
import styles from '../ScheduleAppointmentForm.module.scss';
import { useNavigate } from 'react-router-dom';

const FormContent = ({ state, prevPageAction, props, ...rest }) => {
    const { control, getValues } = useFormContext();
    const [subscriptions, setSubscriptions] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        return () => {
            subscriptions.forEach((sub) => sub.unsubscribe());
        };
    }, []);

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };

    const handleConfirm = () => {
        const { pcp, identity } = props;

        const { startTime, endTime } = fromDateTimeToMeetingTime(
            state.startTime.date,
            { ...state.startTime, duration: state.reason.duration },
            null
        );
        const apptData = {
            memberId: identity.memberId,
            Provider: pcp,
            reason: state.reason.id,
            allscriptsAppointmentType: state.reason.appointmentType,
            notificationPreferences: [0],
            appointmentNotes: state.appointmentNotes,
            optionalAttendees: [],
            type: state.type,
            patientPhone: state.patientPhone,
            patientEmail: state.patientEmail,
            startTime: state.timeSlot.startTime,
            endTime,
            attachedFiles: [],
            virtualAppointmentStatus: 1,
            timeSlot: state.timeSlot,
            translatorPreferredLanguage: state.translatorPreferredLanguage,
            translatorRequired: state.translatorRequired,
            consent: state.consent,
        };

        loadOverlayService.show('Submitting...');

        const appointmentSubscription = appointmentService.create$(apptData).subscribe({
            next: () => {
                //if appointment reason is Jump Start Assessment (JSA) the jsa alert should be removed.
                if (apptData.reason == '90') {
                    jsaMemberInfo$.next({displayAlert: false, displayPendingBubble: false});
                }
                loadOverlayService.hide();
                createdAppointment$.next(apptData);
                navigate('/virtual-care');
            },
            error: (e) => {
                loadOverlayService.hide();
                setShowErrorModal(true);
            },
        });
        setSubscriptions([...subscriptions, appointmentSubscription]);
    };

    const handleConfirmRequest = () => {
        const { member, identity } = props;

        const requestData = {
            memberId: identity.memberId,
            requestReason: state.reason.id,
            requestNotes: state.appointmentNotes,
            visitType: state.type,
            memberPhone: state.patientPhone,
            memberEmail: state.patientEmail,
            Status: 1,
            preferredDate: state.preferredDate,
            preferredTimeOfDay: state.preferredTimeOfDay,
            translatorPreferredLanguage: state.translatorPreferredLanguage,
            translatorRequired: state.translatorRequired,
        };

        loadOverlayService.show('Submitting...');

        const requestSubscription = appointmentRequestService
            .createRequest$(requestData)
            .subscribe({
                next: () => {
                    //if appointment request reason is Jump Start Assessment (JSA) the jsa alert should be removed.
                    if (requestData.requestReason == '6') {
                        jsaMemberInfo$.next({displayAlert: false, displayPendingBubble: false});
                    }
                    loadOverlayService.hide();
                    createdRequest$.next(requestData);
                    navigate('/virtual-care');
                },
                error: (e) => {
                    loadOverlayService.hide();
                    setShowErrorModal(true);
                },
            });
        setSubscriptions([...subscriptions, requestSubscription]);
    };

    return (
        <>
            <div className={styles['calendar-container']} data-testid="step4">
                <Row>
                    <Col
                        sm="6"
                        className="text-center d-flex justify-content-center align-items-center"
                    >
                        <Icon icon={faCalendar} className={styles.calendar} />
                    </Col>
                    <Col sm="6">
                        <div>
                            <small>
                                <span className="bold-text">
                                    {state.preferredDate !== undefined && state.preferredDate !== ''
                                        ? 'Requested Date & Time Of Day'
                                        : 'Date & Time'}
                                </span>
                            </small>
                        </div>
                        <div>
                            {state.isAppointmentForm ? (
                                <span
                                    className="bold-text"
                                    data-testid={`dateConfirmation:${getMeetingTimeStringFromDateTime(
                                        state.timeSlot.date,
                                        {
                                            ...state.timeSlot,
                                            duration: state.reason.duration,
                                        }
                                    )}`}
                                >
                                    {getDateTimeFormat(
                                        state.timeSlot.startTime,
                                        'dddd, MMMM D, YYYY'
                                    )}
                                    <br />
                                    {getDateTimeFormat(state.timeSlot.startTime, 'h:mm A')}{' '}
                                    {getTimeZone()}
                                </span>
                            ) : state.preferredDate !== undefined && state.preferredDate !== '' ? (
                                <span>
                                    {`${state.preferredDate.toLocaleString('default', {
                                        month: 'long',
                                        day: 'numeric',
                                        year: 'numeric',
                                    })} - ${state.preferredTimeOfDay.map((time) => {
                                        return props.preferredTimes[time];
                                    })}`}
                                </span>
                            ) : (
                                <span>Request Pending</span>
                            )}
                        </div>
                        <br />
                        <div>
                            <small>
                                <span className="bold-text">Appointment Reason</span>
                            </small>
                        </div>
                        <div>
                            <span
                                className="bold-text"
                                data-testid={`reasonConfirmation:${state.reason.description}`}
                            >
                                {state.reason.description}
                            </span>
                        </div>
                        <br />
                        <div>
                            <small>
                                <span className="bold-text">Visit Type</span>
                            </small>
                        </div>
                        <div>
                            <span
                                className="bold-text"
                                data-testid={`typeConfirmation:${state.type}`}
                            >
                                {props.visitTypes[state.type]} Appointment
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <article className={`${styles.question} m-5`} aria-label={'consent-checkbox'}>
                        <Controller
                            control={control}
                            name="consent"
                            defaultValue={1}
                            render={({ field: { onChange, onBlur, value }}) => (
                                <CheckboxQuestion
                                    value={value}
                                    onChange={onChange}
                                    question={{
                                        info: (
                                            <>
                                                <span>
                                                    <strong>
                                                        By confirming this appointment, I agree to
                                                        receive care from my provider by phone or
                                                        video and consent to receive health care
                                                        services via telehealth.
                                                    </strong>
                                                </span>
                                                <br />
                                                <br />
                                                <span>
                                                    I understand that I can call{' '}
                                                    {state.isAppointmentForm
                                                        ? `1 (855) AVA-VIRTUAL
                                                    (855 - 282 - 8478)`
                                                        : '833-402-5803'}{' '}
                                                    for help with scheduling or to request an
                                                    in-person appointment. Copays may apply to
                                                    in-person visits based on my plan benefit
                                                    package.
                                                </span>
                                            </>
                                        ),
                                    }}
                                    option={{ label: 'consent', value: 1 }}
                                    disabled={true}
                                />
                            )}
                        />
                    </article>
                </Row>
            </div>
            <div className="text-center">
                <Button
                    onClick={() => {
                        navigate('/virtual-care');
                    }}
                    variant="secondary"
                    size="lg"
                    className="m-4"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => prevPageAction(getValues())}
                    variant="secondary"
                    size="lg"
                    className="m-4"
                >
                    Back
                </Button>
                <Button
                    onClick={state.isAppointmentForm ? handleConfirm : handleConfirmRequest}
                    variant="primary"
                    size="lg"
                    className="m-4"
                >
                    Confirm
                </Button>
            </div>
            <Modal centered show={showErrorModal} onHide={handleCloseErrorModal}>
                <Modal.Body>
                    {`We're sorry. There was an issue with booking your appointment. Please try again
                    later.`}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseErrorModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const AppointmentFourthStep = ({ state, onSubmit, props, ...rest }) => {
    const valuePaths = ['consent'];

    return (
        <FormHOC formDefaultValues={pick(state, valuePaths)}>
            <FormContent onSubmit={onSubmit} state={state} props={props} {...rest} />
        </FormHOC>
    );
};

export default AppointmentFourthStep;
