import React, { ChangeEvent } from 'react';
import InputQuestion from 'components/molecules/input/InputQuestion';
import { Control, Controller, ErrorOption } from 'react-hook-form';
import { isEmpty, get } from 'lodash';
import styles from 'components/molecules/input/InputQuestion.module.scss';

export interface TextFieldWithControllerProps {
    control: Control<any>;
    name: string;
    defaultValue: string | number;
    type: string;
    error: ErrorOption;
    onKeyUp: Event;
    question: string;
}
const TextFieldWithController = ({
    control,
    name,
    defaultValue = '',
    question,
    type,
    error,
    onKeyUp,
}: TextFieldWithControllerProps) => (
    <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value }}) => (
            <div className="pt-3">
                <InputQuestion
                    value={value}
                    type={type}
                    onChange={(e: ChangeEvent) => {
                        onChange(e);
                    }}
                    question={question}
                    option={{ label: name }}
                    onKeyUp={onKeyUp}
                />
                {!isEmpty(error) && (
                    <p className={styles['error-message']}>
                        {get(error, 'message', 'This field is incorrect')}
                    </p>
                )}
            </div>
        )}
    />
);
export { TextFieldWithController };
