import { map, switchMap, publishBehavior, distinctUntilChanged, filter, shareReplay, startWith } from 'rxjs/operators';
import { ReplaySubject, Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { ajax } from 'rxjs/ajax';

const accessToken$ = new ReplaySubject(1);

const authHeaders$ = accessToken$.pipe(
    distinctUntilChanged(),
    map((token) => ({
        Authorization: `Bearer ${token}`,
    }))
);

const jsonHeaders$ = accessToken$.pipe(
    distinctUntilChanged(),
    map((token) => ({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    }))
);

const env$ = new ReplaySubject(1);

const identity$ = new ReplaySubject(1);

const tokenExpiration$ = new ReplaySubject(1);

const refreshToken$ = new ReplaySubject(1);

const beforeRedirect$ = new Subject();

const lastMessageCategorySent$ = new Subject();

const memberId$ = identity$.pipe(map((identity) => identity.memberId));

function isBeforeToday(dateString) {
    if (!dateString) return false;
    let parts = dateString.split('-');
    if (parts.length !== 3) return false;
    return new Date(parts[0], parseInt(parts[1]) - 1, parts[2]) < Date.now();
}

function isDisenrolled(member) {
    return member.termination_date === undefined || isBeforeToday(member.termination_date);
}

const member$ = combineLatest(jsonHeaders$, memberId$).pipe(
    switchMap(([headers, memberId]) =>
        ajax({
            method: 'GET',
            url: `api/enrollment/members/${memberId}`,
            headers,
        })
    ),
    map((response) => response.response),
    publishBehavior()
);

const hasSentMessageCategory = (category) =>
    jsonHeaders$.pipe(switchMap((headers) => ajax({ method: 'POST', url: `api/messages/hasSentMessageCategory`, headers, body: { category } })), map((response) => response.response));   

const memberAdas$ = jsonHeaders$.pipe(
    switchMap((headers) =>
        ajax({
            method: 'GET',
            url: `api/member`,
            headers,
        })
    ),
    map((response) => response.response),
    publishBehavior()
);

const pcp$ = jsonHeaders$.pipe(
    switchMap((headers) =>
        ajax({
            method: 'GET',
            url: `api/pcp`,
            headers,
        })
    ),
    map((response) => response.response),
    map((pcp) => {
        pcp.name = `${pcp.nppes.firstName} ${pcp.nppes.lastName}, ${pcp.nppes.suffix}`;
        return pcp;
    }),
    publishBehavior()
);

const isDisenrolled$ = member$.pipe(map(isDisenrolled), publishBehavior());

const policies$ = jsonHeaders$.pipe(
    switchMap((headers) =>
        ajax({
            method: 'GET',
            url: `api/enrollment/policies`,
            headers,
        })
    ),
    filter((response) => !!response.response),
    filter((response) => !!response.response.results),
    filter((response) => !!response.response.results.length),
    filter((response) => !!response.response.results[0].policies),
    map((response) => response.response.results[0].policies),
    publishBehavior()
);

const plans$ = new BehaviorSubject(null);

const documents$ = new BehaviorSubject(null);

const appointments$ = new BehaviorSubject(null);

const unreadMessageCount$ = new BehaviorSubject(null);

const messages$ = new BehaviorSubject(null);

const idCards$ = new BehaviorSubject(null);

const idCardImg$ = new BehaviorSubject(null);

const claims$ = new BehaviorSubject(null);

const medications$ = new BehaviorSubject(null);

const labs$ = new BehaviorSubject(null);

const benefits$ = new BehaviorSubject(null);

const preferenceData$ = new BehaviorSubject(null);

const userPreferences$ = new BehaviorSubject(null);

const lastUpdated$ = new BehaviorSubject(null);

const createdAppointment$ = new BehaviorSubject(null);

const createdRequest$ = new BehaviorSubject(null);

const createdJsaRequest$ = new BehaviorSubject(null);

const memberPbpAssignmentHistory$ = new BehaviorSubject(null);

const languages$ = new BehaviorSubject(null);

const ipas$ = new BehaviorSubject(null);

const notifications$ = new Subject();

const emergencyContactInfo$ = new BehaviorSubject(null);

const memberPortalConfig$ = new BehaviorSubject(null);

const jsaMemberInfo$ = new BehaviorSubject({ displayAlert: false, displayPendingBubble: false, isJsa: false });

const submittedHRA$ = new BehaviorSubject(false);

const appointmentReasons$ = jsonHeaders$.pipe(
    switchMap((headers) =>
        ajax({
            method: 'GET',
            url: `api/appointments/reasons`,
            headers,
        })
    ),
    map((response) => response.response),
    publishBehavior()
);

const appointmentRequestReasons$ = jsonHeaders$.pipe(
    switchMap((headers) =>
        ajax({
            method: 'GET',
            url: `api/appointmentrequests/reasons`,
            headers,
        })
    ),
    map((response) => response.response),
    publishBehavior()
);

const appointmentTypes$ = jsonHeaders$.pipe(
    switchMap((headers) =>
        ajax({
            method: 'GET',
            url: `api/appointments/types`,
            headers,
        })
    ),
    map((response) => response.response),
    publishBehavior()
);

const preferredTimes$ = jsonHeaders$.pipe(
    switchMap((headers) =>
        ajax({
            method: 'GET',
            url: `api/appointmentrequests/PreferredTimes`,
            headers,
        })
    ),
    map((response) => response.response),
    publishBehavior()
);

const appointmentStatuses$ = jsonHeaders$.pipe(
    switchMap((headers) =>
        ajax({
            method: 'GET',
            url: `api/appointments/statuses`,
            headers,
        })
    ),
    map((response) => response.response),
    publishBehavior()
);

const appointmentRequestStatuses$ = jsonHeaders$.pipe(
    switchMap((headers) =>
        ajax({
            method: 'GET',
            url: `api/appointmentRequests/statuses`,
            headers,
        })
    ),
    map((response) => response.response),
    publishBehavior()
);

const cmLetters$ = (skip, take) =>
    jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/healthSummary/getCareManagementLetters?skip=${skip}&take=${take}`,
                headers,
            })
        ),
        map((response) => response.response)
    );

const authorizationLetters$ = (skip, take) =>
    jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/healthSummary/getAuthorizationLetters?skip=${skip}&take=${take}`,
                headers,
            })
        ),
        map((response) => response.response)
    );

export {
    env$,
    lastUpdated$,
    accessToken$,
    identity$,
    memberId$,
    emergencyContactInfo$,
    member$,
    memberAdas$,
    pcp$,
    policies$,
    plans$,
    documents$,
    messages$,
    unreadMessageCount$,
    idCards$,
    idCardImg$,
    claims$,
    benefits$,
    preferenceData$,
    userPreferences$,
    isDisenrolled$,
    notifications$,
    appointments$,
    medications$,
    labs$,
    jsonHeaders$,
    authHeaders$,
    appointmentReasons$,
    appointmentRequestReasons$,
    appointmentTypes$,
    preferredTimes$,
    appointmentStatuses$,
    appointmentRequestStatuses$,
    createdAppointment$,
    createdRequest$,
    tokenExpiration$,
    refreshToken$,
    memberPbpAssignmentHistory$,
    languages$,
    ipas$,
    memberPortalConfig$,
    cmLetters$,
    beforeRedirect$,
    authorizationLetters$,
    jsaMemberInfo$,
    submittedHRA$,
    createdJsaRequest$,
    hasSentMessageCategory,
    lastMessageCategorySent$,
};
