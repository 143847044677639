import { useEffect, useState } from 'react';
import useFeatureFlags from './useFeatureFlags';

function useFeatureFlag(featureFlag: string) {
    const [enabled, setEnabled] = useState<boolean>(false);

    const { isFeatureFlagLoading, featureFlags } = useFeatureFlags() as { isFeatureFlagLoading: boolean, featureFlags: Record<string, boolean> };

    useEffect(() => {
       if(featureFlags) {
              setEnabled(featureFlags[featureFlag]);
       }
    }, [featureFlags]);

    return {isFeatureFlagLoading, enabled};
}

export default useFeatureFlag;
