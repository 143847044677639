import React, { ChangeEvent } from 'react';
import { Controller, Control, ErrorOption } from 'react-hook-form';
import styles from 'components/molecules/input/TextAreaQuestion.module.scss';
import { Row, Col } from 'react-bootstrap';

interface TextAreaFieldProps {
    name: string;
    question: string | undefined;
    onChange: (e: string) => void;
    value: string;
}
export interface TextAreaFieldWithControllerProps {
    control: Control<any>;
    question: string | undefined;
    name: string;
    defaultValue: string | undefined;
    error: ErrorOption;
}

const TextAreaQuestion = ({ onChange, question, value, name }: TextAreaFieldProps) => {
    return (
        <Row className="pt-3">
            <Col>
                <label
                    id={`label-textarea-question-${name}`}
                    className={'h5 mt-3 font-weight-bold'}
                    data-testid="textareaTitle"
                >
                    {question}
                </label>
                <textarea
                    id={name}
                    className={styles.textarea}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        onChange(e.target.value);
                    }}
                    value={value || ''}
                    name={name}
                    aria-labelledby={`label-textarea-question-${name}`}
                />
            </Col>
        </Row>
    );
};

const TextAreaFieldWithController = ({
    control,
    name,
    defaultValue = '',
    question,
}: TextAreaFieldWithControllerProps) => (
    <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }}) => (
            <TextAreaQuestion
                value={value}
                onChange={(e) => {
                    onChange(e);
                }}
                question={question}
                name={name}
            />
        )}
    />
);
export { TextAreaFieldWithController };
