import React, { Component } from 'react';
import { userPreferences$ } from '../services/subjects';
import { switchMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import preferenceService from '../services/preferenceService';

export default function withPreferenceData(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {};
            this.subscriptions = [];
        }

        componentDidMount() {
            const fetchUserPreferencesFromServer$ = preferenceService
                .getUserPreference$()
                .pipe(
                    map((response) => response.response),
                    tap((data) => userPreferences$.next(data ? data : []))
                );
            this.subscriptions.push(
                userPreferences$
                    .pipe(
                        switchMap((data) =>
                            data === null ? fetchUserPreferencesFromServer$ : of(data)
                        )
                    )
                    .subscribe((userPreferences) => this.setState({ userPreferences }))
            );
        }

        componentWillUnmount() {
            this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        }
        render() {
            return (
                <WrappedComponent
                    userPreferences={this.state.userPreferences}
                    updatePreference={(preferences) => {
                        this.setState({ updating: true });

                        const newUserPreferences = this.state.userPreferences.map(organizedPreference => {
                            organizedPreference.names = preferences.filter(preference => organizedPreference.campaignCategory === preference.campaignCategory);
                            return organizedPreference;
                        });
                        userPreferences$.next(newUserPreferences);
                        return preferenceService
                            .updateUserPreference$(preferences)
                            .subscribe(
                                (_) => this.setState({ updating: false }),
                                (_) => this.setState({ updating: false, updateFailure: true })
                            );
                    }}
                    updating={this.state.updating}
                    updateFailure={this.state.updateFailure}
                    {...this.props}
                />
            );
        }
    };
}
