import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormHOC from 'components/Form/FormHOC';
import { HraQuestion } from './HraQuestion';
import { Button } from 'react-bootstrap';
import { PAGES } from './util';
import PageHeader from 'components/molecules/PageHeader';

interface Props {
    state: any;
    onSubmit: () => void;
    props: any;
    rest: any;
}
export const DetailsStep = ({ state, onSubmit, props, ...rest } : Props) => {
    const { hraForm, createHRA, member } = props;

    const formData = hraForm[PAGES.DETAILS_NEXT_STEPS];
    const filteredQuestions = formData?.subSections[0]?.questions;

    const onCreateHRA =  () => {
         createHRA(member, state);
    };

    useEffect(() => {
        if (state.submit) {
            onCreateHRA();
        }
    }, [state.submit]);

    const getQuestions = () =>
        filteredQuestions.map((question:any, id:number) => {
            return (
                <div className="mt-5 mb-5" key={`question-container${id}`}>
                    <HraQuestion key={`hraquestion ${id}`} question={question} />
                </div>
            );
        });

    const Form = ({ onSubmit, prevPageAction } :any) => {
        const { handleSubmit, getValues } = useFormContext();
        return (
            <div className="mt-5 mb-5">
                <PageHeader>
                    <span className="bold-text" style={{fontSize: '2rem'}}>
                        Based on your responses, a care team member may follow-up with you. If you
                        need assistance or have any questions, please call our Care Management team
                        at 833-835-5482.
                    </span>
                </PageHeader>
                {getQuestions()}
                <div className="text-center mt-5">
                    <Button
                        onClick={() => prevPageAction(getValues())}
                        variant="secondary"
                        size="lg"
                        className="m-4"
                    >
                        Back
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} variant="primary" size="lg">
                        Submit
                    </Button>
                </div>{' '}
            </div>
        );
    };

    return (
        <FormHOC formDefaultValues={state.answers}>
            <Form onSubmit={onSubmit} props={props} {...rest} />
        </FormHOC>
    );
};
