import { ajax } from 'rxjs/ajax';
import { map, switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';
import { getSearchParamsString } from 'common/utility';
import {
    CompleteHRARequest,
    CreateHRARequest,
    HealthRiskAssessment,
} from 'interfaces/HealthRiskAssessment';

const healthRiskAssessmentService = {
    submitHealthRiskAssessment$: (HraData: HealthRiskAssessment) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/HealthRiskAssessment`,
                    headers,
                    body: HraData,
                }).pipe(map((response) => response))
            )
        ),
    getHealthRiskAssessmentPdf$: (applicationId: string, storageBlobId: string) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/HealthRiskAssessment/download?${getSearchParamsString({
                        applicationId,
                        storageBlobId,
                    })}`,
                    headers,
                    responseType: 'arraybuffer',
                }).pipe(map((response) => response.response))
            )
        ),
    getHealthRiskAssessmentForm$: () =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/HealthRiskAssessment/GetHRAForm`,
                    headers,
                }).pipe(map((response: any) => response?.response?.value?.result))
            )
        ),
    getCMProblems$: () =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/HealthRiskAssessment/GetCarePlanProblems`,
                    headers,
                }).pipe(map((response) => response?.response))
            )
        ),
    createHRA$: (HraData: CreateHRARequest) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/HealthRiskAssessment/CreateHRA`,
                    headers,
                    body: HraData,
                }).pipe(map((response) => response?.response))
            )
        ),
    completeHRAInformation$: (assessmentId: string, HraData: CompleteHRARequest) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'PATCH',
                    url: `api/HealthRiskAssessment/UpdateAssessment/${assessmentId}`,
                    headers,
                    body: HraData,
                }).pipe(map((response) => response?.response))
            )
        ),
    getMemberAssessments$: (memberId: string) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/HealthRiskAssessment/GetAssessmentByMemberId/${memberId}`,
                    headers,
                }).pipe(map((response) => response?.response))
            )
        ),
    getAssessmentPDF$: (id: string) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/HealthRiskAssessment/GetPDFAssestmentById/${id}`,
                    headers,
                    responseType: 'blob',
                }).pipe((response) => response)
            )
        ),
};

export { healthRiskAssessmentService };
