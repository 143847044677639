import React, { useEffect, useState, JSX } from "react";
import type { Member } from "types/Member";
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faTimes } from "@fortawesome/free-solid-svg-icons";
import styles from './CampaignPopUp.module.scss';
import type { MemberPortalCampaignEligibilityConfig } from "interfaces/MemberPortalConfig";
import { hasSentMessageCategory, lastMessageCategorySent$ } from "services/subjects";

export type CampaignPopUpProps = {
    member: Member;
    alertType: 'danger' | 'warning' | 'info';
    title: string;
    campaignConfig: MemberPortalCampaignEligibilityConfig;
    message: JSX.Element;
};

export function CampaignPopUp({member, alertType, title, message, campaignConfig}: CampaignPopUpProps) {
    const [showPopUp, setShowPopUp] = useState(false);

    useEffect(() => {
        if(!campaignConfig.messageCenterCategory) {
            return;
        }
        const campaignEligibility = member?.campaignEligibilities?.find(campaign => campaign.campaignId === campaignConfig?.campaignId);
        if(!campaignEligibility) {
            return;
        }
        const hasSentMessageCategorySubscription = hasSentMessageCategory(campaignConfig.messageCenterCategory).subscribe({
            next: (sent) => {
                if(!sent && !campaignEligibility.caseCreated) {
                    setShowPopUp(true);
                } else {
                    setShowPopUp(false);
                }
            },
            error: console.error,
        });

        const lastMessageCategorySentSubscription = lastMessageCategorySent$.subscribe({
            next: (messageCategory) => {
                if(messageCategory === campaignConfig?.messageCenterCategory) {
                    setShowPopUp(false);
                }
            },
        });

        return () => {
            hasSentMessageCategorySubscription.unsubscribe();
            lastMessageCategorySentSubscription.unsubscribe();
        };
    }, [member?.campaignEligibilities, campaignConfig.messageCenterCategory]);

    function getIconColor() {
        switch(alertType) {
            case 'danger':
                return 'rgb(142.56, 60.8, 37.36)';
            case 'warning':
                return 'rgb(150.36, 119.56, 23.32)';
            case 'info':
                return 'rgb(29.72, 103.44, 115.36)';
            default:
                return 'rgb(142.56, 60.8, 37.36)';
        }
    }

    function handleClickClose() {
        setShowPopUp(false);
    }

    return (
        <AnimatePresence>
            {showPopUp &&  <motion.article
                animate={{ scale: 1, opacity: 1}}
                initial={{ scale: 0.5, opacity: 0}}
            >
            <article className={`alert alert-${alertType} ${styles.alert}`} role="alert">
                <button type="button" className={`${styles['close-btn']}`} aria-label="Close" onClick={handleClickClose}>
                    <FontAwesomeIcon icon={faTimes} color={getIconColor()}/>
                </button>
                <p className={styles.title}>
                    <FontAwesomeIcon icon={faBell} className={styles['bell-icon']}/>
                    <strong>{title}</strong>
                </p>
                <p>
                {message}
                </p>
            </article>
            </motion.article>}
        </AnimatePresence>
    );
}
