import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { PreferenceOption } from 'types/Preference';


export type PreferenceOptionCheckboxProps = {
    option: PreferenceOption;
    campaignCategory: string;
    campaignName: string;
    invalidEmail: boolean;
    onChange: (newOption: PreferenceOption, email: boolean) => void;
}

export default function PreferenceOptionCheckbox({
    option,
    campaignName,
    campaignCategory,
    invalidEmail,
    onChange,
}: PreferenceOptionCheckboxProps) {
    return (
        <FormGroup>
            <Input
                disabled={(option.communicationType === 'EMAIL' && invalidEmail) || !option.enabled}
                key={`${option.communicationType}_${campaignName}`}
                multiple={true}
                id={`${option.communicationType}_${campaignName}`}
                className="d-flex"
                type="checkbox"
                name={`preference_${campaignName}`}
                checked={option.active}
                onChange={(e) => {
                    onChange({...option, active: e.target.checked}, option.communicationType === 'EMAIL');
                }}
            />
            <Label htmlFor={option.communicationType} check>
                {option.communicationType}
            </Label>
        </FormGroup>
    );
}
