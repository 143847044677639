import React from 'react';
import PropTypes from 'prop-types';
import { handleEnterKey } from '../../../common/accessibilityHelpers';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './RadioQuestion.module.scss';

const deserialize = (value) => {
    if(typeof value === 'object') {
        return Object.entries(value).sort(([keya], [keyb])=> keya - keyb).map(([key, value]) => {
            return deserialize(value);
        }).join(',');
    }
    return value.toString();
}

const RadioQuestion = (props) => {
    const isChecked = (optionValue) => {
        const { value, hraFlag } = props;
        if (hraFlag) {
            const optionId = Object.keys(optionValue)[0];
            return value[optionId];
        } else {
            return deserialize(value) === deserialize(optionValue);
        }
    };
    return (
        <>
            <p className={styles['question-title']}>{props.question}</p>
            <Row role="radiogroup" aria-labelledby={`label-radiogroup`}>
                {props.options.map((option, i) => (
                    <Col key={`radio-option-${i}`} md={props.isColumn ? '12' : '6'}>
                        <input
                            className={
                                option.disabled
                                    ? `${styles.radio} ${styles.disabled}`
                                    : styles.radio
                            }
                            data-testid={option.label + props?.id}
                            type="radio"
                            checked={isChecked(option.value)}
                            name={option.label + props?.id}
                            onChange={(_) => props.onChange(option.value)}
                            id={option.label + props?.id}
                            value={option.value}
                            aria-label={option.label}
                            disabled={option.disabled}
                        />
                        <label
                            key={`radio-option-${i}`}
                            className={styles.label}
                            htmlFor={option.label}
                            tabIndex="0"
                            onKeyUp={handleEnterKey(() => props.onChange(option.value))}
                            ref={props.forwardedRef}
                            style={{
                                marginBottom: 0,
                                marginTop: 0,
                                color: option.disabled ? 'gray' : '',
                            }}
                        >
                            {option.label}

                            {option.note !== undefined ? (
                                <>
                                    {' '}
                                    <br />{' '}
                                    <span>
                                        <b>Note:</b> {option.note}
                                    </span>{' '}
                                </>
                            ) : (
                                ''
                            )}
                            {option.icon && option.customIcon ? (
                                option.icon
                            ) : option.icon && (
                                <FontAwesomeIcon icon={option.icon} className={styles.icon} />
                            )}
                        </label>
                    </Col>
                ))}
            </Row>
            {props.error && <p style={{ color: 'red', marginTop: '2%' }}>This field is required</p>}
        </>
    );
};

RadioQuestion.propTypes = {
    isColumn: PropTypes.bool,
    error: PropTypes.bool,
    value: PropTypes.any,
    onBlur: PropTypes.any,
    onChange: PropTypes.any,
    id: PropTypes.string,
    hraFlag: PropTypes.bool,
    question: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
                label: PropTypes.string,
                note: PropTypes.string,
                value: PropTypes.any,
                icon: PropTypes.object,
                disabled :PropTypes.bool,
                customIcon: PropTypes.bool
            }),
    ),
};

export default RadioQuestion;
