import React, { useMemo, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import SearchBar from '../../../../molecules/SearchBar';
import ResponsiveTable from '../../../../organisms/ResponsiveTable';

import styles from './AppointmentGrids.module.scss';
import { of } from 'rxjs';
import useObservable from 'hooks/useObservable';
import { Loader } from 'components/v2/atoms/Loader/Loader';
import { delay } from 'rxjs/operators';

interface AppointmentGridsProps {}

export const AppointmentGrids = ({}: AppointmentGridsProps) => {
    const shouldShowRequests$ = useMemo(() => of(true).pipe(delay(1000)), []);
    const pastAppointments$ = useMemo(() => of([{}]), []);
    const pastAppointmentRequests$ = useMemo(() => of([{}]), []);
    const isSummaryNotesEnabled$ = useMemo(() => of(true), []);

    const shouldShowRequests = useObservable(shouldShowRequests$, null);
    const pastAppointmentsGridData = useObservable(pastAppointments$, null);
    const pastAppointmentRequestsGridData = useObservable(pastAppointmentRequests$, null);
    const isSummaryNotesEnabled = useObservable(isSummaryNotesEnabled$, null);

    const [appointmentSummary, setAppointmentSummary] = useState<object | null>(null);

    const pastAppointmentsGridFields = useMemo(() => {
        var fields = [
            {
                header: 'Date & Time',
                col: { lg: isSummaryNotesEnabled ? 3 : 4 },
                name: 'timeBlock',
            },
            {
                header: 'Reason',
                col: { lg: 2 },
                name: 'reason',
            },
            {
                header: 'Provider',
                col: { lg: 2 },
                name: 'provider.providerFullName',
            },
            {
                header: 'Visit Type',
                col: { lg: 2 },
                name: 'type',
            },
            {
                header: 'Status',
                col: { lg: 2 },
                name: 'status',
            },
        ];

        if (isSummaryNotesEnabled) {
            var notesField = {
                header: 'Notes',
                col: { lg: 1 },
                name: 'summaryNotesIcon',
                onClick: (_: unknown, appointment: object) => setAppointmentSummary(appointment),
                sortable: false,
            };

            fields.push(notesField);
        }

        return fields;
    }, [isSummaryNotesEnabled]);

    const pastAppointmentRequestsGridFields = [
        {
            header: 'Reason',
            col: { lg: 4 },
            name: 'reason',
        },
        {
            header: 'Visit Type',
            col: { lg: 4 },
            name: 'type',
        },
        {
            header: 'Status',
            col: { lg: 4 },
            name: 'status',
        },
    ];

    const handleSearchChange = (term: string, isApptRequest: boolean) => {
        console.log(term, isApptRequest);
    };

    return (
        <section className="mt-5">
            <h3 className={`${styles.labeled}`}>Past Appointments</h3>
            <Tabs>
                <Tab eventKey="appointments" title="Appointments">
                    <SearchBar placeholder="Search Past Appointments" change={handleSearchChange} />
                    <div data-testid="rowPastAppointments">
                        {pastAppointmentsGridData === null ? (
                            <Loader size="md" />
                        ) : (
                            <ResponsiveTable
                                data={pastAppointmentsGridData}
                                fields={pastAppointmentsGridFields}
                            />
                        )}
                    </div>
                </Tab>
                {shouldShowRequests && (
                    <Tab eventKey="requests" title="Appointment Requests">
                        <SearchBar
                            placeholder="Search Past Appointment Requests"
                            change={(value) => {
                                handleSearchChange(value, true);
                            }}
                        />
                        <div>
                            {pastAppointmentRequestsGridData === null ? (
                                <Loader size="md" />
                            ) : (
                                <ResponsiveTable
                                    data={pastAppointmentRequestsGridData}
                                    fields={pastAppointmentRequestsGridFields}
                                />
                            )}
                        </div>
                    </Tab>
                )}
            </Tabs>
        </section>
    );
};
