import React, { Dispatch } from 'react';
import { Container } from 'reactstrap';
import TablePagination from '../molecules/TablePagination';
import SimpleTableContent, { SimpleTableContentProps } from 'components/molecules/SimpleTableContent';


export type ServerResponsiveTableProps<T> = SimpleTableContentProps<T> & {
    loading: boolean;
    pageNumber: number;
    tableSize: number; // totalCount
    setPageNumber: Dispatch<number>;
    pageSettings: {
        pageCount: number;
        pageSize: number;
    };
};

export default function ServerResponsiveTable<T>(props: ServerResponsiveTableProps<T>) {
    return (
        <Container className="table">
            <SimpleTableContent
                boldRowIf={props.boldRowIf}
                data={props.data}
                fields={props.fields}
                sortBy={props.sortBy}
                sortDirection={props.sortDirection}
                onClickSort={props.onClickSort}
                tableSize={props.pageSettings.pageSize}
                loading={props.loading}
            />
            <TablePagination
                loading={props.loading}
                pageNumber={props.pageNumber}
                setPageNumber={props.setPageNumber}
                tableSize={props.tableSize}
                pageSettings={props.pageSettings}
            />
        </Container>
    );
}