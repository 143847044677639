export const defaultValiations = {
    address1Error: false,
    cityError: false,
    stateError: false,
    zipError: false,
    errorMessage: 'Error! - This field could not be empty',
};

export const emptyAddressSuggestions = {
    address1: null,
    address2: null,
    state: null,
    city: null,
    zip5: null,
};

export const emptyVerifiedAddress = {
    address1: "",
    address2: "",
    state: "",
    city: "",
    zip5: "",
};