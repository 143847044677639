import React from 'react';
import { Preference, PreferenceOption } from 'types/Preference';
import PreferenceOptionRadios from './PreferenceOptionRadios';
import PreferenceOptionCheckboxes from './PreferenceOptionCheckboxes';

export type PreferenceOptionProps = {
    preference: Preference;
    campaignName: string;
    onChange: (newPreference: Preference, email: boolean) => void;
    invalidEmail: boolean;
    cmUMFeatureFlagIsActive?: boolean;
}

const PreferenceOptionInput = ({
    preference,
    onChange,
    invalidEmail,
    cmUMFeatureFlagIsActive,
}: PreferenceOptionProps) => {
    const handleChange = (newOption: PreferenceOption, email: boolean): void => {
        onChange({
            ...preference, options: preference.options.map(option => {
                if (option.id === newOption.id || option.communicationType === newOption.communicationType) {
                    return newOption;
                }
                if(preference.selectionType === 'RADIO') {
                    return {...option, active: false};
                }

                return option;
            })
        }, email);
    };
    if (preference.selectionType === 'RADIO') {
        return <PreferenceOptionRadios cmUMFeatureFlagIsActive={cmUMFeatureFlagIsActive} options={preference.options} onChange={handleChange} invalidEmail={invalidEmail} campaignName={preference.campaignName} campaignCategory={preference.campaignCategory} />;
    } else if (preference.selectionType === 'CHECKBOX') {
        return <PreferenceOptionCheckboxes options={preference.options} onChange={handleChange} invalidEmail={invalidEmail} campaignName={preference.campaignName} campaignCategory={preference.campaignCategory} />;
    }
    else {
        return 'Uknown selection';
    }
};

export default PreferenceOptionInput;
