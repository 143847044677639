import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Badge,
} from 'reactstrap';
import styles from './NotificationsDropdown.module.scss';
import { DropdownNotification } from '../DropdownNotification/DropdownNotification';

interface Props {
    newNotificationsCount: number;
    memberNotifications: any[];
    markAsReadMemberNotification: (notificationId:string) => void;
}

export const NotificationsDropdown = ({ newNotificationsCount, memberNotifications, markAsReadMemberNotification }: Props) => {
    return (
        <UncontrolledDropdown className="mr-1">
            <DropdownToggle nav aria-label="notifications-dropdown" className="mr-2">
                <FontAwesomeIcon
                    icon={faBell}
                    size="2x"
                    aria-label="Navigate to Message(s) Button"
                />
                {newNotificationsCount > 0 && (
                    <Badge className={styles.badge}>{newNotificationsCount}</Badge>
                )}
            </DropdownToggle>
            {memberNotifications?.length > 0 && (
                <DropdownMenu right style={{ borderRadius: 0, padding: 0 }}>
                    {memberNotifications?.map((n, i) => {
                        return (
                            <DropdownItem
                                key={`notification-number ${i}`}
                                style={{ padding: 0 }}
                                onClick={() => markAsReadMemberNotification(n.id)}
                            >
                                <DropdownNotification
                                    key={`DropdownNotification-number ${i}`}
                                    notification={n}
                                    dropdownStyle="legacy"
                                />
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            )}
        </UncontrolledDropdown>
    );
};
