import React, { useState, Dispatch, SetStateAction } from 'react';
import Styles from './SelectInput.module.scss';
import { FormGroup, Label, Input, Tooltip, FormFeedback } from 'reactstrap';

interface Props {
    label: string;
    disabled?: boolean;
    onChange?: (a:any) => void | Dispatch<SetStateAction<string>>;
    defaultValue: string | number;
    id: string;
    tooltip?: string | null;
    options: { value: string; description: string }[];
    error?: boolean;
    errorMesssage?: string;
}

export const SelectInput = ({
    label,
    onChange = () => {},
    disabled = false,
    id,
    defaultValue,
    tooltip = null,
    options,
    error = false,
    errorMesssage = 'Invalid Value',
}: Props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <FormGroup>
            <Label id={`label-for${id}`} for={id} className={Styles['form-select-input-label']}>
                {label}
            </Label>
            <Input
                invalid={error}
                className={`${Styles['form-select-input-text']}  ${
                    disabled ? Styles['form-select-input-text-disbled'] : ''
                }`}
                aria-labelledby={`label-for${id}`}
                name={id}
                id={id}
                type="select"
                value={defaultValue}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
            >
                {options.map((o, i) => (
                    <option key={'option' + i} value={o.value}>
                        {o.description}
                    </option>
                ))}
            </Input>
            <FormFeedback> {errorMesssage} </FormFeedback>
            {tooltip && (
                <Tooltip target={id} toggle={toggle} isOpen={tooltipOpen} placement="right">
                    <span className={Styles['form-select-input-tooltip']}> {tooltip} </span>
                </Tooltip>
            )}
        </FormGroup>
    );
};
