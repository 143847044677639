import React from 'react';
import { handleEnterKeyToClick } from '../../../common/accessibilityHelpers';
import { Col, Row } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import styles from '../VirtualCare.module.scss';

interface Props {
    show: boolean;
    handleAlertClose: () => void;
}
export const SuccessModal = ({ show, handleAlertClose }: Props) => {
    return (
        <Modal centered show={show} onHide={handleAlertClose}>
            <div className={styles['success-alert']}>
                <div className={styles['calendar-icon-container']}>
                    <FontAwesomeIcon
                        className={styles['calendar-icon']}
                        aria-label="Success"
                        size="3x"
                        icon={faCheckCircle}
                    />
                </div>
                <div className="text-center">
                    <h4>Your Health Risk Assessment was submitted!</h4>
                    <br />
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-center">
                                <button
                                    className={styles['modal-btn']}
                                    onClick={handleAlertClose}
                                    onKeyUp={handleEnterKeyToClick}
                                    tabIndex={0}
                                >
                                    OK
                                </button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Modal>
    );
};
