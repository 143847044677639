import React, { forwardRef, HTMLProps } from 'react';
import './pageHeader.css';

const PageHeader = forwardRef<HTMLHeadingElement, HTMLProps<HTMLHeadingElement>>(({ children, ...rest }, ref) => (
    <h1 className="page-header" {...rest} ref={ref}>
        {children}
    </h1>
));

export default PageHeader;
