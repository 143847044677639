import { useState, useEffect } from 'react';
import jsaAppointmentService from 'services/jsaAppointmentService';
import { jsaMemberInfo$ } from 'services/subjects';

export const useJSAData = () => {
    const [loading, setLoading] = useState<boolean>(true)

    const getJSAData = () => {
        jsaAppointmentService.getJsaMemberAlert$().subscribe({
            next: (res) => {
                jsaMemberInfo$.next(res?.response)
                setLoading(false);
            },
            error: () => {
                setLoading(false);
            },
        });
    };

    useEffect(() => {
        getJSAData();
    }, []);

    return { getJSAData, loading };
};

export default useJSAData;
