import React from 'react';
import style from './NotificationsList.module.scss';
import { MemberNotification } from 'types/notifications';
import { getNotificationDateFormat } from '../DropdownNotification/DropdownNotification';
import { ReactComponent as ArchiveIcon } from 'icons/v2/cancel-x.svg';
import usePagination from 'hooks/usePagination';
import { Pagination } from 'components/v2/organisms/Pagination/Pagination';
import { getDescriptionWithLocalTimezoneDates, jsDateTimeToString } from 'common/utility';


interface Props {
    notifications: MemberNotification[];
    onArchiveNotification: (notificationId: string) => void;
}
const pageSettings = { pageSize: 10, pageCount:
     3 }

export const NotificationsList = ({ notifications, onArchiveNotification }: Props) => {
    const { displayData, setPageNumber, pageNumber } = usePagination({
        data: notifications,
        pageSettings: pageSettings,
    });
    if (notifications.length == 0) {
        return <span> You currently have no notifications </span>;
    }

    const getMemberNotificationRow = (notification: MemberNotification, index: number) => {
        const lastOne = index == displayData.length - 1 ? true : false;
        return (
            <div
                className={`${style['notification-list-container']}  ${
                    lastOne ? style['notification-list-lastItem'] : ''
                }`}
            >
                <div className={style['notification-dot-container']}>
                    {!notification.isRead && <div className={style['notification-dot']} />}
                </div>
                <div className={style['notification-list-container-description']}>
                    <span className={style['notification-list-title']}> {notification.title} </span>
                    <span className={style['notification-list-description']}>
                        {' '}
                        {getDescriptionWithLocalTimezoneDates(notification.description)}{' '}
                    </span>
                    <span className={style['notification-list-date']}>
                        {' '}
                        {getNotificationDateFormat(notification.date)}{' '}
                    </span>
                </div>
                <div>
                    {!notification.isArchived && (
                        <button
                            onClick={() => onArchiveNotification(notification.id)}
                            className={style['details-button']}
                            type="button"
                        >
                            <ArchiveIcon className={style['archive-icon']} />
                            {'Archive'}
                        </button>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className={style['notifications-list-container']}>
            {displayData.map((n, i) => getMemberNotificationRow(n, i))}
                <div className={style['notification-pagination-container']}>
                    <Pagination
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        tableSize={notifications.length}
                        pageSettings={pageSettings}
                    />
                </div>
        </div>
    );
};
