import React from 'react';
import { LinkedPost } from '../../molecules/Post';
import { Col } from 'reactstrap';
import styles from '../../pages/VirtualCare.module.scss';

interface Props {
    contactAgentHandler: () => void;
}

export const VirtualCareProgramTiles = ({contactAgentHandler}: Props) => {
    return (
        <>
            <Col md="6" sm="12"  className={styles['virtual-care-card']}>
                <LinkedPost
                    icon="doctor-v2"
                    header="Schedule an appointment"
                    subHeader="Connect with a Clinician"
                    to="/appointment-request"
                />
            </Col>
            <Col md="6" sm="12" className={styles['virtual-care-card']}>
                <LinkedPost
                    icon="bell"
                    header="Talk to Your Virtual Care Team"
                    subHeader={`Contact an Agent Now`}
                    onClick={contactAgentHandler}
                />
            </Col>
        </>
    );
};
