import React from 'react';
import { ReactComponent as BackIcon } from 'icons/v2/back-arrow.svg';
import styles from "./BackButton.module.scss"


interface Props {
    show: boolean;
    onClick: () => void;
}

export const BackButton = ({ show, onClick }: Props) => {
    return (
        <div className={styles['back-button-container']} onClick={onClick}>
            {show && (
                <span className={styles['back-button-span']}>
                    <BackIcon
                       className={styles['back-button-icon']}
                    />
                    Back
                </span>
            )}
        </div>
    );
};
