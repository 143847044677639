import { MemberAddress, VerifiedAddress, emptyMemberAddress } from 'types/Member';

export const formatPermanentAndMailingAddress = (
    addresses: MemberAddress[]
): { homeAddress: MemberAddress; mailingAddress: MemberAddress; sameAddress: boolean } => {
    const homeAddress = addresses.find((a) => a.address_type == 'Home');
    const mailingAddress = addresses.find((a) => a.address_type == 'Mailing');
    if (!homeAddress || !mailingAddress) {
        return {
            homeAddress: homeAddress
                ? homeAddress
                : { ...emptyMemberAddress, address_type: 'Home' },
            mailingAddress: mailingAddress
                ? mailingAddress
                : { ...emptyMemberAddress, address_type: 'Mailing' },
            sameAddress: false,
        };
    } else {
        const keysA = Object.keys(homeAddress);
        const keysB = Object.keys(mailingAddress);

        if (keysA.length !== keysB.length) {
            return {
                homeAddress: homeAddress
                    ? homeAddress
                    : { ...emptyMemberAddress, address_type: 'Home' },
                mailingAddress: mailingAddress
                    ? mailingAddress
                    : { ...emptyMemberAddress, address_type: 'Mailing' },
                sameAddress: true,
            };
        } else {
            for (let objKey of keysA) {
                if (
                    homeAddress[objKey as keyof MemberAddress]?.trim()?.toLowerCase() !==
                        mailingAddress[objKey as keyof MemberAddress]?.trim()?.toLowerCase() &&
                    objKey !== 'address_type'
                ) {
                    return {
                        homeAddress: homeAddress
                            ? homeAddress
                            : { ...emptyMemberAddress, address_type: 'Home' },
                        mailingAddress: mailingAddress
                            ? mailingAddress
                            : { ...emptyMemberAddress, address_type: 'Mailing' },
                        sameAddress: false,
                    };
                }
            }
            return {
                homeAddress: homeAddress
                    ? homeAddress
                    : { ...emptyMemberAddress, address_type: 'Home' },
                mailingAddress: mailingAddress
                    ? mailingAddress
                    : { ...emptyMemberAddress, address_type: 'Mailing' },
                sameAddress: true,
            };
        }
    }
};

export const getAddressSuggestion = (address: VerifiedAddress, form: MemberAddress) => {
    const suggestions = {
        address1: shouldSugestAddress(address?.address1, form.street_name_1),
        address2: shouldSugestAddress(address?.address2, form.street_name_2),
        state: shouldSugestAddress(address?.state, form.state),
        city: shouldSugestAddress(address?.city, form.city_name),
        zip5: shouldSugestAddress(address?.zip5, form.zip_code),
    };
    for (let objKey in suggestions) {
        if (
            suggestions[
                objKey as keyof VerifiedAddress
            ] != null
        ) {
            return suggestions;
        }
    }

    return null;
};

const shouldSugestAddress = (verified: string, formData: string) => {
    return verified?.trim()?.toLowerCase() == formData?.trim()?.toLowerCase() ? null : verified;
};