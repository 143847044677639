import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { TextInput } from 'components/v2/organisms/TextInput/TextInput';
import { ReactComponent as EditIcon } from 'icons/v2/Edit.svg';
import { ReactComponent as SaveIcon } from 'icons/v2/Save.svg';
import { ReactComponent as CloseIcon } from 'icons/v2/Cancel.svg';
import Styles from 'components/v2/organisms/ContactInformationForm/ContactInfomationForm.module.scss';
import { SelectInput } from '../SelectInput/SelectInput';
import withRelationships from 'hoc/withRelationShips';
import withPolicyData from 'hoc/withPolicyData';
import { MemberEmergencyContact, emptyMemberEmergencyContact } from 'types/Member';
import { isValidPhone, validateEmail } from 'utils/validations';
import { emergencyContactService } from 'services/emergencyContactService';
import { MemberInformationToaster } from '../MemberInformationToaster/MemberInformationToaster';
import { sendCustomEvent } from 'common/utility';
import { AUDITEVENTTYPE } from 'services/trackService';

interface Props {
    memberId: string;
    relationships: { value: string; description: string }[];
    emergencyContact: any;
    setLoading: (show:boolean) => void
}

const defaultValiations = {
    relationshipError: false,
    relationshipErrorMessage: 'Error! - Please select one option for field relationship',
    phoneError: false,
    phoneErrorMessage: 'Error! - Please provide a valid US phone number',
    mailErrorMessage: 'Please enter correct email format',
    mailError: false
};

const MemberEmergencyContactForm = ({ relationships, emergencyContact, memberId,setLoading }: Props) => {
    const [pageValidations, setPageValidations] = useState(defaultValiations);
    const [editing, setEditing] = useState(false);
    const [alert, setAlert] = useState<string | boolean>(false);
    const [relationshipsList, setRelationshipsList] = useState([
        {
            value: '',
            description: 'Select One',
        },
    ]);
    const [formData, setFormData] = useState<MemberEmergencyContact>(emptyMemberEmergencyContact);

    const initializeValues = () => {
        const relation = emergencyContact.relationship || '';
        setFormData({
            emergencyContactRelation:
                relation.charAt(0).toUpperCase() + relation.slice(1).toLowerCase(),
            emergencyContactFullName: emergencyContact.fullName,
            emergencyContactPhoneNumber: emergencyContact.phone,
            emergencyContactEmail: emergencyContact.email,
            emergencyContactPhoneNumberType: null,
        });
        setPageValidations(defaultValiations);
    };

    useEffect(() => {
        if (emergencyContact) {
            initializeValues();
        }
    }, [emergencyContact]);

    useEffect(() => {
        if (relationships) {
            relationships.unshift(relationshipsList[0]);
            setRelationshipsList(relationships);
        }
    }, [relationships]);

    const getIconToDisplay = () => {
        if (editing) {
            return (
                <>
                    <span
                        className={`body-link  ${Styles['section-title-button']}`}
                        onClick={onHandleSubmit}
                    >
                        <SaveIcon className={Styles['section-title-edit-icon']} /> Save
                    </span>
                    <span
                        className={`body-link  ${Styles['section-title-button']}`}
                        onClick={() => {
                            initializeValues();
                            setEditing(false);
                        }}
                    >
                        <CloseIcon
                            className={`${Styles['section-title-edit-icon']} ${Styles['section-title-x-icon']}`}
                        />
                    </span>
                </>
            );
        } else {
            return (
                <span
                    className={`body-link  ${Styles['section-title-button']}`}
                    onClick={() => setEditing(true)}
                >
                    <EditIcon className={Styles['section-title-save-icon']} /> Edit
                </span>
            );
        }
    };

    const onChangeForm = (property: string, value: any) => {
        const tempForm = { ...formData };
        tempForm[property as keyof typeof formData] = value;
        setFormData(tempForm);
    };

    const onHandleSubmit = () => {
        const phoneValidated = isValidPhone(formData.emergencyContactPhoneNumber || '');
        const mailValidated = validateEmail(formData.emergencyContactEmail || '');
        const relationInvalid = formData.emergencyContactRelation?.length == 0;
        if (!phoneValidated || !mailValidated || relationInvalid) {
            setPageValidations({ ...pageValidations, phoneError: !phoneValidated, relationshipError:relationInvalid, mailError: !mailValidated });
            return;
        }else {
            setPageValidations({ ...pageValidations, phoneError: false, relationshipError:false, mailError: false });
        }

        if (
            formData.emergencyContactPhoneNumber != null &&
            formData.emergencyContactPhoneNumber.length > 0
        ) {
            setFormData({ ...formData, emergencyContactPhoneNumberType: 'Phone' });
        }
        setLoading(true);
        sendCustomEvent({
            eventType: AUDITEVENTTYPE.MemberPortalMyInformationUpdated,
        });
        emergencyContactService
            .processEmergencyContact$(
                {
                    ...formData,
                    emergencyContactPhoneNumberType:
                        formData.emergencyContactPhoneNumber != null &&
                        formData.emergencyContactPhoneNumber.length > 0
                            ? 'Phone'
                            : null,
                },
                memberId
            )
            .subscribe({
                next: () => {
                    setAlert('success');
                    setEditing(false);
                    setLoading(false);
                },
                error: () => {
                    setAlert('error');
                    setEditing(false);
                    setLoading(false);
                },
            });
    };

    return (
        <>
            <header className={Styles['section-title-action-button-container']}>
                <h1 className={`section-title-compressed`}>EMERGENCY CONTACT</h1>{' '}
                {getIconToDisplay()}
            </header>
            <Row className="mb-4">
                <Col md="4">
                    <SelectInput
                        error={pageValidations.relationshipError}
                        errorMesssage={pageValidations.relationshipErrorMessage}
                        options={relationshipsList}
                        id="emergencyContactRelation"
                        label="Relation to Member"
                        disabled={!editing}
                        defaultValue={formData.emergencyContactRelation || ''}
                        onChange={(value) => onChangeForm('emergencyContactRelation', value)}
                    />
                </Col>
                <Col md="4">
                    <TextInput
                        id="Emergency_name"
                        label="Emergency Contact Name"
                        disabled={!editing}
                        defaultValue={formData.emergencyContactFullName || ''}
                        onChange={(value) => onChangeForm('emergencyContactFullName', value)}
                    />
                </Col>
                <Col md="4">
                    <TextInput
                        error={pageValidations.phoneError}
                        errorMesssage={pageValidations.phoneErrorMessage}
                        id="emergencyContactPhoneNumber"
                        label="Emergency Contact Phone"
                        disabled={!editing}
                        defaultValue={formData.emergencyContactPhoneNumber || ''}
                        onChange={(value) => onChangeForm('emergencyContactPhoneNumber', value)}
                    />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col md="4">
                    <TextInput
                        error={pageValidations.mailError}
                        errorMesssage={pageValidations.mailErrorMessage}
                        id="emergencyContactEmail"
                        label="Emergency Contact Email"
                        disabled={!editing}
                        defaultValue={formData.emergencyContactEmail || ''}
                        onChange={(value) => onChangeForm('emergencyContactEmail', value)}
                    />
                </Col>
            </Row>

            <MemberInformationToaster
                type={alert}
                onError={() => {
                    setEditing(false);
                    initializeValues();
                    setAlert(false);
                }}
                onSuccess={() => setAlert(false)}
            />
        </>
    );
};

export default withPolicyData(withRelationships(MemberEmergencyContactForm));
