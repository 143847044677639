import React, { useEffect, useState } from "react";
import type { Member } from "types/Member";
import { Link } from "react-router-dom";
import type { MemberPortalCampaignEligibilityConfig, MemberPortalConfig } from "interfaces/MemberPortalConfig";
import { CampaignPopUp } from "components/molecules/CampaignPopUp/CampaignPopUp";

const CAMPAIGN_NAME = 'BloodPressureScreening';

export type BloodPressureScreeningPopUpProps = {
    member: Member;
    memberPortalConfig: MemberPortalConfig;
};

export function BloodPressureScreeningPopUp({member, memberPortalConfig}: BloodPressureScreeningPopUpProps) {
    const [campaignConfig, setCampaignConfig] = useState<MemberPortalCampaignEligibilityConfig | null>(null);

    useEffect(() => {
        const campaignConfig = memberPortalConfig?.campaignEligibilityConfigs?.find(campaign => campaign.name === CAMPAIGN_NAME);
        if(!campaignConfig) {
           return;
        }
        setCampaignConfig(campaignConfig);
    }, [memberPortalConfig]);

    return (
        campaignConfig && <CampaignPopUp
            alertType="danger"
            campaignConfig={campaignConfig}
            member={member}
            message={
                <p>
                If Yes,<strong><Link to={`/message-center/new?initialCategory=${campaignConfig?.messageCenterCategory}`}>Click  here </Link></strong>to let us know.
                </p>
            }
            title="Have you tested your Blood Pressure recently?"
        />
    );
}