import { jsonHeaders$ } from './headers';
import { switchMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

export const AUDITEVENTTYPE = {
    MemberAppointmentCreating: 114,
    MemberPortalAppointmentsScheduled: 115,
    MemberAppointmentCanceling: 116,
    MemberAppointmentCanceled: 117,
    MemberAppointmentRequestCreating: 307,
    MemberPortalAppointmentsRequested: 308,
    MemberAppointmentRequestCanceling: 309,
    MemberAppointmentRequestCanceled: 310,
    MemberLoggedIn: 169,
    MemberLoggedOut: 170,
    MemberPortalCaseCreate: 848,
    MemberPortalWorkflowCreate: 849,
    MemberPortalPcpChange: 850,
    MemberPortalInteractionCreate: 873,
    MemberPortalInteractionUpdate: 874,
    MemberPortalViewVirtualCare: 353,
    MemberPortalViewPatientForm: 354,
    MemberPortalViewAppointmentForm: 355,
    MemberPortalViewUrgentCareAppointmentForm: 356,
    MemberPortalViewAppointmentRequest: 357,
    MemberPortalViewAccountInformation: 358,
    MemberPortalViewBenefits: 359,
    MemberPortalViewClaims: 360,
    MemberPortalViewCommunicationPreferences: 361,
    MemberPortalViewHealthSummary: 362,
    MemberPortalViewMedications: 363,
    MemberPortalViewLabs: 364,
    MemberPortalViewIdCard: 365,
    MemberPortalMessageCenter: 366,
    MemberPortalViewOutOfPocket: 367,
    MemberPortalViewPlanDocuments: 368,
    MemberPortalViewExplanationOfBenefits: 369,
    MemberPortalViewContactUs: 370,
    MemberPortalViewFaq: 371,
    MemberPortalMessageSent: 409,
    MemberPortalMessageDeleted: 410,
    MemberPortalMessageFailedToSend: 411,
    MemberPortalMessageUpdated: 412,
    MemberPortalGetAllMessages: 413,
    MemberPortalGetSingleMessages: 414,
    MemberPortalSubmitHRAForm: 527,
    MemberPortalDownloadHRADocument: 526,
    MemberPortalGetAccessCardBalance: 630,
    MemberPortalGetAccessCardTransactions: 631,
    MemberPortalAccessCardActivation: 632,
    MemberPortalAccessCardCardReplacementRequest: 633,
    MemberPortalSSOSafeRide: 1162,
    MemberPortalViewMyAccessCardDropdown: 1163,
    MemberPortalSSOtoOTCMailOrder: 1415,
    MemberPortalFindAProvider: 1416,
    MemberPortalSSOtoInstacart: 1417,
    MemberPortalSSOtoOptum: 1418,
    MemberPortalSSOtoPrime: 1419,
    MemberPortalSSOtoPayspan: 1420,
    MemberPortalViewMyAccessCardTile: 1421,
    MemberPortalViewCallUs: 1422,
    MemberPortalSSOInComm: 1442,
    MemberPortalFallPreventionPDF: 1473,
    MemberPortalUrinaryWomenPDF: 1474,
    MemberPortalUrinaryMenPDF: 1475,
    MemberPortalLogOut: 1491,
    MemberPortalDownloadCard: 1492,
    MemberPortalProviderDirectoryPDF: 1493,
    MemberPortalEocPDF: 1494,
    MemberPortalIndividualAuthorizationFormPDF: 1495,
    MemberPortalCGTLink: 1496,
    MemberPortalViewDigitalCalendarPDF: 1497,
    MemberPortalAnocPDF: 1503,
    MemberPortalMemberResourceGuidePDF: 1504,
    MemberPortalSSOToTeledoc: 1517,
    MemberPortalViewQuest: 1518,
    MemberPortalViewLabCorp: 1519,
    MemberPortalMyInformationUpdated: 1520,
    MemberPortalJSAAlert: 1599,
};

const trackService = {
    customEvent$: (event) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'post',
                    url: `api/track/customevent`,
                    headers,
                    body: event,
                })
            )
        ),
};

export default trackService;
