import React, { useEffect, useReducer } from 'react';
import StepBreadcrumb from '../molecules/StepBreadcrumb';
import { appointmentSteps } from './Appointment/appointmentWizardConfig';
import ProgressBar from './Patient/Breadcrumb/ProgressBar';
import { omitBy } from 'lodash';

const typeDispatchMaker = (dispatch, type) => {
    return (payload) => {
        dispatch({ type, payload });
    };
};

const goToPageActionDispatcher = (dispatch, page) => (payload) => {
    dispatch({ type: 'GOTO', payload: { ...payload, page } });
};

const CurrentPage = ({ state: { page, ...rest }, dispatch, pageMetaData, anchorTop, props }) => {
    const currPageMetaData = pageMetaData[page];
    useEffect(() => {
        if (anchorTop?.current) {
            anchorTop.current.scrollIntoView();
        }
    }, [page]);
    if (!currPageMetaData) return null;

    const { component: Component, nextAction, prevPage } = currPageMetaData;
    const nextActionDispatcher = typeDispatchMaker(dispatch, nextAction);

    return (
        <Component
            state={rest}
            onSubmit={nextActionDispatcher}
            prevPageAction={goToPageActionDispatcher(dispatch, prevPage)}
            props={props}
        />
    );
};

const Wizard = ({ initialFormState, reducer, pageMetaData, props, anchor, steps }) => {
    const [state, dispatch] = useReducer(reducer, initialFormState);
    const goToPage = (page) => dispatch({ type: 'GOTO', payload: { page } });
    if (!pageMetaData[state?.page]?.showProgressBar) {
        return (
            <div>
                <CurrentPage
                    state={state}
                    dispatch={dispatch}
                    pageMetaData={pageMetaData}
                    props={props}
                    anchorTop={anchor}
                />
            </div>
        );
    }
    return (
        <div>
            {state.isAppointmentForm ? (
                <div>
                    <StepBreadcrumb
                        steps={appointmentSteps}
                        handleStepChange={goToPage}
                        indexAt={state.page}
                        maxIndexAt={state.page}
                    />
                </div>
            ) : (
                <ProgressBar steps={steps} handleStepChange={goToPage} currentStep={state.page} />
            )}
            <div>
                <CurrentPage
                    state={state}
                    dispatch={dispatch}
                    pageMetaData={pageMetaData}
                    props={props}
                    anchorTop={anchor}
                />
            </div>
        </div>
    );
};

export default Wizard;
