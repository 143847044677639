import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormHOC from 'components/Form/FormHOC';
import { HraQuestion } from './HraQuestion';
import { Button } from 'react-bootstrap';
import { PAGES } from './util';

interface Props {
    state: any;
    onSubmit: () => void;
    props: any;
    rest: any;
}
export const HealthStatusStep = ({ state, onSubmit, props, ...rest }: Props) => {
    const { hraForm } = props;
    const formData = hraForm[PAGES.HEALTH_STATUS];
    const getQuestions = () =>
        formData.subSections[0].questions.map((question: any, id: number) => {
            return (
                <div className="mt-5 mb-5" key={`question-container${id}`}>
                    <HraQuestion key={`hraquestion ${id}`} question={question} />
                </div>
            );
        });

    const Form = ({ onSubmit, props }: any) => {
        const { handleSubmit } = useFormContext();
        return (
            <div className="mt-5 mb-5">
                {getQuestions()}
                <div className="text-center mt-5">
                    <Button
                        onClick={() => {
                            props.history.push({
                                pathname: '/health-risk-assessment',
                            });
                        }}
                        variant="secondary"
                        size="lg"
                        className="m-4"
                    >
                        Back
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} variant="primary" size="lg">
                        Next
                    </Button>
                </div>{' '}
            </div>
        );
    };

    return (
        <FormHOC formDefaultValues={state.answers}>
            <Form onSubmit={onSubmit} props={props} {...rest} />
        </FormHOC>
    );
};
