import React, { useState, useEffect, useRef } from 'react';
import Wizard from '../../Form/Wizard';
import {
    initialFormState,
    reducer,
    pageMetaData,
    steps,
} from './JsaWizardConfig';
import withAppointmentData from '../../../hoc/withAppointmentData';
import withMemberData from 'hoc/withMemberData';
import withIdentityData from 'hoc/withIdentityData';
import appointmentService from '../../../services/appointmentService';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const RequestOptionForm = (props: any) => {
    const [subscriptions, setSubscriptions] = useState<any>([]);
    const [initFormState, setInitFormState] = useState<any>(null);
    const titleRef = useRef();
    useEffect(() => {
        getTranslatorPreferredLanguage();

        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewAppointmentRequest })
            .subscribe();

        setSubscriptions([...subscriptions, trackPageVisitSub]);

        return () => {
            subscriptions.forEach((sub: any) => sub.unsubscribe());
        };
    }, []);

    const getTranslatorPreferredLanguage = () => {
        const getLanguageSub = appointmentService.getTranslatorPreferredLanguage$().subscribe({
            next: (response) => {
                const tempFormState = { ...initialFormState };
                tempFormState.translatorPreferredLanguage =
                    response.language === null ? '' : response.language;
                setInitFormState(tempFormState);
            },
            error: () => {
                setInitFormState(initialFormState);
            },
        });
        setSubscriptions([...subscriptions, getLanguageSub]);
    };

    return (
        <div style={{ margin: '5%' }}>
            {initFormState !== null ? (
                <>
                    <h1>Request an Appointment</h1>
                    <Wizard
                        initialFormState={initFormState}
                        reducer={reducer}
                        pageMetaData={pageMetaData}
                        props={props}
                        steps={steps}
                        anchor={titleRef}
                    />
                </>
            ) : (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: '375px' }}
                >
                    <FontAwesomeIcon
                        icon={faCircleNotch}
                        aria-label="Currently Loading Content"
                        size="3x"
                    />
                </div>
            )}
        </div>
    );
};

export default withAppointmentData(withMemberData(withIdentityData(RequestOptionForm)));
