import React, { useEffect, useState, useContext } from 'react';
import { env$ } from 'services/subjects';
import withPolicyData from 'hoc/withPolicyData';
import withMemberCardData from 'hoc/withMemberCardData';
import withMemberData from 'hoc/withMemberData';

import CustomCarousel from 'components/molecules/CustomCarousel/CustomCarousel';
import { HomeTiles } from 'components/v2/organisms/HomeTiles/HomeTiles';
import classNames from './Home.module.scss';
import { MemberPortalConfigContext } from 'contexts/MemberPortalConfigContext';

export type HomeProps = {
    member?: {
        first_name: string;
    };
    memberPortalConfig: any;
};

export const Home = withPolicyData(
    withMemberCardData(
        withMemberData(({ member }: HomeProps) => {
            const [env, setEnv] = useState('');
            const { memberPortalConfig } = useContext(MemberPortalConfigContext);

            useEffect(() => {
                const subscription = env$.subscribe({
                    next: (env) => setEnv(env),
                });
                return subscription.unsubscribe();
            }, []);

            return (
                <>
                    {memberPortalConfig?.homeBanners && (
                        <div id="home-banner">
                            <CustomCarousel
                                data={memberPortalConfig?.homeBanners}
                                effect="fade"
                                time={8000}
                                showControls
                            />
                        </div>
                    )}
                    <main className={classNames.container}>
                        <h2 className="section-title">WELCOME {member?.first_name}</h2>
                        <HomeTiles />
                    </main>
                </>
            );
        })
    )
);
