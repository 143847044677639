import React, { createContext, useContext, useEffect, useState } from 'react';
import * as msal from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider, useAccount, useMsal } from '@azure/msal-react';
import { registerLicense } from '@syncfusion/ej2-base';
import useUnmountSubject from 'hooks/useUnmountSubject';
import B2CConfig from 'interfaces/B2CConfig';
import { first, map, takeUntil } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
    accessToken$,
    env$,
    identity$,
    lastUpdated$,
    refreshToken$,
    tokenExpiration$,
} from 'services/subjects';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import accountService from 'services/accountService';
import { initAppInsights } from 'ApplicationInsights';

export interface AuthContextType {
    b2cConfig: B2CConfig | null;
}

export const AuthContext = createContext<AuthContextType>({
    b2cConfig: null,
});

export const useAuthProvider = () => {
    return useContext<AuthContextType>(AuthContext);
};

const AuthProvider = ({ children }: any) => {
    const unmountSubscriptions$ = useUnmountSubject();

    const [b2cConfig, setB2CConfig] = useState<B2CConfig | null>(null);

    useEffect(() => {
        ajax('api/config/b2c')
            .pipe(
                map((response) => response.response),
                takeUntil(unmountSubscriptions$)
            )
            .subscribe((config: any) => {
                setB2CConfig(config);
            });
    }, []);

    useEffect(() => {
        if (b2cConfig) {
            initB2CConfig();
            initAppInsights(b2cConfig.instrumentationKey);
        }
    }, [b2cConfig]);

    const initB2CConfig = () => {
        if (!b2cConfig) return;

        env$.next(b2cConfig.clientId === '1fc70862-cfc0-4348-a13d-66f0cbbbc21b' ? 'prod' : 'dev');
        registerLicense(b2cConfig.syncfusionLicenseKey);
        lastUpdated$.next(b2cConfig.lastReleaseDate as any);
    };

    if (!b2cConfig) {
        return null;
    }

    const getInstance = () => {
        const { clientId, tenantName, signInPolicy, cacheLocation } = b2cConfig;
        const tenant = `${tenantName}.onmicrosoft.com`;
        const azureAdB2CHostname = `${tenantName}.b2clogin.com`;
        const authority = `https://${azureAdB2CHostname}/tfp/${tenant}/${signInPolicy}`;

        const msalConfig = {
            auth: {
                clientId: clientId,
                authority, // Choose sign-up/sign-in user-flow as your default.
                knownAuthorities: [azureAdB2CHostname], // You must identify your tenant's domain as a known authority.
                redirectUri: window.location.origin,
            },
            cache: {
                cacheLocation, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level: any, message: any, containsPii: any) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case msal.LogLevel.Error:
                                console.error(message);
                                return;
                            case msal.LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case msal.LogLevel.Warning:
                                console.warn(message);
                                return;
                            case msal.LogLevel.Info:
                            default:
                                console.info(message);
                                return;
                        }
                    },
                },
            },
        };
        const instance = new msal.PublicClientApplication(msalConfig);
        instance.addEventCallback((event) => {
            if (event.eventType === msal.EventType.LOGIN_SUCCESS) {
                trackService
                    .customEvent$({
                        eventType: AUDITEVENTTYPE.MemberLoggedIn,
                    })
                    .pipe(first(), takeUntil(unmountSubscriptions$))
                    .subscribe();
                accountService
                    .registerLogin$()
                    .pipe(first(), takeUntil(unmountSubscriptions$))
                    .subscribe();
            }
        });
        return instance;
    };

    return (
        <AuthContext.Provider value={{ b2cConfig }}>
            <MsalProvider instance={getInstance()}>
                <MsalAuthenticationTemplate
                    interactionType={msal.InteractionType.Redirect}
                    authenticationRequest={{ scopes: b2cConfig.scopes }}
                >
                    <MsalWrapped>{children}</MsalWrapped>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </AuthContext.Provider>
    );
};

interface MsalWrappedProps {
    children: any;
}

// cannot use useMsal hook outside of msal provider
// extra layer of wrapper to keep all auth/msal within authprovider
const MsalWrapped = ({ children }: MsalWrappedProps) => {
    const { accounts, instance } = useMsal();
    const account = useAccount(accounts[0]);
    const { b2cConfig } = useAuthProvider();
    const unmountSubscriptions$ = useUnmountSubject();

    useEffect(() => {
        getToken(true);

        refreshToken$.pipe(takeUntil(unmountSubscriptions$)).subscribe({
            next: getToken,
        });
    }, []);

    useEffect(() => {
        if (!account) return;
        publishAuthData();
    }, [account, b2cConfig?.scopes, instance]);

    const publishAuthData = () => {
        if (!account?.idTokenClaims) return;

        const getLastName = () => {
            if (!account?.idTokenClaims) return;
            if (account.idTokenClaims.family_name) {
                return account.idTokenClaims.family_name;
            }
            if (account.idTokenClaims.name && account.idTokenClaims.name.indexOf('_') > -1) {
                return account.idTokenClaims.name.split('_')[1];
            }
        };

        var userIdentity = {
            memberId: account.idTokenClaims.extension_MEMID,
            name: account.idTokenClaims.name,
            firstName: account.idTokenClaims.given_name,
            lastName: getLastName(),
            emails: account.idTokenClaims.emails,
        };

        identity$.next(userIdentity);
    };

    const getToken = (forceRefresh=false) => {
        if (!account || !b2cConfig?.scopes) {
            return;
        }

        instance
            .acquireTokenSilent({ account, scopes: b2cConfig.scopes, forceRefresh })
            .then((response: any) => {
                accessToken$.next(response.accessToken);
                const secondsToExpire = response.idTokenClaims.exp - Date.now() / 1000;
                tokenExpiration$.next(
                    typeof secondsToExpire !== 'number'
                        ? parseInt(secondsToExpire)
                        : secondsToExpire
                );
            })
            .catch(() => {
                // Unable to acquire the token silently. Manual intervention is required
                instance.loginRedirect({
                    redirectUri: window.location.origin,
                    scopes: b2cConfig.scopes,
                });
            });
    };

    return children;
};

export default AuthProvider;
