import { ajax } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';
import { combineLatest } from 'rxjs';
import { memberId$ } from './subjects';

const memberService = {
    getPbpAssignmentHistory$: combineLatest(jsonHeaders$, memberId$).pipe(
        switchMap(([headers, memberId]) =>
            ajax({
                method: 'GET',
                url: `api/member/${memberId}/pbpAssignmentHistory`,
                headers,
            })
        )
    ),

    getHealthPlansForPbp$: (pbpId, year) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/member/healthPlansForPbp/${pbpId}/${year}`,
                    headers,
                })
            )
        ),
    getLanguages$: jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/member/languages`,
                headers,
            })
        )
    ),
    updateContactInformation$: (body) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/member/updateMemberContactInformation`,
                    headers,
                    body,
                })
            )
        ),
    updateMailingAddress$: (body) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/member/updateMemberMailingAddress`,
                    headers,
                    body,
                })
            )
        ),
    validateMemberAddress$: (body) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/member/verifyMemberAddress`,
                    headers,
                    body,
                })
            )
        ),
    getStates$: jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/member/getStates`,
                headers,
            })
        )
    )
};

export default memberService;
