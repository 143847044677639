import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { currencyFormatter, byString } from '../../common/utility';
import { handleEnterKeyToClick } from '../../common/accessibilityHelpers';
import styles from './SimpleTableContent.module.scss';
import { Row } from 'reactstrap';
import { Stack } from '@mui/material';
import debounce from 'lodash.debounce';
import { TableSkeleton } from 'components/organisms/TableSkeleton/TableSkeleton';

const DEBOUNCE_RESIZE_EVENT_BY = 1000;

export type FieldConfig<T> = {
    header: string;
    name: keyof T & string;
    sortable: boolean;
    format?:
    | 'date'
    | 'link'
    | 'list'
    | 'preserve'
    | 'custom'
    | 'currency'
    | 'stringlist'
    | 'header';
    skeleton?: string;
    width?: string;
    template?: (datum: T) => string;
    onClick?: (datum: T) => void;
    align?: 'center' | 'right' | 'left';
    units?: number
};

export type SimpleTableContentProps<T> = {
    sortBy?: keyof T;
    sortDirection?: 'asc' | 'desc';
    onClickSort?: (name: keyof T) => string;
    onClickRow?: (datum: T) => void;
    fields: FieldConfig<T>[];
    data: T[];
    boldRowIf?: (datum: T) => boolean;
    tableSize?: number;
    loading?: boolean;
};

export default function SimpleTableContent<T>({
    boldRowIf,
    sortBy,
    sortDirection,
    onClickSort,
    fields,
    data,
    onClickRow,
    loading,
    tableSize,
}: SimpleTableContentProps<T>) {
    const [hasScroll, setHasScroll] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const renderCell = (datum: T, field: FieldConfig<T>) => {
        switch (field.format) {
            case 'currency':
                return currencyFormatter.format(byString(datum, field.name));
            case 'date':
                var date = datum[field.name] as unknown as string;
                return date
                    ? moment(new Date(date)).format(field.skeleton || 'YYYY-MM-DD HH:mm')
                    : '';
            case 'stringlist':
                var value = datum[field.name] as unknown as string;
                if (!value) {
                    return 'N/A';
                }
                return (
                    <ul>
                        {value.split(/\|\s*\|/).map((singleValue, i) => (
                            <li key={i}>{singleValue}</li>
                        ))}
                    </ul>
                );

            case 'preserve': {
                const value = datum[field.name] as unknown as string;
                if (!value) {
                    return 'N/A';
                }
                return <div style={{ whiteSpace: 'pre-wrap' }}>{value}</div>;
            }
            case 'link': {
                return (
                    <div className="link" onClick={() => onclick}>
                        {byString(datum, field.name)}
                    </div>
                );
            }

            case 'custom': {
                return field?.template ? (field.template(datum) as unknown as string) : '';
            }

            default:
                return byString(datum, field.name);
        }
    };

    const getSortIconProps = (field: FieldConfig<T>) => {
        let sortIconProperties: {
            icon: IconDefinition;
            ariaLabel: string;
        };
        if (sortBy !== field.name || sortDirection === 'asc') {
            sortIconProperties = {
                icon: faSort,
                ariaLabel: `Order ascending or descending by ${field.header}`,
            };
        } else if (sortDirection === 'desc') {
            sortIconProperties = {
                icon: faSortUp,
                ariaLabel: `Order ascending by ${field.header}`,
            };
        } else {
            sortIconProperties = {
                icon: faSortDown,
                ariaLabel: `Order descending by ${field.header}`,
            };
        }
        return sortIconProperties;
    };

    const handleResize = () => {
        setHasScroll(
            (containerRef.current?.scrollWidth || 0) > (containerRef.current?.clientWidth || 0)
        );
    };

    const templateColumns = useMemo(() => {
        return fields.map(field => field.width || '1fr').join(' ');
    }, [fields]);

    useEffect(() => {
        const debouncedHandleResize = debounce(handleResize, DEBOUNCE_RESIZE_EVENT_BY);
        debouncedHandleResize();
        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);

    return (
        <>
            <Row className={styles.row}>
                <section className={styles.section} ref={containerRef}>
                    <div className={`${styles.table}`} style={{ gridTemplateColumns: templateColumns }}>
                        <div className={`header ${styles['table-header']} ${styles['table-row']}`}>
                            {fields.map((field) => {
                                const sortIconProperties = getSortIconProps(field);
                                return (
                                    <div
                                        style={{ minWidth: field.width }}
                                        key={field.name}
                                        onClick={() => onClickSort && onClickSort(field.name)}
                                        className={`${onClickSort ? styles.pointer : ''} ${field.align && styles[`text-${field.align}`]} ${styles['table-header-cell']}`}
                                        aria-label={`Order ascending or descending by ${field.header}`}
                                    >
                                        <span className="bold-text">{field.header}</span>
                                        {field.sortable && (
                                            <FontAwesomeIcon
                                                className="ml-2"
                                                icon={sortIconProperties.icon}
                                                aria-label={sortIconProperties.ariaLabel}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        {data?.length !== 0
                            ? data.map((datum, i) => (
                                <div
                                    key={i}
                                    className={`${styles.tr} ${i % 2 === 0 ? '' : 'gray'} ${onClickRow ? 'ptr' : ''
                                        } ${(boldRowIf && boldRowIf(datum) && styles.bold) || ''
                                        } ${styles['table-row']}`}
                                    onClick={() => onClickRow && onClickRow(datum)}
                                    onKeyUp={handleEnterKeyToClick}
                                >

                                    {fields.map((field) => (
                                        <div style={{ minWidth: field.width }} key={`${i}_${field.name}`} className={`${field.align && styles[`text-${field.align}`]} ${styles['table-cell']}`}>
                                            {field.format === 'header' ? (
                                                <strong>{byString(datum, field.name)}</strong>
                                            ) : (
                                                <>
                                                    <label className="d-md-none mt-4 mb-0">
                                                        <span className="bold-text">
                                                            {field.header}
                                                        </span>
                                                    </label>
                                                    <div
                                                        onClick={
                                                            field.onClick
                                                                ? () => field.onClick!(datum)
                                                                : () => { }
                                                        }
                                                    >
                                                        {renderCell(datum, field)}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))
                            : loading ? 
                            <TableSkeleton rows={tableSize || 0} columns={fields.length} /> : 
                            'No data to display'}
                    </div>
                </section>
            </Row>
            {hasScroll && (
                <Row>
                    <Stack flexDirection="row" justifyContent="space-between" width="100%">
                        <span>Scroll Left</span>
                        <span>Scroll Right</span>
                    </Stack>
                </Row>
            )}
        </>
    );
}
