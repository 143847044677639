import React from 'react';
import PropTypes from 'prop-types';
import PreferenceOptionRadio from './PreferenceOptionRadio';

const PreferenceOptionRadios = (props) =>
    props.options.map((option) => (
        <PreferenceOptionRadio
            key={option.id}
            option={option}
            {...props}
        />
    ));

export default PreferenceOptionRadios;

PreferenceOptionRadios.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array,
    invalidEmail: PropTypes.bool,
    campaignName: PropTypes.string,
    campaignCategory: PropTypes.string,
    cmUMFeatureFlagIsActive: PropTypes.bool,
};
