import React, { useEffect, useState } from 'react';
import { Tile } from 'components/v2/atoms/Tile/Tile';
import { PrescriptionsGrid } from '../PrescriptionsGrid/PrescriptionsGrid';
import Styles from './PrescriptionsGridContainer.module.scss';
import withMedicationData from 'hoc/withMedicationData';
import { messageService } from 'services/messageService';
import { Subscription } from 'rxjs';
import { Alert } from 'components/v2/molecules/Alert/Alert';

const columns = [
    { column: 'drugName', displayText: 'Name' },
    { column: 'quantity', displayText: 'Quantity' },
    { column: 'frequency', displayText: 'Supply' },
    { column: 'pharmacyName', displayText: 'Pharmacy' },
    { column: 'prescriberName', displayText: 'Prescriber' },
    { column: 'dateOfService', displayText: 'Filled', type: 'date' },
    { column: 'isActive', displayText: 'Active', type: 'button' },
];

interface Props {
    medications: any[];

    setLoading: (show: boolean) => void;
}
const PrescriptionsGridContainer = ({ medications = [], setLoading }: Props) => {
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [displayAlert, setDisplayAlert] = useState(false);

    const onAskRefillHandler = (drugName: string) => {
        setLoading(true);
        const sendMessage = messageService
            .createInteraction$(
                'Concierge Team',
                'Prescription Refill',
                'Prescription Refill',
                `Requesting refill for: ${drugName}`,
                []
            )
            .subscribe({
                next: (response: any) => {
                    if (response?.response?.success) {
                        setDisplayAlert(true);
                        setLoading(false);
                    }
                },
            });
        setSubscriptions([...subscriptions, sendMessage]);
    };

    useEffect(() => {
        return () => {
            subscriptions.forEach((sub) => sub.unsubscribe());
        };
    }, []);

    return (
        <>
            <Tile title="MY PRESCRIPTIONS" arial-label="prescriptions">
                <span>
                    Use the scroll bar at the bottom of this table to scroll right and view the rest
                    of your medication/prescription information.
                </span>
                <div style={{ overflowX: 'auto' }}>
                    <PrescriptionsGrid
                        columns={columns}
                        data={medications}
                        onAskRefill={onAskRefillHandler}
                    />
                </div>
                {medications?.length > 3 && (
                    <>
                        <a href="" onClick={() => {}} className={Styles['view-history-button']}>
                            <span>View prescription history</span>
                        </a>
                    </>
                )}
            </Tile>
            <br />
            {displayAlert && (
                <Alert title="Success" variant="success" onClose={() => setDisplayAlert(false)}>
                    Message Sent
                </Alert>
            )}
        </>
    );
};

export default withMedicationData(PrescriptionsGridContainer);
