import React, { useState, useRef, useEffect } from 'react';
import PageHeader from 'components/molecules/PageHeader';
import Wizard from 'components/Form/Wizard';
import { initialFormState, steps, reducer, pageMetaData } from './util';
import Loading from 'components/atoms/Loading';
import WithHealthRiskAssessmentData from 'hoc/withHealthRiskAssessmentData';
import withMemberData from 'hoc/withMemberData';
import { useNavigate } from 'react-router-dom';
import promptService from 'services/promptService';
import { submittedHRA$ } from 'services/subjects';

const HraForm = (props: any) => {
    const titleRef = useRef<HTMLHeadingElement | null>(null);
    const [blockNavigation, setBlockNavigation] = useState(true);
    const [nextLocation, setNextLocation] = useState<any>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const sub = submittedHRA$.subscribe((display) => {
            setBlockNavigation(!display)
        });

        return () => {
            sub.unsubscribe();
        };
    }, []);

    const leavingThePage = (nextLocation: any) => {
        if (!blockNavigation) return true;
        setNextLocation(nextLocation);
        promptService.open({
            title: 'Are you sure you want to leave? The changes you made will not be saved.',
            onActionConfirmed: onLeave,
            onActionDeclined: onCancel,
        });
        return false;
    };

    const onLeave = () => {
        setBlockNavigation(false);
        promptService.close();
    };
    const onCancel = () => {
        promptService.close();
        setNextLocation(null);
    };

    useEffect(() => {
        if (!blockNavigation) {
            if(nextLocation){
                navigate(nextLocation?.pathname)
            }else {
                navigate('/health-risk-assessment');
            }
        }
    }, [blockNavigation]);


    if (props.loading) return <Loading />;

    return (
        <div>
            <PageHeader ref={titleRef}>Health Risk Assessment</PageHeader>
            <Wizard
                initialFormState={initialFormState}
                reducer={reducer}
                pageMetaData={pageMetaData}
                anchor={titleRef}
                steps={steps}
                props={props}
            />
        </div>
    );
};

export default withMemberData(WithHealthRiskAssessmentData(HraForm));
