import { ajax } from 'rxjs/ajax';
import { map, switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';
import { MemberNotification } from 'types/notifications';

export const memberNotificationsService = {
    get$: (memberId:string) => jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/membernotifications/GetNewNotifications/${memberId}`,
                headers,
            }).pipe<MemberNotification[]>(
                map(response => response.response as MemberNotification[])
            )
        )
    ),
    markNotificationAsRead$: (notificationId:string) => jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'PUT',
                url: `api/membernotifications/MarkNotificationRead/${notificationId}`,
                headers,
            }).pipe<boolean>(
                map(response => response.response as boolean)
            )
        )
    ),
    archiveNotification$: (notificationId:string) => jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'PUT',
                url: `api/membernotifications/ArchiveNotification/${notificationId}`,
                headers,
            }).pipe<boolean>(
                map(response => response.response as boolean)
            )
        )
    )
};

