import { ajax } from 'rxjs/ajax';
import { map, take, switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';

const jsaAppointmentService = {
    createJsaRequest$: (requestData) =>
        jsonHeaders$.pipe(
            take(1),
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/jsa`,
                    headers,
                    body: requestData,
                }).pipe(map((response) => response.response.appointmentId))
            )
        ),
    getJsaMemberAlert$: () =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/jsa/GetJSAAlertNotification`,
                    headers,
                })
            )
        ),
};

export default jsaAppointmentService;
