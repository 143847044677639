import { CampaignEligibility } from "./CampaignEligibility";

export const emptyMemberAddress = {
    address_type: '',
    city_name: '',
    state: '',
    street_name_1: '',
    street_name_2: '',
    zip_code: ''
};

export type MemberAddress = {
    address_type: 'Home' | 'Mailing';
    city_name: string;
    state: string;
    street_name_1: string;
    street_name_2: string;
    zip_code: string;
}
export type Member = {
    addresses: MemberAddress[];
    cell_phone: string;
    email: string;
    first_name: string;
    home_phone: string;
    last_name: string;
    member_number: string;
    middle_name: string;
    pbp_name: string;
    campaignEligibilities: CampaignEligibility[];
};


export const emptyMember = {
    addresses: [],
    cell_phone: '',
    email: '',
    first_name: '',
    home_phone: '',
    last_name: '',
    member_number: '',
    middle_name: '',
    pbp_name: '',
    campaignEligibilities: []
};

export type MemberEmergencyContact = {
    emergencyContactRelation: string | null;
    emergencyContactFullName: string | null;
    emergencyContactPhoneNumber: string | null;
    emergencyContactPhoneNumberType: string | null;
    emergencyContactEmail: string | null;
};

export const emptyMemberEmergencyContact = {
    emergencyContactRelation: null,
    emergencyContactFullName: null,
    emergencyContactPhoneNumber: null,
    emergencyContactPhoneNumberType: null,
    emergencyContactEmail: null,
};

export type MemberContactInformationForm = {
    member_phone:  string | null;
    cell_phone:  string | null;
    email:  string | null;
};

export const emptyMemberContactInformationForm = {
    member_phone: null,
    cell_phone: null,
    email: null,
};

export type UpdateContactInformationRequest = {
    MemberId: string;
    HomePhone: string;
    CellPhone: string;
    Email: string;
};

export type VerifiedAddress = {
    address1: string;
    address2: string;
    state: string;
    city: string;
    zip5: string;
}
export interface State {
    code: string;
    name: string;
}