import { Loader } from 'components/v2/atoms/Loader/Loader';
import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { AppointmentCard } from '../AppointmentCard/AppointmentCard';
import { BehaviorSubject, Subject, merge, of } from 'rxjs';
import useObservable from 'hooks/useObservable';
import { VirtualAppointmentCard } from 'interfaces/VirtualAppointmentCard';
import { switchMap } from 'rxjs/operators';

import styles from './UpcomingAppointmentCards.module.scss';
import { virtualCareService } from 'services/virtualCareService';

interface UpcomingAppointmentCardsProps {}

export const UpcomingAppointmentCards = ({}: UpcomingAppointmentCardsProps) => {
    const refreshUpcoming$ = new BehaviorSubject<{}>({});

    const upcomingRequestsAndAppointments$ = useMemo(
        () =>
            refreshUpcoming$.pipe(
                switchMap((_) => virtualCareService.upcomingRequestsAndAppointments$())
            ),
        [refreshUpcoming$]
    );

    const upcomingRequestsAndAppointments = useObservable<VirtualAppointmentCard[] | null>(
        upcomingRequestsAndAppointments$,
        null
    );

    return (
        <>
            <h2 style={{ fontWeight: 'bold' }}>Your Virtual Appointments</h2>
            <section>
                <p className="mt-4">
                    You can join your virtual appointment 15 minutes before the scheduled time. If
                    you would like to reschedule, please cancel the appointment and schedule a new
                    one.
                </p>
                <h3 className={`${styles.labeled}`}>Upcoming Appointments</h3>
                <Row data-testid="rowFutureAppointments">
                    {upcomingRequestsAndAppointments === null ? (
                        <Loader size="sm" />
                    ) : (
                        upcomingRequestsAndAppointments.map((appointment) => (
                            <Col lg="4" key={appointment.id} className={styles['appt-cards']}>
                                <AppointmentCard
                                    key={appointment.id}
                                    appointment={appointment}
                                    cancelAppointment={(_) => refreshUpcoming$.next({})}
                                    onAppointmentCancellationCompleted={console.log}
                                    refreshAppointments={console.log}
                                />
                            </Col>
                        ))
                    )}
                </Row>
            </section>
        </>
    );
};
