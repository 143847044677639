import React from 'react';
import { LinkedPost } from '../../molecules/Post';
import { Col } from 'reactstrap';
import styles from '../../pages/VirtualCare.module.scss';

interface Props {
    pcpName: string;
    contactAgentHandler: () => void;
}
export const VirtualCareAvaHMOTiles = ({ pcpName = '', contactAgentHandler }: Props) => {
    return (
        <>
            <Col md="4" sm="12" className={styles['virtual-care-card']}>
                <LinkedPost
                    icon="doctor-v2"
                    header="See Your PCP"
                    subHeader={`Book an Appointment with ${pcpName}`}
                    to="/appointment-form"
                />
            </Col>
            <Col md="4" sm="12" className={styles['virtual-care-card']}>
                <LinkedPost
                    icon="doctor-v2"
                    header="Get 24/7 Care"
                    subHeader="Request the Next Available Appointment"
                    to="/urgent-care-appointment-form"
                />
            </Col>
            <Col md="4" sm="12" className={styles['virtual-care-card']}>
                <LinkedPost
                    icon="bell"
                    header="Talk to Your Virtual Care Team"
                    subHeader={`Contact an Agent Now`}
                    onClick={contactAgentHandler}
                />
            </Col>
        </>
    );
};
