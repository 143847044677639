import React, { useMemo, useEffect } from 'react';
import { useState } from 'react';
import ssoService from '../services/ssoService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { accessToken$ } from '../services/subjects';
import { useSubscription } from '../hooks/useSubscription';

const SSOHandler = (props) => {
    const [__html, setHtml] = useState();
    console.log(__html);

    const urlParams = new URLSearchParams(window.location.search);

    let partnerName = urlParams.get('partnerName');

    if (partnerName?.includes('otc')) {
        partnerName = 'https://api.otcnetwork.com';
    }

    if (partnerName?.includes('NBSSO2021')) {
        partnerName = 'NBSSO2021OTC';
    }

    const relayUrl = urlParams.get('relayUrl');
    const token = urlParams.get('token');

    if (token) {
        accessToken$.next(token);
    }

    const fetchSso$ = useMemo(() => ssoService.fetchSSOForm$(partnerName, relayUrl), []);

    useSubscription(fetchSso$, (response) => setHtml(response));

    useEffect(() => {
        document.forms.samlform && document.forms.samlform.submit();
    });

    return (
        <>
            <div className="text-center mt-5">
                <FontAwesomeIcon
                    icon={faCircleNotch}
                    aria-label="Currently Loading Content"
                    size="3x"
                />
            </div>
            <div dangerouslySetInnerHTML={{ __html }} />
        </>
    );
};

export default SSOHandler;
