import { ActivitiesStep } from './ActivitiesStep';
import { BenefitsNeedsStep } from './BenefitsNeedsStep';
import { DetailsStep } from './DetailsStep';
import { HealthStatusStep } from './HealthStatusStep';
import { LifestyleStep } from './LifestyleStep';
import { LivingEnvironmentStep } from './LivingEnvironmentStep';

const PAGES = {
    HEALTH_STATUS: 0,
    ACTIVITIES: 1,
    LIVING_ENVIRONMENT: 2,
    BENEFITS_NEEDS: 3,
    LIFESTYLE: 4,
    DETAILS_NEXT_STEPS: 5,

};

const steps = [
    { title: 'H. Status, History & Treatment', page: 0 },
    { title: 'Activities of Daily Living', page: 1 },
    { title: 'Living Environment', page: 2 },
    { title: 'Benefits and Needs', page: 3 },
    { title: 'Lifestyle & Well-being', page: 4 },
    { title: 'Details and Next Steps', page: 5 },
];

// It's not redux, but similar. These are Actions used with useReducer in Wizard.
const ACTIONS = {
    RESET: 'RESET',
    FINISHED_HEALTH_STATUS: 'FINISHED_HEALTH_STATUS',
    FINISHED_ACTIVITIES: 'FINISHED_ACTIVITIES',
    FINISHED_LIVING_ENVIRONMENT: 'FINISHED_LIVING_ENVIRONMENT',
    FINISHED_BENEFITS_NEEDS: 'FINISHED_BENEFITS_NEEDS',
    FINISHED_LIFESTYLE: 'FINISHED_LIFESTYLE',
    FINISHED_DETAILS_NEXT_STEPS: 'FINISHED_DETAILS_NEXT_STEPS',
    GOTO: 'GOTO',
};

// This stores the entire form data. React Hook Forms will pass this as argument in handleSubmit((here)=>{}).
const initialFormState = {
    submit: false,
    page: PAGES.HEALTH_STATUS,
    showProgressBar: false,
    answers: [],
};

// This gives the metadata of page component, and what actions to dispatch for going back / prev page.
const pageMetaData = {
    [PAGES.HEALTH_STATUS]: {
        showProgressBar: true,
        component: HealthStatusStep,
        nextAction: ACTIONS.FINISHED_HEALTH_STATUS,
        prevPage: PAGES.HEALTH_STATUS,
    },
    [PAGES.ACTIVITIES]: {
        showProgressBar: true,
        component: ActivitiesStep,
        nextAction: ACTIONS.FINISHED_ACTIVITIES,
        prevPage: PAGES.HEALTH_STATUS,
    },
    [PAGES.LIVING_ENVIRONMENT]: {
        showProgressBar: true,
        component: LivingEnvironmentStep,
        nextAction: ACTIONS.FINISHED_LIVING_ENVIRONMENT,
        prevPage: PAGES.ACTIVITIES,
    },
    [PAGES.BENEFITS_NEEDS]: {
        showProgressBar: true,
        component: BenefitsNeedsStep,
        nextAction: ACTIONS.FINISHED_BENEFITS_NEEDS,
        prevPage: PAGES.LIVING_ENVIRONMENT,
    },
    [PAGES.LIFESTYLE]: {
        showProgressBar: true,
        component: LifestyleStep,
        nextAction: ACTIONS.FINISHED_LIFESTYLE,
        prevPage: PAGES.BENEFITS_NEEDS,
    },
    [PAGES.DETAILS_NEXT_STEPS]: {
        showProgressBar: true,
        component: DetailsStep,
        nextAction: ACTIONS.FINISHED_DETAILS_NEXT_STEPS,
        prevPage: PAGES.LIFESTYLE,
    },
};

// Reducer, exactly like how redux work. Actions will affect the state of the initialState data.
const reducer = (state:any, action:any) => {
    switch (action.type) {
        case ACTIONS.RESET:
            return initialFormState;
        case ACTIONS.FINISHED_HEALTH_STATUS:
            return {
                ...state,
                answers: { ...state.answers, ...action.payload },
                page: PAGES.ACTIVITIES,
            };
        case ACTIONS.FINISHED_ACTIVITIES:
            return {
                ...state,
                answers: { ...state.answers, ...action.payload },
                page: PAGES.LIVING_ENVIRONMENT,
            };
        case ACTIONS.FINISHED_LIVING_ENVIRONMENT:
            return {
                ...state,
                answers: { ...state.answers, ...action.payload },
                page: PAGES.BENEFITS_NEEDS,
            };
        case ACTIONS.FINISHED_BENEFITS_NEEDS:
            return {
                ...state,
                answers: { ...state.answers, ...action.payload },
                page: PAGES.LIFESTYLE,
            };
        case ACTIONS.FINISHED_LIFESTYLE:
            return {
                ...state,
                answers: { ...state.answers, ...action.payload },
                page: PAGES.DETAILS_NEXT_STEPS,
            };
        case ACTIONS.FINISHED_DETAILS_NEXT_STEPS:
            return {
                ...state,
                answers: { ...state.answers, ...action.payload },
                submit: true,
            };
        case ACTIONS.GOTO:
            return {
                ...state,
                page: action.payload.page,
                answers: { ...state.answers, ...action.payload },
            };
        default:
            return state;
    }
};

export type Question = {
    id: string;
    carryOver: boolean;
    questionNumber: number;
    title: string;
    carryOverExpiration: null;
    followUpQuestions: FollowUpQuestion[];
    options: QuestionOption[];
    required: boolean;
};
export type QuestionOption = {
    optionId: string;
    text: string;
    type: number;
};
export type FollowUpQuestion = {
    item1: string[];
    item2: Question[];
};
export const QuestionTypes = {
    RADIO_BUTTON: 0,
    CHECK_BOX: 1,
    TEXT: 2,
};
export type CarePlanProblem = {
    goals: { id: string; interventions: string[]; optionIds: number[]; text: string }[];
    id: string;
    text: string;
    optionIds: number[];
    questionId: string;
};

export const getCMProblemsSelected = (answers: any, cmProblems: CarePlanProblem[]) => {
    let problems: any[] = [];
    Object.keys(answers).forEach((myKey) => {
        Object.keys(answers[myKey]).forEach((innerKey) => {
            const cmProblem = findCMProblemByOptionId(innerKey, cmProblems);
            problems = [...problems, ...cmProblem];
        });
    });
    return removeDuplicated(problems);
};

const removeDuplicated = (data: any[]) => {
    const uniqueIds = new Set();
    const filteredData: any = [];

    data.forEach((item) => {
        if (!uniqueIds.has(item.id)) {
            uniqueIds.add(item.id);
            filteredData.push(item);
        }
    });

    return filteredData;
};

export const findCMProblemByOptionId = (
    optionId: string,
    carePlanProblems: CarePlanProblem[] | null
) => {
    const result: CarePlanProblem[] = [];
    if (!carePlanProblems) return result;
    const optionNumber = Number(optionId);
    for (const carePlanProblem of carePlanProblems) {
        if (carePlanProblem.optionIds.includes(optionNumber)) {
            result.push({ ...carePlanProblem });
        }
    }
    return result.map((r) => ({ id: r.id, text: r.text }));
};

export { initialFormState, ACTIONS, reducer, pageMetaData, steps, PAGES };
