import { useState, useEffect } from 'react';
import { PageSetting } from 'components/v2/organisms/Pagination/Pagination';

type Props<TValue> = {
    pageSettings: PageSetting;
    data: TValue[];
};

export const usePagination = <TValue>({
    pageSettings,
    data
    
}: Props<TValue>) => {
  
    const [displayData, setDisplayData] = useState<TValue[]>(data);
    const [pageNumber, setPageNumber] = useState(1);

    const getDataToDisplay = (displayData:any) => {
        const { pageSize } = pageSettings;
        var startIndex = pageSize * (pageNumber - 1);

        const toDisplay =  displayData && startIndex < displayData.length
            ? displayData.slice(startIndex, Math.min(startIndex + pageSize, displayData.length))
            : [];
            setDisplayData(toDisplay)
    };


    
    useEffect(() => {
        getDataToDisplay(data)
    }, [data, pageNumber])

    return {
        pageNumber,
        displayData,     
        setPageNumber,
    };
}

export default usePagination;