export const isValidPhone = (phone: string) => {
    if (phone.length === 0) {
        return true;
    }
    if (phone.length < 10 || phone.length > 14) {
        return false;
    }

    return (
        /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(phone) ||
        /^(\([0-9]{3}\)|[0-9]{3})[0-9]{3}[0-9]{4}$/.test(phone)
    );
};


export const validateEmail = (email:string) => {
    if(email?.length == 0){
        return true;
    }
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}