import React from 'react';
import Styles from './PrescriptionsGrid.module.scss';
import moment from 'moment';
import { Button } from 'components/v2/atoms/Button/Button';

type GridColumn = {
    column: string;
    displayText: string;
    type?: any;
};

interface Props {
    columns: GridColumn[];
    data: any[];
    onAskRefill: (drugName:string) => void;
}

const prescriptionsLimit = 3;

export const PrescriptionsGrid = ({ columns, data = [], onAskRefill }: Props) => {
    const displayRowValue = (row: any, column: GridColumn) => {
        if (column.type == 'date') {
            return (
                <p className={Styles['p-column-row-paragraph']}>
                    {moment(new Date(row[column.column])).format('MM/DD/YYYY')}{' '}
                </p>
            );
        }
        if (column.type == 'button') {
            return row[column.column] == 1 ? <Button onClick={() => onAskRefill(row.drugName)} className={Styles['ask-refill-button']}>Ask Refill</Button> : null;
        }
        return <p className={Styles['p-column-row-paragraph']}>{row[column.column]} </p>;
    };
    return (
        <table>
            <thead>
                <tr className={Styles['prescriptions-grid-header']}>
                    {columns.map((column: GridColumn, index: number) => {
                        return (
                            <th key={index}>
                                <span>{column.displayText} </span>{' '}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {data?.slice(0, prescriptionsLimit).map((row, i) => (
                    <tr key={'row ' + i} className={Styles['p-column-row-container']}>
                        {columns.map((col, colIndex) => (
                            <td className={Styles['p-column-row']} key={'td ' + colIndex}>
                                {displayRowValue(row, col)}{' '}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
