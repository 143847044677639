import React from 'react';
import style from './NotificationDot.module.scss';

export const NotificationDot = () => {
    return (
        <div className={style['notification-dot-container']}>
            <div className={style['notification-dot']} />
        </div>
    );
};
