import React from 'react';
import styles from './LoadingOverlay.module.scss';
import Loading from 'components/atoms/Loading';

type LoadingOverlayProps = {
    active: boolean;
    className?: string;
    text?: string;
    spinner?: boolean;
};

function LoadingOverlay({ text, active, spinner }: LoadingOverlayProps) {
    return (
        <article className={`${styles.overlay} ${active ? styles.show : ''}`}>
            {text ? (
                <span className={styles.text}>{text}</span>
            ) : spinner ? (
                <Loading color="white" size="2x" />
            ) : null}
        </article>
    );
}

export default LoadingOverlay;
