import './bootstrap-themed.scss';
import 'draft-js/dist/Draft.css';
import 'react-calendar/dist/Calendar.css';
import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { ariaFixMsalIframe } from './utils/accessibility';
import { BrowserRouter } from 'react-router-dom';
import { PDFViewerProvider } from 'contexts/PdfViewerContext';
import { PDFViewer } from 'components/organisms/PDFViewer';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AuthProvider from 'contexts/AuthProvider';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

const renderContent = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <PDFViewerProvider>
                <BrowserRouter basename={baseUrl ?? undefined}>
                    <App />
                </BrowserRouter>
                <PDFViewer />
            </PDFViewerProvider>
        </LocalizationProvider>
    );
};

const rootElement = createRoot(document.getElementById('root') as HTMLElement);
if (token) {
    rootElement.render(renderContent());
} else {
    rootElement.render(<AuthProvider>{renderContent()}</AuthProvider>);

    ariaFixMsalIframe();
    registerServiceWorker();
}
