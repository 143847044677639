import React, { forwardRef, useCallback, useState, ChangeEvent } from 'react';
import { Controller, Control, ErrorOption } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { handleEnterKey } from 'common/accessibilityHelpers';
import RadioQuestionStyles from 'components/molecules/input/RadioQuestion.module.scss';
import { Option } from '../Models';
import { YesNo } from '../Enums';

interface RadioInputProps {
    name: string;
    question: string | undefined;
    options: Option[];
    onChange: (e: string | number | undefined) => void;
    value: string | undefined;
    isHeading?: boolean;
    colSize: number;
}
export interface RadioInputWithControllerProps {
    control: Control<any>;
    question: string | undefined;
    options: Option[];
    name: string;
    defaultValue: number | undefined;
    error: ErrorOption;
    isHeading?: boolean;
    colSize: number;
}

const RadioQuestion = ({
    name,
    question,
    options,
    value,
    onChange,
    colSize,
    isHeading,
}: RadioInputProps) => {
    return (
        <>
            <p className={isHeading ? 'h2' : 'h5 mt-3 font-weight-bold'}>{question}</p>
            <Row role="radiogroup" aria-labelledby={`label-radiogroup`}>
                {options.map((option: Option, i: number) => (
                    <Col key={`radio-option-${i}`} lg={colSize}>
                        <label
                            key={`radio-option-${i}`}
                            className={RadioQuestionStyles.label}
                            htmlFor={`${name}-${option.label}`}
                            tabIndex={0}
                            onKeyUp={handleEnterKey(() => onChange(option.value))}
                        >
                            <input
                                className={RadioQuestionStyles.radio}
                                data-testid={option.label}
                                type="radio"
                                checked={value === option.value}
                                name={`${name}-${option.label}`}
                                onChange={(_) => onChange(option.value)}
                                id={`${name}-${option.label}`}
                                value={option.value}
                                aria-label={`${name}-${option.label}`}
                            />
                            <span>{option.label}</span>
                        </label>
                    </Col>
                ))}
            </Row>
        </>
    );
};

const RadiosWithController = ({
    control,
    name,
    defaultValue = YesNo.NONE,
    question,
    options,
    colSize,
    isHeading,
}: RadioInputWithControllerProps) => {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value }}) => (
                <RadioQuestion
                    name={name}
                    onChange={onChange}
                    value={value}
                    question={question}
                    options={options}
                    colSize={colSize}
                    isHeading={isHeading}
                />
            )}
        />
    );
};
export default RadiosWithController;
