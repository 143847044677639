import React, { useState, Dispatch, SetStateAction } from 'react';
import Styles from './TextInput.module.scss';
import { FormGroup, Label, Input, Tooltip, FormFeedback } from 'reactstrap';
interface Props {
    label: string;
    disabled?: boolean;
    onChange?: (a: any) => void | Dispatch<SetStateAction<string>>;
    defaultValue: string | number;
    id: string;
    tooltip?: string | null;
    error?: boolean;
    errorMesssage?: string | null;
    suggestion?: string | null;
    inputRef?: any;
}
export const TextInput = ({
    label,
    onChange = () => {},
    disabled = false,
    id,
    defaultValue,
    tooltip = null,
    error = false,
    errorMesssage = null,
    suggestion = null,
    inputRef,
}: Props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const inputWarning = error || suggestion ? true : false;
    return (
        <FormGroup>
            <Label id={`label-for${id}`} for={id} className={Styles['form-input-label']}>
                {label}
            </Label>
            <Input
                innerRef={inputRef}
                invalid={inputWarning}
                aria-labelledby={`label-for${id}`}
                name={id}
                id={id}
                className={`${Styles['form-input-text']}  ${
                    disabled ? Styles['form-input-text-disabled'] : ''
                }`}
                value={defaultValue}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
            />
            {suggestion && (
                <FormFeedback>  Did you mean {`"${suggestion}"`}? </FormFeedback>
            )}
            {errorMesssage && !suggestion && <FormFeedback> {errorMesssage} </FormFeedback>}
            {tooltip && (
                <Tooltip target={id} toggle={toggle} isOpen={tooltipOpen} placement="right">
                    <span className={Styles['form-input-tooltip']}> {tooltip} </span>
                </Tooltip>
            )}
        </FormGroup>
    );
};
