import React, { useState, useEffect } from 'react';
import withAvailabilityData from '../../../hoc/withAvailabilityData';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Modal } from 'react-bootstrap';
import { pick } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import FormHOC from '../../Form/FormHOC';
import DateTime from '../../organisms/DateTime';
import styles from '../ScheduleAppointmentForm.module.scss';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const DATE_FORMAT = 'MM/DD/YYYY';

const FormContent = ({ prevPageAction, onSubmit, state, props, ...rest }) => {
    const { handleSubmit, control, getValues } = useFormContext();
    const [allowUserToContinue, setAllowUserToContinue] = useState(false);
    const [startTimeData, setStartTimeData] = useState(null);
    const [newDateSelected, setNewDateSelected] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (newDateSelected) {
            setAllowUserToContinue(false);
            if (startTimeData) {
                setAllowUserToContinue(true);
            }
        } else {
            if (
                state.startTime !== '' &&
                rest.availableDateTimes &&
                rest.availableDateTimes[
                    moment(state.startTime?.startTime).format(DATE_FORMAT)
                ]?.find((time) => time.startTime === state.startTime.startTime)
            ) {
                setAllowUserToContinue(true);
            }
        }
    }, [newDateSelected, startTimeData, state.startTime, rest.availableDateTimes]);

    const handleChange = (value) => {
        setNewDateSelected(true);
        setStartTimeData(value);
    };

    const onViewStartDateChange = (value) => {
        if (value?.view === 'month') {
            let now = new Date();
            let currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            let startDate =
                value?.activeStartDate < currentDate ? currentDate : value?.activeStartDate;
            rest.fetchAvailabilityFromServer(props.pcp.id, state.reason.id, {
                startDate: startDate,
                endDate: new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1),
            });
        }
    };
    return (
        <>
            <Modal size="sm" centered show={rest.loadingAvailability}>
                <Modal.Body>
                    <div style={{ alignItems: 'center', textAlign: 'center' }}>
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            aria-label="Currently Loading Content"
                            size="3x"
                        />
                    </div>
                </Modal.Body>
            </Modal>
            <Controller
                control={control}
                name="timeSlot"
                defaultValue=""
                render={({ field: { onChange, onBlur, value }}) => (
                    <div data-testid="step2">
                        <p className={styles['question-title']}>
                            Pick a Date and Time for your visit
                        </p>
                        <DateTime
                            time={value}
                            availableDateTimes={rest.availableDateTimes}
                            doctorName={props.pcp?.name}
                            onChange={(e) => {
                                onChange(e);
                                handleChange(e);
                            }}
                            onActiveStartDateChange={(e) => onViewStartDateChange(e)}
                        />
                    </div>
                )}
            />
            <div className="text-center mt-5">
                <Button
                    onClick={() => {
                        navigate('/virtual-care');
                    }}
                    variant="secondary"
                    size="lg"
                    className="m-4"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => prevPageAction(getValues())}
                    variant="secondary"
                    size="lg"
                    className="m-4"
                >
                    Back
                </Button>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="primary"
                    size="lg"
                    disabled={!allowUserToContinue}
                >
                    Next
                </Button>
            </div>
        </>
    );
};

const AppointmentSecondStep = ({ state, onSubmit, props, ...rest }) => {
    const valuePaths = ['startTime'];
    let now = new Date();
    const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    useEffect(() => {
        rest.fetchAvailabilityFromServer &&
            props.pcp &&
            rest.fetchAvailabilityFromServer(props.pcp.id, state.reason.id, {
                startDate: startDate,
                endDate: new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1),
            });
    }, [rest.fetchAvailabilityFromServer, props.pcp, state.reason.id]);

    return (
        <FormHOC formDefaultValues={pick(state, valuePaths)}>
            <FormContent onSubmit={onSubmit} state={state} props={props} {...rest} />
        </FormHOC>
    );
};

export default withAvailabilityData(AppointmentSecondStep);
export { AppointmentSecondStep };
