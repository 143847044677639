import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';

const ConnectForm = ({ children }) => {
    const methods = useFormContext();

    return children({ ...methods });
};

const FormContext = ({ children, ...rest }) => {
    return (
        <FormProvider {...rest}>
            {children}
        </FormProvider>
    );
};


const withFormContext = (Component) => (props) => (
    <ConnectForm>
        {(methods) => <Component {...props} ref={methods.register} {...methods} />}
    </ConnectForm>
);

export {
    ConnectForm as default,
    FormContext,
    withFormContext,
};
