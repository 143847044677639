import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'reactstrap';
import { TextInput } from 'components/v2/organisms/TextInput/TextInput';
import { ReactComponent as EditIcon } from 'icons/v2/Edit.svg';
import { ReactComponent as SaveIcon } from 'icons/v2/Save.svg';
import { ReactComponent as CloseIcon } from 'icons/v2/Cancel.svg';
import Styles from 'components/v2/organisms/ContactInformationForm/ContactInfomationForm.module.scss';
import { CheckBoxInput } from '../CheckBoxInput/CheckBoxInput';
import { MemberAddress, State, VerifiedAddress } from 'types/Member';
import memberService from 'services/memberService';
import { MemberInformationToaster } from '../MemberInformationToaster/MemberInformationToaster';
import { getAddressSuggestion } from 'components/v2/pages/AccountInformation/util';
import { SelectInput } from '../SelectInput/SelectInput';
import { usePlacesWidget } from 'react-google-autocomplete';
import { AddressSugestionsModal } from '../AddressSuggestionsModal/AddressSuggestionsModal';
import { defaultValiations, emptyAddressSuggestions, emptyVerifiedAddress } from './util';
import { sendCustomEvent } from 'common/utility';
import { AUDITEVENTTYPE } from 'services/trackService';

interface Props {
    homeAddress: MemberAddress;
    mailingAddress: MemberAddress;
    sameAddress: boolean;
    memberId: string;
    setLoading: (show: boolean) => void;
}

export const MemberMailingAddressForm = ({
    homeAddress,
    mailingAddress,
    sameAddress,
    memberId,
    setLoading,
}: Props) => {
    const { ref } = usePlacesWidget({
        apiKey: 'AIzaSyCZUXjld2wi_EmRj5H4Y6idtjx5lkvrjn4',
        onPlaceSelected: (place) => {
            //Due autocomplete method returns the city plus state and zip we extract the city only
            const auxIndex = place.formatted_address?.indexOf(',');
            const cityText = place.formatted_address?.substring(0, auxIndex);
            onChangeForm('city_name', cityText);
        },
        options: {
            types: ['locality'],
            componentRestrictions: { country: 'us' },
        },
    });
    const suggestionsRef: any = useRef(emptyAddressSuggestions);
    const [verifiedAddress, setVerifiedAddress] = useState<VerifiedAddress>(emptyVerifiedAddress);
    const [formData, setFormData] = useState<MemberAddress>(mailingAddress);
    const [sameThanHomeAddress, setSameThanHomeAddress] = useState(false);
    const [editing, setEditing] = useState(false);
    const [sugesstionModal, setSugesstionModal] = useState<boolean>(false);
    const [alert, setAlert] = useState<string | boolean>(false);
    const [pageValidations, setPageValidations] = useState(defaultValiations);
    const [addressSuggestions, setAddressSuggestions] = useState<any>(emptyAddressSuggestions);
    const [uspsError, setUspsError] = useState<string | boolean>(false);
    const [statesList, setStatesList] = useState<any[]>([
        {
            value: '',
            description: '',
        },
    ]);

    const onChangeForm = (property: string, value: any) => {
        setFormData((prevState) => {
            return { ...prevState, [property]: value };
        });
    };

    const clearSuggestions = () => {
        suggestionsRef.current = emptyAddressSuggestions;
        setAddressSuggestions(emptyAddressSuggestions);
    };
    const initializeValues = () => {
        setUspsError(false);
        setVerifiedAddress(emptyVerifiedAddress);
        setFormData(mailingAddress);
        setSameThanHomeAddress(sameAddress);
        setPageValidations(defaultValiations);
        clearSuggestions();
    };

    useEffect(() => {
        initializeValues();
    }, [homeAddress, mailingAddress, sameAddress]);

    useEffect(() => {
        setLoading(true);
        memberService.getStates$.subscribe({
            next: (response) => {
                if (response?.response) {
                    const formatedList = response?.response?.map((c: State) => ({
                        value: c.code,
                        description: c.code,
                    }));
                    setStatesList(formatedList);
                    setLoading(false);
                }
            },
        });
    }, []);

    const onHandleSubmit = () => {
        const isValidStreet1 = formData.street_name_1?.length > 0;
        const isValidCity = formData.city_name?.length > 0;
        const isValidState = formData.state?.length > 1;
        const isValidZip = formData.zip_code?.length > 0;

        if (!isValidStreet1 || !isValidCity || !isValidState || !isValidZip) {
            setPageValidations({
                ...pageValidations,
                address1Error: !isValidStreet1,
                cityError: !isValidCity,
                stateError: !isValidState,
                zipError: !isValidZip,
            });
            return;
        }
        setLoading(true);
        memberService
            .validateMemberAddress$({
                FirmName: '',
                Address2: formData.street_name_2,
                Address1: formData.street_name_1,
                City: formData.city_name,
                State: formData.state,
                Zip5: formData.zip_code,
                Zip4: '',
            })
            .subscribe({
                next: (res) => {
                    const { hasAddressDiscrepancy, verifiedAddress, returnText } = res?.response;
                    if (!verifiedAddress && returnText) {
                        setAlert('invalidAddress');
                        setUspsError(returnText)
                        setLoading(false);
                        return;
                    }

                    if (hasAddressDiscrepancy && verifiedAddress) {
                        setVerifiedAddress(verifiedAddress);
                        const suggestions = getAddressSuggestion(verifiedAddress, formData);
                        if (suggestions) {
                            setLoading(false);
                            setSugesstionModal(true);
                            suggestionsRef.current = suggestions;
                        } else {
                            updateMemberMailingAddress(formData);
                        }
                    } else {
                        updateMemberMailingAddress(formData);
                    }
                },
                error: () => {
                    setAlert('error');
                    setEditing(false);
                    setLoading(false);
                },
            });
    };

    const updateMemberMailingAddress = (data: MemberAddress) => {
        setLoading(true);
        sendCustomEvent({
            eventType: AUDITEVENTTYPE.MemberPortalMyInformationUpdated,
        });
        memberService
            .updateMailingAddress$({
                memberId: memberId,
                city: data.city_name,
                addressLine1: data.street_name_1,
                addressLine2: data.street_name_2,
                state: data.state,
                zip: data.zip_code,
            })
            .subscribe({
                next: (res) => {
                    setAlert('success');
                    setEditing(false);
                    setLoading(false);
                    clearSuggestions();
                },
                error: () => {
                    setAlert('error');
                    setEditing(false);
                    setLoading(false);
                    clearSuggestions();
                },
            });
    };

    const getIconToDisplay = () => {
        if (editing) {
            return (
                <>
                    <span
                        className={`body-link  ${Styles['section-title-button']}`}
                        onClick={onHandleSubmit}
                    >
                        <SaveIcon className={Styles['section-title-edit-icon']} /> Save
                    </span>
                    <span
                        className={`body-link  ${Styles['section-title-button']}`}
                        onClick={() => {
                            initializeValues();
                            setEditing(false);
                        }}
                    >
                        <CloseIcon
                            className={`${Styles['section-title-edit-icon']} ${Styles['section-title-x-icon']}`}
                        />
                    </span>
                </>
            );
        } else {
            return (
                <span
                    className={`body-link  ${Styles['section-title-button']}`}
                    onClick={() => setEditing(true)}
                >
                    <EditIcon className={Styles['section-title-save-icon']} /> Edit
                </span>
            );
        }
    };

    const handleOnSameThanHomeAddress = () => {
        if (!sameThanHomeAddress) {
            setSameThanHomeAddress(true);
            setFormData(homeAddress);
        } else {
            setSameThanHomeAddress(false);
        }
    };

    const onDismissSuggestion = () => {
        setSugesstionModal(false);
        setAlert('invalidAddress');
        setAddressSuggestions(suggestionsRef.current);
    };

    const onTakeSuggestion = () => {
        const newForm = {
            ...formData,
            city_name: verifiedAddress.city,
            state: verifiedAddress.state,
            street_name_1: verifiedAddress.address1,
            street_name_2: verifiedAddress.address2,
            zip_code: verifiedAddress.zip5,
        };
        setFormData(newForm);
        setSugesstionModal(false);
        updateMemberMailingAddress(newForm);
    };

    return (
        <>
            <AddressSugestionsModal
                modalVisible={sugesstionModal}
                setModalVisible={setSugesstionModal}
                onDismiss={onDismissSuggestion}
                address={verifiedAddress}
                onTakeSuggestion={onTakeSuggestion}
            />
            <header className={Styles['section-title-action-button-container']}>
                <h1 className={`section-title-compressed`}>MAILING ADDRESS</h1> {getIconToDisplay()}
            </header>

            <Row className="mb-4">
                <Col md="4">
                    <CheckBoxInput
                        id="mailing_same_as"
                        label="Same as permanent address"
                        disabled={!editing}
                        value={sameThanHomeAddress}
                        onChange={handleOnSameThanHomeAddress}
                    />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col md="4">
                    <TextInput
                        id="mailing_address_1"
                        label="Address 1"
                        disabled={!editing}
                        defaultValue={formData.street_name_1}
                        onChange={(value) => onChangeForm('street_name_1', value)}
                        error={pageValidations.address1Error}
                        errorMesssage={pageValidations.errorMessage}
                        suggestion={addressSuggestions.address1}
                    />
                </Col>
                <Col md="4">
                    <TextInput
                        id="mailing_address_2"
                        label="Address 2"
                        disabled={!editing}
                        defaultValue={formData.street_name_2}
                        onChange={(value) => onChangeForm('street_name_2', value)}
                        suggestion={addressSuggestions.address2}
                    />
                </Col>
                <Col md="4">
                    <TextInput
                        id="mailing_City"
                        label="City"
                        disabled={!editing}
                        defaultValue={formData.city_name}
                        onChange={(value) => onChangeForm('city_name', value)}
                        error={pageValidations.cityError}
                        errorMesssage={pageValidations.errorMessage}
                        suggestion={addressSuggestions.city}
                        inputRef={ref}
                    />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col md="4">
                    <SelectInput
                        error={pageValidations.stateError}
                        errorMesssage={pageValidations.errorMessage}
                        options={statesList}
                        id="mailing_State"
                        label="State"
                        disabled={!editing}
                        defaultValue={formData.state}
                        onChange={(value) => onChangeForm('state', value)}
                    />
                </Col>
                <Col md="4">
                    <TextInput
                        id="mailing_Zip Code"
                        label="Zip Code"
                        disabled={!editing}
                        defaultValue={formData.zip_code}
                        onChange={(value) => onChangeForm('zip_code', value)}
                        error={pageValidations.zipError}
                        errorMesssage={pageValidations.errorMessage}
                        suggestion={addressSuggestions.zip5}
                    />
                </Col>
            </Row>
            <MemberInformationToaster
                type={alert}
                onError={() => {
                    setEditing(false);
                    initializeValues();
                    setAlert(false);
                }}
                onSuccess={() => setAlert(false)}
                customMessage={uspsError}
            />
        </>
    );
};
