import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import {
    accessToken$,
    beforeRedirect$,
    refreshToken$,
    tokenExpiration$,
} from '../../services/subjects';
import { Container } from 'react-bootstrap/cjs';
import styles from './SessionExpirationModal.module.scss';
import { useMsal } from '@azure/msal-react';
import { merge, fromEvent } from 'rxjs';
import { switchMap, sampleTime } from 'rxjs/operators';
import { sendCustomEvent } from 'common/utility';
import { AUDITEVENTTYPE } from 'services/trackService';

const SessionExpirationModal = () => {
    /** Time for session expiration. Represented in seconds (default to 1 hour) */
    const [timeToExpire, setTimeToExpire] = useState(3600);
    /** Represents the number of seconds before token expires to show modal to the user */
    const timeToShowModal = 120;

    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [countdown, setCountdown] = useState(null);
    const { instance: msalInstance, accounts } = useMsal();

    useEffect(() => {
        var subscriptions = [];

        subscriptions.push(
            tokenExpiration$.subscribe({
                next: (secondsToExpire) => {
                    if (!secondsToExpire || isNaN(secondsToExpire) || secondsToExpire < 0) {
                        return;
                    }
                    setTimeToExpire(secondsToExpire);
                },
            })
        );

        subscriptions.push(
            tokenExpiration$
                .pipe(
                    switchMap((exp) => {
                        return merge(
                            fromEvent(document, 'click'),
                            fromEvent(document, 'keypress')
                        ).pipe(sampleTime((exp - 3) * 1000));
                    })
                )
                .subscribe({
                    next: () => {
                        refreshAccessToken();
                    },
                })
        );

        return () => {
            subscriptions.map((subscription) => subscription.unsubscribe());
        };
    }, []);

    // Logout when token expires
    useEffect(() => {
        if (modalIsVisible && timeToExpire <= 0) {
            sendCustomEvent({
                eventType: AUDITEVENTTYPE.MemberPortalLogOut,
            });
            msalInstance.logoutRedirect();
            return;
        }
    }, [modalIsVisible, timeToExpire, msalInstance]);

    // Starts token expiration countdown
    useEffect(() => {
        if (timeToExpire > 0 && countdown === null) {
            const timer = setInterval(() => {
                setTimeToExpire((t) => {
                    const newTimeToExpire = t - 1;
                    return newTimeToExpire > 0 ? newTimeToExpire : 0;
                });
            }, 1000);
            setCountdown(timer);
        }
    }, [timeToExpire, countdown]);

    // Toggles modal visibility based on the time left for token expiration
    useEffect(() => {
        if (!modalIsVisible && timeToExpire > 0 && timeToExpire <= timeToShowModal) {
            setModalIsVisible(true);
        } else if (modalIsVisible && timeToExpire > 120) {
            setModalIsVisible(false);
        }
    }, [timeToExpire, modalIsVisible]);

    const refreshAccessToken = () => {
        beforeRedirect$.next();
        refreshToken$.next();
    };

    // Formats seconds or minutes represented as int to string. Example: 1 => 01, 10 => 10
    const formatTime = (string, pad, length) => {
        return (new Array(length + 1).join(pad) + string).slice(-length);
    };

    const seconds = timeToExpire % 60;
    const remainingSeconds = formatTime(seconds, '0', 2);

    return (
        <Container className={styles['token-expiration-modal']}>
            <Modal
                isOpen={modalIsVisible}
                centered={true}
                className={styles['token-expiration-modal-container']}
            >
                <ModalBody className={styles['token-expiration-modal-body']}>
                    <div className={styles['token-expiration-body']}>
                        <div className={styles['token-expiration-body-title']}>
                            Your session is about to expire!
                        </div>
                        <div className={styles['token-expiration-body-timer']}>
                            You will be logged out in{' '}
                            {timeToExpire >= 60
                                ? '2 minutes'
                                : `${remainingSeconds} ${seconds > 1 ? 'seconds' : 'second'}`}
                            . All your progress will be lost and your message will be discarded.
                        </div>
                        <Button
                            color="primary"
                            onClick={refreshAccessToken}
                            block
                            className={styles['token-expiration-modal-btn']}
                        >
                            Stay Logged In
                        </Button>
                        <Button
                            outline
                            color="primary"
                            onClick={(_) => {
                                sendCustomEvent({
                                    eventType: AUDITEVENTTYPE.MemberPortalLogOut,
                                });
                                msalInstance.logoutRedirect();
                            }}
                            block
                            className={styles['token-expiration-modal-btn']}
                        >
                            Log Out Now
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </Container>
    );
};

export default SessionExpirationModal;
