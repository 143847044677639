import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Row, Col } from 'react-bootstrap';
import { pick } from 'lodash';
import FormHOC from '../../Form/FormHOC';
import InputQuestion from '../../molecules/input/InputQuestion';
import CheckboxQuestion from '../../molecules/input/CheckboxQuestion';
import TextAreaQuestion from '../../molecules/input/TextAreaQuestion';
import RadioQuestion from '../../molecules/input/RadioQuestion';
import { enforcePhoneFormat, formatToPhone } from 'common/utility';
import styles from '../ScheduleAppointmentForm.module.scss';
import { identity$ } from 'services/subjects';
import { useNavigate } from 'react-router-dom';

const FormContent = ({ prevPageAction, onSubmit, state, ...rest }) => {
    const { member } = rest.props;

    const { handleSubmit, control, getValues, setValue } = useFormContext();
    const [allowUserToContinue, setAllowUserToContinue] = useState(false);
    const [emailField, setEmailField] = useState(state.patientEmail || '');
    const [phoneField, setPhoneField] = useState(state.patientPhone || '');
    const [wrongFormatFields, setWrongFormatFields] = useState([]);
    const [translatorFieldEnable, setTranslatorFieldEnable] = useState(
        state.translatorRequired == 1
    );
    const [languageField, setLanguageField] = useState(state.translatorPreferredLanguage);
    const [appointmentNotes, setAppointmentNotes] = useState(state.appointmentNotes || '');
    const navigate = useNavigate();
    useEffect(() => {
        if (
            ((emailField.length > 0 && phoneField.length > 0) ||
                (state.patientEmail.length > 0 && state.patientPhone.length > 0)) &&
            wrongFormatFields.length === 0 &&
            (!translatorFieldEnable || (translatorFieldEnable && languageField?.length > 0)) && appointmentNotes?.length > 0
        ) {
            setAllowUserToContinue(true);
        } else {
            setAllowUserToContinue(false);
        }
    }, [
        emailField,
        phoneField,
        languageField,
        state.patientEmail,
        state.patientPhone,
        translatorFieldEnable,
        wrongFormatFields.length,
        appointmentNotes
    ]);

    const handleChange = (value, field) => {
        if (field === 'email') {
            setEmailField(value);
            getWrongFormatFields(value, field);
        }
        if (field === 'phone') {
            setPhoneField(value);
            getWrongFormatFields(value, field);
        }
        if (field === 'translatorRequired') {
            setTranslatorFieldEnable(value == 1);
        }
        if (field === 'translatorPreferredLanguage') {
            setLanguageField(value);
        }
        if (field === 'appointmentNotes') {
            setAppointmentNotes(value);
        }
    };

    const getWrongFormatFields = (value, field) => {
        let tempWrongFormatFields = [...wrongFormatFields];

        switch (field) {
            case 'email':
                if (!value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
                    if (!tempWrongFormatFields.includes('email')) {
                        tempWrongFormatFields.push('email');
                    }
                    setWrongFormatFields(tempWrongFormatFields);
                } else {
                    tempWrongFormatFields = tempWrongFormatFields.filter(
                        (field) => field !== 'email'
                    );
                    setWrongFormatFields(tempWrongFormatFields);
                }
                break;
            case 'phone':
                if (value.replace(/\D/g, '').length !== 10) {
                    if (!tempWrongFormatFields.includes('phone')) {
                        tempWrongFormatFields.push('phone');
                    }
                    setWrongFormatFields(tempWrongFormatFields);
                } else {
                    tempWrongFormatFields = tempWrongFormatFields.filter(
                        (field) => field !== 'phone'
                    );
                    setWrongFormatFields(tempWrongFormatFields);
                }
                break;
            default:
                return;
        }
    };

    useEffect(() => {
        const memberDefaultPhone =
            member?.cell_phone?.length > 0 ? member?.cell_phone : member?.home_phone || null;
        setValue('patientPhone', memberDefaultPhone);
        setPhoneField(memberDefaultPhone);
        const identitySubscription = identity$.subscribe({
            next: (identity) => {
                if (!state.patientEmail && identity.emails?.length) {
                    const firstEmail = identity.emails[0];
                    setValue('patientEmail', firstEmail);
                    setEmailField(firstEmail);
                }
            },
        });

        return () => identitySubscription.unsubscribe();
    }, []);

    return (
        <>
            <Row className="mt-5">
                <Col md={12}>
                    <p className={styles['question-title']}>
                        Enter the email address and phone number we should use for appointment
                        notifications and any questions related to your appointment.
                    </p>
                </Col>
                <Col md={6}>
                    <Controller
                        control={control}
                        name="patientEmail"
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value, ref }}) => (
                            <InputQuestion
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                    handleChange(e, 'email');
                                }}
                                onBlur={onBlur}
                                question="Your Email"
                                option={{ label: 'Email' }}
                                inputRef={ref}
                                showErrorMessage={wrongFormatFields.includes('email')}
                            />
                        )}
                    />
                </Col>
                <Col md={6}>
                    <Controller
                        control={control}
                        name="patientPhone"
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value }}) => (
                            <InputQuestion
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                    handleChange(e, 'phone');
                                }}
                                question="Your Phone Number (Mobile Preferred)"
                                option={{ label: 'Phone Number' }}
                                showErrorMessage={wrongFormatFields.includes('phone')}
                                onKeyDown={enforcePhoneFormat}
                                onKeyUp={formatToPhone}
                            />
                        )}
                    />
                </Col>
            </Row>
            <div className="mt-5 mb-5">
                <Controller
                    control={control}
                    name="notificationPreferences"
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value }}) => (
                        <CheckboxQuestion
                            value={value}
                            onChange={onChange}
                            id="notificationPreference"
                            option={{ label: 'Email', value: 0 }}
                            disabled={true}
                            question={{
                                info: (
                                    <span>
                                        By scheduling this appointment, I agree to receive email and
                                        SMS text<sup>*</sup> appointment notifications. <br />
                                        <br />
                                        <sup>*</sup>SMS text appointment notifications will be
                                        sent only if a mobile phone number is provided. Text message
                                        and data rates may apply. Message frequency will vary based
                                        on your use. Click{' '}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.alignmenthealthplan.com/about-us/terms-of-use/sms-terms-and-conditions"
                                        >
                                            SMS Terms and Conditions
                                        </a>{' '}
                                        for more information.
                                    </span>
                                ),
                            }}
                        />
                    )}
                />
            </div>
            <div className="mt-5 mb-5">
                <Controller
                    control={control}
                    name="translatorRequired"
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value }}) => (
                        <RadioQuestion
                            value={value}
                            onChange={(e) => {
                                onChange(e);
                                handleChange(e, 'translatorRequired');
                            }}
                            onBlur={onBlur}
                            id="translatorRequired"
                            question="Do you need a translator for this visit?"
                            options={[
                                { label: 'Yes', value: 1 },
                                { label: 'No', value: 0 },
                            ]}
                        />
                    )}
                />
            </div>
            <div className="mt-5 mb-5">
                <Controller
                    control={control}
                    name="translatorPreferredLanguage"
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value }}) => (
                        <InputQuestion
                            value={value}
                            onChange={(e) => {
                                onChange(e);
                                handleChange(e, 'translatorPreferredLanguage');
                            }}
                            onBlur={onBlur}
                            question="Enter Translator Language"
                            id="translatorPreferredLanguage"
                            option={{ label: 'translatorPreferredLanguage' }}
                            disabled={!translatorFieldEnable}
                        />
                    )}
                />
            </div>
            <Controller
                rules={{ required: true }}
                control={control}
                name="appointmentNotes"
                defaultValue=""
                render={({ field: { onChange, onBlur, value }}) => (
                    <TextAreaQuestion
                        value={value}
                        onChange={(e) => {
                            onChange(e);
                            handleChange(e, 'appointmentNotes');
                        }}
                        onBlur={onBlur}
                        id="extraInfo"
                        question="Is there anything else you would like to share with the doctor? (Required)"
                        option={{ label: 'extraInfo' }}
                    />
                )}
            />
            <div className="text-center mt-5">
                <Button
                    onClick={() => {
                        navigate('/virtual-care');
                    }}
                    variant="secondary"
                    size="lg"
                    className="m-4"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => prevPageAction(getValues())}
                    variant="secondary"
                    size="lg"
                    className="m-4"
                >
                    Back
                </Button>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="primary"
                    size="lg"
                    disabled={!allowUserToContinue}
                >
                    Next
                </Button>
            </div>
        </>
    );
};

const AppointmentThirdStep = ({ state, onSubmit, ...rest }) => {
    const valuePaths = [
        'patientEmail',
        'patientPhone',
        'notificationPreferences',
        'appointmentNotes',
        'translatorPreferredLanguage',
        'translatorRequired',
    ];

    return (
        <FormHOC formDefaultValues={pick(state, valuePaths)}>
            <FormContent onSubmit={onSubmit} state={state} {...rest} />
        </FormHOC>
    );
};

export default AppointmentThirdStep;
