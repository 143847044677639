import React, { useCallback, useContext, useEffect, useRef } from 'react';
import PageHeader from '../molecules/PageHeader';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { authorizationLetters$ } from 'services/subjects';
import { PDFViewerContext } from 'contexts/PdfViewerContext';
import { MemberAuthorizationLetter } from 'types/MemberAuthorizationLetter';
import ServerResponsiveTable from 'components/organisms/ServerResponsiveTable';
import useServerPagination from 'hooks/useServerPagination';

const INITIAL_TAKE = 10;
const SERVICE_URL = 'api/healthsummary/authorizationletters';

export type ReferralNotificationLettersGridProps = {
    onLoad?: () => void;
};

export default function ReferralNotificationLettersGrid(props: ReferralNotificationLettersGridProps) {
    const { addAndOpenDocument } = useContext(PDFViewerContext);
    const onLoadFired = useRef(false);
    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewHealthSummary })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);
    const { data, loading, page, count, setPage, dataLoadedOnce } = useServerPagination<MemberAuthorizationLetter>({
        getData: (skip, take) => authorizationLetters$(skip, take),
        initialTake: INITIAL_TAKE
    });

    const handleClickDocument = useCallback(
        (authorization: MemberAuthorizationLetter) => {
            addAndOpenDocument({
                serviceUrl: SERVICE_URL,
                documentPath: `${authorization.authNo}/${authorization.letterId}`,
                displayName: authorization.letterId,
            });
        },
        [addAndOpenDocument]
    );

    useEffect(() => {
        if(!dataLoadedOnce || onLoadFired.current || !props.onLoad) {
            return;
        }
        onLoadFired.current = true;
        props.onLoad();
    }, [dataLoadedOnce]);
    return (
        <section id="referrals">
            <PageHeader>
                <span className="bold-text">Referral Notifications</span>
            </PageHeader>
            <p>
            Authorizations check to see whether your plan covers a service or medication before you receive it. Pre-authorization is a request that is submitted on your behalf by your health care provider for a particular procedure, test, treatment, or prescription. You will receive a referral notification when the status of your request has been approved or denied, which can take up to two weeks. This authorization process helps ensure that you are receiving quality, effective, safe, and timely care by which is medically necessary.
            </p>
            <ServerResponsiveTable
                setPageNumber={setPage}
                data={data || []}
                boldRowIf={(data) => !data.memberPortalRead}
                tableSize={count}
                loading={loading}
                fields={[
                    {
                        header: 'Submit Date',
                        name: 'submitDate',
                        sortable: false,
                        format: 'date',
                        skeleton: 'YYYY-MM-DD',
                        width: '120px'
                    },
                    {
                        header: 'Letter',
                        name: 'letterId',
                        sortable: false,
                        format: 'link',
                        onClick: handleClickDocument,
                    },
                    {
                        header: 'Status',
                        name: 'authStatusDescription',
                        sortable: false,
                    },
                    {
                        header: 'Referred From',
                        name: 'referredFrom',
                        sortable: false,
                    },
                    {
                        header: 'Referred To',
                        name: 'referredTo',
                        sortable: false,
                    },
                    {
                        width: '220px',
                        header: 'Facility',
                        name: 'facility',
                        sortable: false,
                    },
                    {
                        header: 'Authorization Type',
                        name: 'authTypeDescription',
                        sortable: false,
                    },
                    {
                        header: 'Authorization Number',
                        name: 'authNo',
                        sortable: false,
                    },
                ]}
                pageNumber={page}
                pageSettings={{
                    pageCount: Math.ceil(count / INITIAL_TAKE),
                    pageSize: INITIAL_TAKE,
                }}
            />
        </section>
    );
}
