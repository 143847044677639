import React from 'react';
import { Row, Col } from 'reactstrap';
import { TextInput } from 'components/v2/organisms/TextInput/TextInput';

interface Props {
    memberId: string;
    firstName: string;
    lastName: string;
    email: string;

}

export const AccountInformationForm = ({memberId, firstName, lastName, email}:Props) => {
    return (
        <>
            <header>
                <h1 className="page-title">MY INFORMATION</h1>
            </header>
            <span>
                Here is your account information. Please make sure everything is up to date. If you
                need to make any changes, click on “Edit” to update your information, or call your
                ACCESS On-Demand Concierge at <strong> 1-833-242-2223 (TTY 711),</strong> 24 hours a
                day, seven days a week.
            </span>
            <br /> <br />
            <Row className="mb-4">
                <Col md="4">
                    <TextInput
                        id="memberId"
                        label="My Member ID#"
                        disabled={true}
                        defaultValue={memberId}
                    />
                </Col>
                <Col md="4">
                    <TextInput
                        id="firstName"
                        label="First Name"
                        disabled={true}
                        defaultValue={firstName}
                    />
                </Col>
                <Col md="4">
                    <TextInput
                        id="lastName"
                        label="Last Name"
                        disabled={true}
                        defaultValue={lastName}
                    />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col md="4">
                    <TextInput
                        id="email"
                        label="Email Address"
                        disabled={true}
                        defaultValue={email}
                        tooltip="This email address was used to register and to log in to your member portal. If a change is required, please send a message to concierge via your message center."
                    />
                </Col>
            </Row>
        </>
    );
};
