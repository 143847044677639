import { Box, Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { Tile } from 'components/v2/atoms/Tile/Tile';
import { Link, useNavigate } from 'react-router-dom';
import { useAdasMember } from 'hooks/useAdasMember';
import { Loader } from 'components/v2/atoms/Loader/Loader';

export const PCPTile = () => {
    const { loadingMember, member } = useAdasMember();
    const navigate = useNavigate();
    const handleClickViewDetails = useCallback(() => {
        navigate('/virtual-care');
    }, []);
    return (
        <Tile
            title="MY CARE TEAM"
            viewDetailsText="View Details"
            onViewDetailsClick={handleClickViewDetails}
            bodySize="fixed"
        >
            {loadingMember ? (
                <Stack height="100%" alignItems="center" justifyContent="center">
                    <Loader size="md" />
                </Stack>
            ) : (
                <>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        spacing={2}
                        height="100%"
                        marginBottom={3}
                    >
                        <h4 className="paragraph-title">
                            <strong>My Providers</strong>
                        </h4>
                        <Box>
                            <strong>My PCP</strong>:{' '}
                            {member?.pcpId ? (
                                <Link to={`/pcp/${member.pcpId}`} className="body-link">
                                    {member?.pcpFullName}
                                </Link>
                            ) : null}
                        </Box>
                        <Box>
                            <strong>PCP ID</strong>: {member?.pcpId}
                        </Box>
                        <Box>
                            <strong>Phone</strong>: {member?.pcpPhone}
                        </Box>
                    </Stack>
                    <Stack spacing={2}>
                        <article>Not happy with your PCP? Any appointment issues?</article>
                        <Link to="/pcp-change" className="body-link">
                            Change your PCP
                        </Link>
                    </Stack>
                </>
            )}
        </Tile>
    );
};
