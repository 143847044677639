import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import styles from './MessageCenterLink.module.css';

const MessageCenterLink = (props) => (
    <Link to="/message-center" className="mr-4 message-center-link" aria-label="View Message(s)" style={{display: 'flex', alignItems: 'center'}}>
        <FontAwesomeIcon icon={faEnvelope} size="2x" aria-label="Navigate to Message(s) Button" />
        {props.unreadMessageCount > 0 && (
            <Badge className={styles.badge}>{props.unreadMessageCount}</Badge>
        )}
    </Link>
);

export default MessageCenterLink;
