import React from 'react';
import PropTypes from 'prop-types';
import Linked from '../../hoc/Linked';
import Icon from '../atoms/Icon';

import styles from './Post.module.scss';
import { isEmpty } from 'lodash';
import { Badge } from 'reactstrap';

const Post = (props) => {
    const isDisabled = props.bubble;
    const disabledStyles = {
        pointerEvents: isDisabled ? 'none' : 'auto',
        filter: isDisabled ? 'grayscale(100%)' : 'none',
        opacity: isDisabled ? 0.5 : 1,
        transition: 'all 0.3s ease'
    };
    return (
        <div className={`${styles.post} text-center card align-items-center padding`} style={props.bubble ? disabledStyles : {}}>
            {props.bubble && (<Badge color="info" style={{color: 'white', alignSelf:'baseline', position:'absolute', top: '10px'}}>Pending</Badge>)}
            
            <Icon icon={props.icon} size="5x" className="text-center" aria-label={props.icon} />
            {!isEmpty(props.header) && (
                <h2 className={`${styles.title} rem-1-125`}>{props.header}</h2>
            )}
            {!isEmpty(props.subHeader) && <label>{props.subHeader}</label>}
        </div>
    );
};

Post.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    bubble: PropTypes.bool,
    header: PropTypes.string,
    subHeader: PropTypes.string,
    to: PropTypes.string,
};

const LinkedPost = Linked(Post);

export { Post, LinkedPost };
