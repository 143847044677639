import { VirtualAppointmentCard } from 'interfaces/VirtualAppointmentCard';
import { ajax } from 'rxjs/ajax';
import { authHeaders$ } from './subjects';
import { switchMap } from 'rxjs/operators';

interface VirtualCareInfo {
    canBookWithPcp: boolean;
    pcpName: string;
}

export const virtualCareService = {
    virtualCareInfo$: () =>
        authHeaders$.pipe(
            switchMap((headers) =>
                ajax.getJSON<VirtualCareInfo>('/api/virtualcare/getvirtualcareinfo', headers)
            )
        ),
    upcomingRequestsAndAppointments$: () =>
        authHeaders$.pipe(
            switchMap((headers) =>
                ajax.getJSON<VirtualAppointmentCard[]>(
                    '/api/virtualcare/getupcomingrequestsandappointments',
                    headers
                )
            )
        ),
};
