import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { faVideo, faPhone, faHome } from '@fortawesome/free-solid-svg-icons';
import { pick } from 'lodash';
import FormHOC from '../../Form/FormHOC';
import RadioQuestion from '../../molecules/input/RadioQuestion';
import { useLocation, useNavigate } from 'react-router-dom';

const visityTypes = {
    video: 'Video Visit',
    phone: 'Phone Visit',
    home: 'Physical Visit',
};

const FormContent = ({ onSubmit, state, props, ...rest }) => {
    const jsaDefaultOption = 'Jump Start Assessment (JSA)';
    const { handleSubmit, control, setValue } = useFormContext();
    const [allowUserToContinue, setAllowUserToContinue] = useState(false);
    const [reasonField, setReasonField] = useState(state.reason);
    const [typeField, setTypeField] = useState(state.type);
    const navigate = useNavigate();
    const location = useLocation();
    const fromJSAAlert = location?.state?.data || false;

    useEffect(() => {
        if (reasonField !== '' && typeField !== '') {
            setAllowUserToContinue(true);
        } else {
            setAllowUserToContinue(false);
        }
    }, [state.reason, state.type, typeField]);

    const handleChange = (value, field) => {
        if (field === 'reason') {
            setReasonField(value);
        }
        if (field === 'type') {
            setTypeField(value);
        }
    };

    const reasons = state.isAppointmentForm ? props.reasons : props.requestReasons;

    useEffect(() => {
        if (!fromJSAAlert) return;
        const defaultJSAOption = reasons.find((r) => r.description == jsaDefaultOption);
        setValue('reason', defaultJSAOption);
        handleChange(defaultJSAOption, 'reason');
    }, [fromJSAAlert]);

    const shouldDisableRadio = (reason) => {
        if (!fromJSAAlert) return false;
        return reason.description == jsaDefaultOption ? false : true;
    };
    const shouldDisableOptionVisit = (visit) => {
        if (visit == visityTypes.phone && reasonField?.description == jsaDefaultOption) {
            return true;
        }
        if (visit == visityTypes.home && reasonField?.description != jsaDefaultOption) {
            return true;
        }
        return false;
    };
    useEffect(() => {
        setValue('type', '');
        handleChange('', 'type');
    }, [reasonField]);

    return (
        <>
            <div className="mt-5 mb-5">
                <Controller
                    control={control}
                    name="reason"
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value }}) => (
                        <RadioQuestion
                            onChange={(e) => {
                                onChange(e);
                                handleChange(e, 'reason');
                            }}
                            onBlur={onBlur}
                            value={value}
                            question="What is the reason for your visit?"
                            options={reasons.map((reason) => ({
                                label: reason.description,
                                value: reason,
                                disabled: shouldDisableRadio(reason),
                            }))}
                        />
                    )}
                />
            </div>
            <div className="mt-5 mb-5">
                <Controller
                    control={control}
                    name="type"
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value }}) => (
                        <RadioQuestion
                            onChange={(e) => {
                                onChange(e);
                                handleChange(e, 'type');
                            }}
                            onBlur={onBlur}
                            value={value}
                            question="How would you like to speak to a doctor?"
                            options={[
                                {
                                    label: visityTypes.video,
                                    value: 0,
                                    icon: faVideo,
                                    disabled: false,
                                },
                                {
                                    label: visityTypes.phone,
                                    value: 1,
                                    icon: faPhone,
                                    disabled: shouldDisableOptionVisit(visityTypes.phone),
                                }
                            ]}
                        />
                    )}
                />
            </div>
            <div className="text-center mt-5">
                <Button
                    onClick={() => {
                        navigate('/virtual-care');
                    }}
                    variant="secondary"
                    size="lg"
                    className="m-4"
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="primary"
                    size="lg"
                    disabled={!allowUserToContinue}
                >
                    Next
                </Button>
            </div>
        </>
    );
};

const AppointmentFirstStep = ({ state, onSubmit, props, ...rest }) => {
    const valuePaths = ['reason', 'type'];
    return (
        <FormHOC formDefaultValues={pick(state, valuePaths)}>
            <FormContent onSubmit={onSubmit} state={state} props={props} {...rest} />
        </FormHOC>
    );
};

export default AppointmentFirstStep;
