import React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dropzone from 'react-dropzone';
import { AiOutlineClose } from 'react-icons/ai';
import { ListGroup } from 'react-bootstrap';
import { sanitizeString } from 'common/utility';

export default class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.dropzoneRef = React.createRef();
        this.state = {
            dropzoneNoClick: false,
            selectedFiles: this.props.initialFiles || [],
        };
    }

    onDrop = (acceptedFiles) => {
        let prevFileMap = this.state.selectedFiles.reduce(
            (map, file) => (map = { ...map, [file.path || file.name]: file }),
            {}
        );
        let acceptedFileMap = acceptedFiles.reduce(
            (map, file) => (map = { ...map, [file.path || file.name]: file }),
            {}
        );
        this.setState(
            {
                selectedFiles: Object.values({ ...prevFileMap, ...acceptedFileMap }),
            },
            () => this.handleChangeFiles(this.state.selectedFiles)
        );
    };

    handleChangeFiles(files) {
        const { onChange } = this.props;
        onChange && onChange(files);
    }

    onClick = () => {
        this.dropzoneRef.open();
    };

    removeFile = (path) => {
        let filteredFiles = this.state.selectedFiles.filter((file) => file.path !== path);
        this.setState(
            {
                selectedFiles: filteredFiles,
            },
            () => this.handleChangeFiles(filteredFiles)
        );
    };

    convertSize = (size) => {
        if (size < 1024) {
            return size + ' Bytes';
        } else if (size >= 1024) {
            return parseInt(size / 1024) + ' KBs';
        } else if (size >= 1024 * 1024) {
            return parseInt(size / (1024 * 1024)) + 'MBs';
        } else {
            return 'Size Over Limit';
        }
    };

    render() {
        return (
            <div>
                <Dropzone
                    ref={(dropzone) => (this.dropzoneRef = dropzone)}
                    noClick={this.state.dropzoneNoClick}
                    noKeyboard
                    maxSize={3000000}
                    multiple
                    accept={['.pdf', '.jpg', '.jpeg', '.png']}
                    onDrop={this.onDrop}
                >
                    {({ getRootProps, getInputProps }) => {
                        return (
                            <div
                                className="file-uploader-container"
                                style={{ backgroundColor: '#fbfbfb' }}
                            >
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <CloudUploadIcon color="primary" sx={{ fontSize: '3rem' }} />
                                    <p className="mb-0" style={{ fontWeight: 'bold' }}>
                                        Or drag and drop the application here or{' '}
                                        <span
                                            onClick={() => {
                                                if (this.state.dropzoneNoClick) {
                                                    this.onClick();
                                                }
                                            }}
                                            style={{
                                                color: '#0376a8',
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            browse
                                        </span>
                                    </p>
                                    <p className="mb-0">
                                        Accepted file formats: pdf, png, jpg, jpeg with max size of
                                        3MB
                                    </p>
                                </div>
                            </div>
                        );
                    }}
                </Dropzone>
                <ListGroup>
                    {this.state.selectedFiles.map((file, i) => (
                        <ListGroup.Item key={`file-uploader-file-${i}`} className="file-list-item">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>{sanitizeString(file.name)}</td>
                                        <td>{this.convertSize(file.size)}</td>
                                        <td>
                                            <AiOutlineClose
                                                style={{ textAlign: 'center' }}
                                                className="close-button"
                                                onClick={() => this.removeFile(file.path)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
        );
    }
}

FileUploader.defaultProps = {
    initialFiles: [],
};
