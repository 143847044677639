import React, { useEffect, useState } from 'react';
import { Container } from 'components/v2/atoms/Container/Container';
import { AccountInformationForm } from 'components/v2/organisms/AccountInformationForm/AccountInformationForm';
import withMemberData from 'hoc/withMemberData';
import { Member, emptyMember } from 'types/Member';
import { ContactInformationForm } from 'components/v2/organisms/ContactInformationForm/ContactInformationForm';
import MemberAddressForm from 'components/v2/organisms/MemberAddressForm/MemberAddressForm';
import { MemberMailingAddressForm } from 'components/v2/organisms/MemberMailingAddress/MemberMailingAddressForm';
import MemberEmergencyContactForm from 'components/v2/organisms/MemberEmergencyContactForm/MemberEmergencyContactForm';
import { formatPermanentAndMailingAddress } from './util';
import { FullScreenLoader } from 'components/v2/atoms/FullScreenLoader/FullScreenLoader';
import { sendCustomEvent } from 'common/utility';
import { AUDITEVENTTYPE } from 'services/trackService';

interface Props {
    member: Member;
}

const AccountInformation = ({ member = emptyMember }: Props) => {
    const { homeAddress, mailingAddress, sameAddress } = formatPermanentAndMailingAddress(
        member.addresses
    );
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        sendCustomEvent({
            eventType: AUDITEVENTTYPE.MemberPortalViewAccountInformation,
        });
    }, []);

    return (
        <Container>
            {loading && (
                <FullScreenLoader/>
            )}

            <AccountInformationForm
                email={member?.email}
                firstName={member?.first_name}
                lastName={member?.last_name}
                memberId={member?.member_number}
            />
            <ContactInformationForm
                member_phone={member?.home_phone}
                email={member?.email}
                cell_phone={member.cell_phone}
                memberId={member?.member_number}
                setLoading={setLoading}
            />
            <MemberAddressForm homeAddress={homeAddress} />
            <MemberMailingAddressForm
                homeAddress={homeAddress}
                mailingAddress={mailingAddress}
                sameAddress={sameAddress}
                memberId={member?.member_number}
                setLoading={setLoading}
            />
            <MemberEmergencyContactForm memberId={member.member_number} setLoading={setLoading} />
        </Container>
    );
};

export default withMemberData(AccountInformation);
