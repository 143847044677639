import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import PageHeader from '../molecules/PageHeader';
import { LinkedPost } from '../molecules/Post';
import withPolicyData from '../../hoc/withPolicyData';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { sendCustomEvent } from 'common/utility';
import withAdasMemberData from 'hoc/withAdasMemberData';
import { Stack } from '@mui/material';
import CareManagementLettersGrid from './CareManagementLettersGrid';
import ReferralNotificationLettersGrid from './ReferralNotificationLettersGrid';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useLocation } from 'react-router-dom';

const CM_UM_NOTIFICATIONS_FLAG = 'cm-um-notifications';

const HealthSummary = (props) => {
    const { hash } = useLocation();
    const { member } = props;
    const { isFeatureFlagLoading, enabled } = useFeatureFlag(CM_UM_NOTIFICATIONS_FLAG);
    const [loadMap, setLoadMap] = useState({
        referrals: false,
        careManagement: false,
    });
    function handleLoad(topic) {
        setLoadMap((latest) => ({ ...latest, [topic]: true }));
    }
    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewHealthSummary })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!member?.isSNP) {
            setLoadMap((latest) => ({ ...latest, careManagement: true }));
        }
    }, [member?.isSNP]);

    useLayoutEffect(() => {
        if (hash && Object.values(loadMap).every(Boolean)) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash, loadMap]);

    const posts = [
        {
            icon: 'doctor',
            header: `Get Virtual Care`,
            subHeader: `Talk to your doctor by phone or video`,
            to: '/virtual-care',
        },
        {
            icon: 'microscope',
            header: 'View Quest Results',
            subHeader: 'Make an appointment or see lab results',
            href: 'https://myquest.questdiagnostics.com/web/home',
            target: '_blank',
            onClick: () => {
                sendCustomEvent({ eventType: AUDITEVENTTYPE.MemberPortalViewQuest });
            },
        },
        {
            icon: 'test-tube',
            header: `View LabCorp Results`,
            subHeader: `Make an appointment or see lab results`,
            href: 'https://patient.labcorp.com',
            target: '_blank',
            onClick: () => {
                sendCustomEvent({ eventType: AUDITEVENTTYPE.MemberPortalViewLabCorp });
            },
        },
    ];

    return (
        <div>
            <Container>
                <PageHeader>
                    <span className="bold-text">My Health Summary</span>
                </PageHeader>
                <p>
                    You can share this overview of your health with your doctor. This information is
                    also handy when you go to a hospital or other treatment center. Just click on
                    the topic title below to see more information or complete your health
                    assessment.
                </p>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch' }}>
                    {posts.map((post, i) => (
                        <Col key={i} md="4">
                            <LinkedPost {...post} />
                        </Col>
                    ))}
                </Row>
                {!isFeatureFlagLoading && enabled && (
                    <>
                        <Stack padding={2}>
                            <CareManagementLettersGrid
                                member={member}
                                onLoad={handleLoad.bind({}, 'careManagement')}
                            />
                        </Stack>
                        <Stack padding={2}>
                            <ReferralNotificationLettersGrid
                                onLoad={handleLoad.bind({}, 'referrals')}
                            />
                        </Stack>
                    </>
                )}
            </Container>
        </div>
    );
};

export default withAdasMemberData(withPolicyData(HealthSummary));
