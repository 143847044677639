import React from 'react';
import { Col } from 'reactstrap';
import styles from 'components/molecules/input/CheckboxQuestion.module.scss';
import { QuestionOption } from '../util';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
    value?: string;
    disabled?: boolean;
    question: QuestionOption;
    onChange: (value: any) => void;
    checked: boolean;
}

export const HraCheckBox = ({ value, disabled, question, onChange, checked }: Props) => {

    return (
        <Col key={'value'} style={{ padding: 0 }}>
            <input
                className={styles.checkbox}
                type="checkbox"
                checked={checked}
                name={question.text}
                onChange={() => {
                    onChange({ [question.optionId]: question.text });
                }}
                id={question.text}
                data-testid={question.text}
                value={value}
                disabled={disabled}
                aria-labelledby={`label-combocheckbox-${question.optionId}-${question.text}`}
            />
            <label
                className={styles.label}
                htmlFor={question.text}
                id={`label-combocheckbox-${question.optionId}-${question.text}`}
            >
                {question.text}
            </label>
        </Col>
    );
};
