import React from 'react';
import { Modal } from 'react-bootstrap';
import Styles from './AddressSuggestionsModal.module.scss';
import { Alert } from 'components/v2/molecules/Alert/Alert';
import { Button } from 'components/v2/atoms/Button/Button';
import { VerifiedAddress } from 'types/Member';

interface Props {
    modalVisible: boolean;
    setModalVisible: (show: boolean) => void;
    onDismiss: () => void;
    address: VerifiedAddress;
    onTakeSuggestion: () => void;
}
export const AddressSugestionsModal = ({ modalVisible, setModalVisible, onDismiss, address,onTakeSuggestion }: Props) => {
    return (
        <Modal
            size="lg"
            show={modalVisible}
            backdrop="static"
            onHide={() => {
                setModalVisible(false);
            }}
        >
            <Modal.Header className={Styles['suggestion-modal']}></Modal.Header>
            <Modal.Body>
                <Alert title="ENTER A VALID MAILING ADDRESS" variant="warning">
                    The address you entered may be incorrect or incomplete. Please review our
                    suggestion below, or edit entered address.
                </Alert>
                <div className={Styles['suggestion-modal-address']}>
                    <h4>Suggested Address</h4>
                   <p>{address.address1} </p>
                    {address.address2?.length > 0 && <p>{address.address2}</p>}
                    <p>{`${address.city}, ${address.state} ${address.zip5}`}</p>
                </div>
                <div>
                    <div className={Styles['suggestion-buttons-container']}>
                        <Button
                            variant="secondary"
                            aria-label="Submit search"
                            onClick={onDismiss}
                            className={Styles['suggestion-button']}
                        >
                            Dismiss
                        </Button>
                        <Button variant="main" aria-label="Submit search" onClick={onTakeSuggestion}>
                            Use Suggested Address
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
