import React from 'react';
import { Skeleton } from '@mui/material';
import styles from './TableSkeleton.module.scss';

export type TableSkeletonProps = {
    rows: number;
    columns: number;
};
export function TableSkeleton({rows, columns}: TableSkeletonProps) {
  return (
    <>
      {Array.from({ length: rows }).map((_, i) => (
        <div key={i} className={`${styles.tr} ${i % 2 === 0 ? '' : 'gray'} ${styles['table-row']}`}>
          {Array.from({ length: columns}).map((_, j) => (
              <div key={j} className={`table-cell ${styles['table-cell']}`}>
                  <Skeleton width="100%" height="100%" />
              </div>
          ))}
        </div>
      ))}
    </>);
}