import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from 'components/organisms/NavMenu';
import { Header } from 'components/organisms/Header';
import withInteractionData from 'hoc/withInteractionData';
import withFeatureFlags from 'hoc/withFeatureFlags';
import JsaNotification from 'components/organisms/JsaNotification/JsaNotification';
import { useLocation } from 'react-router-dom';
import { ColorectalCancerScreeningPopUp } from 'components/molecules/ColorectalCancerScreeningPopUp/ColorectalCancerScreeningPopUp';
import { Box } from '@mui/material';
import withMemberPortalConfigData from 'hoc/withMemberPortalConfigData';
import { MammogramScreeningPopUp } from 'components/molecules/MammogramScreeningPopUp/MammogramScreeningPopUp';
import { BloodPressureScreeningPopUp } from 'components/molecules/BloodPressureScreeningPopUp/BloodPressureScreeningNotification';
import withMemberData from 'hoc/withMemberData';

const SHOW_HEDIS_CAMPAIGNS_POP_UPS_FEATURE_FLAG = 'show-hedis-campaigns-popups';
const jsaRoute = '/jsa-appointment-request';
const messageCenterRoute = '/message-center';

export const Layout = (props) => {
    const [displayJsa, setDisplayJsa] = useState(true);
    const [displayHedisPopUps, setDisplayHedisPopUps] = useState(false);

    const location = useLocation();
    
    useEffect(() => {
        if (location?.pathname == jsaRoute) {
            setDisplayJsa(false);
        } else {
            setDisplayJsa(true);
        }
        if(!location.pathname?.toLowerCase().startsWith(messageCenterRoute)) {
            setDisplayHedisPopUps(true);
        } else {
            setDisplayHedisPopUps(false);
        }
        
    }, [location?.pathname]);

    return (
        <div className="main-content">
            <Header {...props} />
            <NavMenu {...props} />
            <Container id="main-container" role="main" aria-label="main content area">
                <Box rowGap={2} paddingTop={4}>
                    {props.featureFlags?.[SHOW_HEDIS_CAMPAIGNS_POP_UPS_FEATURE_FLAG] && displayHedisPopUps &&
                    <>
                        {props.member && props.memberPortalConfig && <ColorectalCancerScreeningPopUp member={props.member} memberPortalConfig={props.memberPortalConfig} />}
                        {props.member && props.memberPortalConfig && <MammogramScreeningPopUp member={props.member} memberPortalConfig={props.memberPortalConfig} />}
                        {props.member && props.memberPortalConfig && <BloodPressureScreeningPopUp member={props.member} memberPortalConfig={props.memberPortalConfig} />}
                    </>}
                    {displayJsa && <JsaNotification member={props.member} />}
                </Box>
                {props.children}
            </Container>
        </div>
    );
};

export default withFeatureFlags(withInteractionData(withMemberData(withMemberPortalConfigData(Layout))));
