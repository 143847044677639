import React from 'react';
import { LinkedPost, Post } from '../../molecules/Post';
import { Col } from 'reactstrap';
import styles from '../../pages/VirtualCare.module.scss';

interface Props {
    pendingJSA: boolean;
    contactAgentHandler: () => void;
}

export const VirtualCareJsaTiles = ({ pendingJSA, contactAgentHandler }: Props) => {
    return (
        <>
            <Col md="4" sm="12" className={styles['virtual-care-card']}>
                <LinkedPost
                    icon="doctor-v2"
                    header="Schedule an appointment"
                    subHeader="Connect with a Clinician"
                    to="/appointment-request"
                />
            </Col>
            <Col md="4" sm="12" className={styles['virtual-care-card']}>
                {pendingJSA ? (
                    <Post
                        bubble={true}
                        icon="doctor-v2"
                        header="Schedule An Annual Checkup"
                        subHeader="Request an Appointment"
                        to="/jsa-appointment-request"
                    />
                ) : (
                    <LinkedPost
                        icon="doctor-v2"
                        header="Schedule An Annual Checkup"
                        subHeader="Request an Appointment"
                        to="/jsa-appointment-request"
                    />
                )}
            </Col>
            <Col md="4" sm="12" className={styles['virtual-care-card']}>
                <LinkedPost
                    icon="bell"
                    header="Talk to Your Virtual Care Team"
                    subHeader={`Contact an Agent Now`}
                    onClick={contactAgentHandler}
                />
            </Col>
        </>
    );
};
