import React, { PropsWithChildren, memo } from 'react';
import { ReactComponent as BlueSwoosh } from 'icons/v2/blue-swoosh.svg';
import classNames from './SectionTitle.module.scss';

export type SectionTitleProps = PropsWithChildren<{
    as?: 'h1' | 'h2' | 'h3';
    blueSwoosh?: boolean
}> &
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;

const SectionTitle = ({
    children,
    as = 'h2',
    blueSwoosh = true,
    ...restOfProps
}: SectionTitleProps) => (
    <>
        {React.createElement(
            as,
            {
                ...restOfProps,
                className: 'section-title'.concat(restOfProps.className || ''),
            },
            <span className={`section-title ${classNames.title}`}>
                {children}
                {blueSwoosh && <BlueSwoosh className={classNames.swoosh} />}
            </span>
        )}
    </>
);

SectionTitle.defaultProps = {
    as: 'h2',
};

const MemoSectionTitle = memo(SectionTitle);
export { MemoSectionTitle as SectionTitle };
