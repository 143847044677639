import { Alert } from 'components/v2/molecules/Alert/Alert';
import React from 'react';

interface Props {
    type: string | boolean;
    onSuccess: () => void;
    onError: () => void;
    customMessage?: string | boolean;
}

export const MemberInformationToaster = ({ type, onSuccess, onError, customMessage = false }: Props) => {
    if (!type) {
        return <> </>;
    }
    if (type == 'invalidAddress') {
        const text = customMessage
            ? customMessage
            : 'The address you entered may be incorrect or incomplete. Please review the entered mailing address and try again.';
        return (
            <Alert title="YOUR CHANGES HAVE NOT BEEN SAVED" variant="error" onClose={onError}>
               {text} 
            </Alert>
        );
    }
    if (type == 'success') {
        return (
            <Alert title="CHANGES SAVED" variant="success" onClose={onSuccess}>
                Your changes have been saved. Please allow 1-2 business days for the changes to take
                effect.
            </Alert>
        );
    } else {
        return (
            <Alert title="Error" variant="error" onClose={onError}>
                Error! - There was an issue updating you contact, please reach out an agent or try
                again.
            </Alert>
        );
    }
};
