import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    far,
    faFolderOpen,
    faFilePdf,
    faEnvelope,
    faCreditCard,
    faTrashAlt,
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
    faSearch,
    faWallet,
    faStethoscope,
    faPrescriptionBottleAlt,
    faConciergeBell,
    faUserCircle,
    faChevronDown,
    faPlusCircle,
    faLongArrowAltLeft,
    faReply,
    faDownload,
    faCircleNotch,
    faArrowsAltV,
    faLongArrowAltUp,
    faLongArrowAltDown,
    faSort,
    faSortUp,
    faSortDown,
} from '@fortawesome/free-solid-svg-icons';

import './custom.scss';

import Layout from './components/templates/Layout';
import HomeV1 from './components/pages/Home';
import { Home as HomeV2 } from './components/v2/pages/Home/Home';
import { CssTypography as CssTypographyV2 } from './components/v2/styles/CssTypography';
import BenefitsV1 from './components/pages/Benefits';
import LegacyMessageCenter from './components/pages/LegacyMessageCenter';
import MessageCenter from './components/pages/MessageCenter';
import IdCardV1 from './components/pages/IdCard';
import { IdCard as IdCardV2 } from 'components/v2/pages/IdCard/IdCard';
import PlanDocumentsV1 from './components/pages/PlanDocuments';
import OutOfPocket from './components/pages/OutOfPocket';
import Claims from './components/pages/Claims';
import HealthSummaryV1 from './components/pages/HealthSummary';
import Medications from './components/pages/Medications';
import Labs from './components/pages/Labs';
import AccountInformationV1 from './components/pages/AccountInformation';
import Preferences from './components/pages/Preferences';
import ExplanationOfBenefits from './components/pages/ExplanationOfBenefits.tsx';
import ContactUs from './components/pages/ContactUs';
import FAQs from './components/pages/FAQs';
import Footer from './components/molecules/Footer';
import Notifications from './components/molecules/Notifications';
import ScrollToTop from './components/molecules/ScrollToTop';
import VirtualCareScreenV1 from './components/pages/VirtualCare';
import ScheduleAppointmentForm from './components/pages/ScheduleAppointmentForm';
import UrgentCareAppointmentForm from './components/pages/UrgentCareAppointmentForm';
import RequestOptionForm from './components/pages/RequestOptionForm';
import NotFound from 'components/pages/NotFound';
import loadOverlayService from './services/loadOverlayService';
import SSOHandler from './components/SSOHandler';
import Prompt from './components/molecules/Prompt';
import SessionExpirationModal from './components/molecules/SessionExpirationModal';
import PcpChange from 'components/pages/PcpChange/PcpChange';
import styles from './App.module.scss';
import AccountInfomrationV2 from 'components/v2/pages/AccountInformation/AccountInformation';
import AccessNewPatientForm from 'components/pages/AccessNewPatientForm';
import { featureFlagsNames } from 'common/constants';
import useFeatureFlags from 'hooks/useFeatureFlags';
import WithFeatureFlags from 'contexts/WithFeatureFlags';
import Loading from 'components/atoms/Loading';
import Prescriptions from 'components/v2/pages/Prescriptions/Prescriptions';
import { withUiVersion } from 'hoc/withUiVersion';
import { Benefits as BenefitsV2 } from 'components/v2/pages/Benefits';
import { GetCare as VirtualCareScreenV2 } from 'components/v2/pages/GetCare';
import { HealthSummary as HealthSummaryV2 } from 'components/v2/pages/HealthSummary';
import { FormAndResources as FormAndResourcesV2 } from 'components/v2/pages/FormAndResources/FormAndResources';
import { Payments } from 'components/v2/pages/Payments';
import { HealthyRewards as HealthyRewardsV2 } from 'components/v2/pages/HealthyRewards/HealthyRewards';
import { Notifications as NotificationsPage } from 'components/v2/pages/Notifications/Notifications';
import useJSAData from 'hooks/useJSAData';
import { PCP } from 'components/v2/pages/PCP/PCP';
import HraForm from 'components/pages/HRAForm/HraForm';
import HealthRiskAssessment from 'components/pages/HRAForm/HealthRiskAssessment';
import useUnmountSubject from 'hooks/useUnmountSubject';
import { takeUntil } from 'rxjs';
import JsaAppointmentForm from 'components/pages/JsaAppointmentForm/JsaAppointmentForm';
import { jsaMemberInfo$ } from 'services/subjects';
import LoadingOverlay from 'components/molecules/LoadingOverlay/LoadingOverlay';

const Home = withUiVersion(HomeV1, HomeV2);
const CssTypography = withUiVersion(null, CssTypographyV2);
const HealthyRewards = withUiVersion(NotFound, HealthyRewardsV2);
const Benefits = withUiVersion(BenefitsV1, BenefitsV2);
const VirtualCareScreen = withUiVersion(VirtualCareScreenV1, VirtualCareScreenV2);
const HealthSummary = withUiVersion(HealthSummaryV1, HealthSummaryV2);
const AccountInformation = withUiVersion(AccountInformationV1, AccountInfomrationV2);
const FormAndResources = withUiVersion(PlanDocumentsV1, FormAndResourcesV2);
const IdCard = withUiVersion(IdCardV1, IdCardV2);

library.add(
    far,
    faFolderOpen,
    faFilePdf,
    faSearch,
    faEnvelope,
    faWallet,
    faStethoscope,
    faCreditCard,
    faPrescriptionBottleAlt,
    faConciergeBell,
    faUserCircle,
    faChevronDown,
    faPlusCircle,
    faLongArrowAltLeft,
    faArrowsAltV,
    faLongArrowAltUp,
    faLongArrowAltDown,
    faReply,
    faTrashAlt,
    faDownload,
    faCircleNotch,
    faCheckCircle,
    faTimesCircle,
    faSort,
    faSortUp,
    faSortDown
);

const App = () => {
    const [loadMessage, setLoadMessage] = useState('');
    const [showLoadOverlay, setShowLoadOverlay] = useState(false);
    const [enableJSARequestPage, setEnableJSARequestPage] = useState(false);
    const unmountSubscriptions$ = useUnmountSubject();
    const { loading } = useJSAData();
    const { featureFlags, isFeatureFlagLoading } = useFeatureFlags();

    useEffect(() => {
        loadOverlayService.show$.pipe(takeUntil(unmountSubscriptions$)).subscribe((message) => {
            setLoadMessage(message);
            setShowLoadOverlay(true);
        });
        loadOverlayService.hide$.pipe(takeUntil(unmountSubscriptions$)).subscribe(() => {
            setLoadMessage('');
            setShowLoadOverlay(false);
        });
        jsaMemberInfo$.subscribe({
            next: (jsaInfo) => {
                if (jsaInfo !== null) {
                    setEnableJSARequestPage(!jsaInfo.displayPendingBubble && jsaInfo.isJSA);
                }
            },
        });
    }, []);

    if (document.location.pathname.startsWith('/sso')) {
        return (
            <Routes>
                <Route path="/sso" element={<SSOHandler />} />
            </Routes>
        );
    }

    return (
        <ScrollToTop>
            <div>
                <LoadingOverlay
                    active={showLoadOverlay || loading}
                    spinner
                    className={styles.overlay}
                    text={loadMessage}
                />
                <main className={styles['loading-overlay']}>
                    <SessionExpirationModal />
                    <Notifications />
                    <Prompt />
                    <WithFeatureFlags>
                        <CssTypography />
                        <Layout>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/virtual-care" element={<VirtualCareScreen />} />
                                <Route path="/patient-form" element={<AccessNewPatientForm />} />
                                <Route
                                    path="/appointment-form"
                                    element={<ScheduleAppointmentForm />}
                                />
                                <Route
                                    path="/urgent-care-appointment-form"
                                    element={<UrgentCareAppointmentForm />}
                                />
                                <Route
                                    path="/appointment-request"
                                    element={<RequestOptionForm />}
                                />
                                {enableJSARequestPage && (
                                    <Route
                                        path="/jsa-appointment-request"
                                        element={enableJSARequestPage ? <JsaAppointmentForm /> : <NotFound />}
                                    />
                                )}
                                <Route
                                    exact
                                    path="/account-information"
                                    element={<AccountInformation />}
                                />
                                <Route path="/benefits" element={<Benefits />} />
                                <Route path="/claims" element={<Claims />} />
                                <Route
                                    path="/communication-preferences"
                                    element={<Preferences />}
                                />
                                <Route path="/health-summary" element={<HealthSummary />} />
                                <Route path="/medications" element={<Medications />} />
                                <Route path="/labs" element={<Labs />} />
                                <Route path="/id-card" element={<IdCard />} />
                                <Route
                                    path="/message-center"
                                    element={
                                        <>
                                            {isFeatureFlagLoading ? (
                                                <Loading />
                                            ) : featureFlags[
                                                  featureFlagsNames.crmInteractionMessageCenter
                                              ] ? (
                                                <MessageCenter />
                                            ) : (
                                                <LegacyMessageCenter />
                                            )}
                                        </>
                                    }
                                />
                                <Route
                                    path="/message-center/new"
                                    element={
                                        <>
                                            {isFeatureFlagLoading ? (
                                                <Loading />
                                            ) : featureFlags[
                                                  featureFlagsNames.crmInteractionMessageCenter
                                              ] ? (
                                                <MessageCenter newMessage />
                                            ) : (
                                                <LegacyMessageCenter />
                                            )}
                                        </>
                                    }
                                />
                                <Route path="/out-of-pocket" element={<OutOfPocket />} />
                                <Route path="/plan-documents" element={<FormAndResources />} />
                                <Route
                                    path="/explanation-of-benefits"
                                    element={<ExplanationOfBenefits />}
                                />
                                <Route path="/contact-us" element={<ContactUs />} />
                                <Route path="/faq" element={<FAQs />} />
                                <Route
                                    path="/health-risk-assessment"
                                    element={
                                        isFeatureFlagLoading ? (
                                            <Loading />
                                        ) : featureFlags[
                                              featureFlagsNames.HEALTH_RISK_ASSESSMENT
                                          ] ? (
                                            <HealthRiskAssessment />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="/health-risk-assessment-form"
                                    element={
                                        <>
                                            {isFeatureFlagLoading ? (
                                                <Loading />
                                            ) : featureFlags[
                                                  featureFlagsNames.HEALTH_RISK_ASSESSMENT
                                              ] ? (
                                                <HraForm />
                                            ) : (
                                                <NotFound />
                                            )}
                                        </>
                                    }
                                />
                                {featureFlags &&
                                    !featureFlags[featureFlagsNames.disablePcpChange] && (
                                        <Route path="/pcp-change" element={<PcpChange />} />
                                    )}
                                {featureFlags &&
                                    featureFlags[featureFlagsNames.avaNextUiVersion] && [
                                        <Route key="pcp" path="/pcp/:id" element={<PCP />} />,
                                        <Route
                                            key="prescriptions"
                                            path="/prescriptions"
                                            element={<Prescriptions />}
                                        />,
                                        <Route
                                            path="/payments"
                                            key="payments"
                                            element={<Payments />}
                                        />,
                                        <Route
                                            exact
                                            path="/notifications"
                                            key="notifications"
                                            element={<NotificationsPage />}
                                        />,
                                    ]}
                                <Route path="/healthy-rewards" element={<HealthyRewards />} />
                                <Route element={<NotFound />} />
                            </Routes>
                        </Layout>
                    </WithFeatureFlags>
                    <Footer />
                </main>
            </div>
        </ScrollToTop>
    );
};

export default App;
