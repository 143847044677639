import { Container } from 'components/v2/atoms/Container/Container';
import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

export const PCP = memo(() => {
    const params = useParams();
    return (
        <Container>
            <header>
                <h1 className="page-title">PCP {params.id}</h1>
            </header>
        </Container>
    );
});
