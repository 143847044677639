import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import PageHeader from 'components/molecules/PageHeader';
import { Button } from 'react-bootstrap';
import Loading from 'components/atoms/Loading';
import { healthRiskAssessmentService } from 'services/HealthRiskAssessmentService';
import withMemberData from 'hoc/withMemberData';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { navigateToBlobLink } from 'common/utility';
import loadOverlayService from 'services/loadOverlayService';
import { submittedHRA$ } from 'services/subjects';
import { SuccessModal } from './SuccessModal';
import { Navigate, useNavigate } from 'react-router-dom';

interface Props {
    member: any;
}

type MemberAssessment = false | 'Completed' | 'Started';

const HealthRiskAssessment = ({ member }: Props) => {
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [memberAssessment, setMemberAssessment] = useState<any>(false);
    const [hasMemberAssessment, setHasMemberAssessment] = useState<MemberAssessment>(false);
    const [successModal, setSuccessModal] = useState(true);
    const COMPLETE_STATUS = 3;
    const HRA_FORM_TYPE = 10;

    const getHRAPDF = () => {
        loadOverlayService.show('Downloading pdf');
        const pdfSub = healthRiskAssessmentService
            .getAssessmentPDF$(memberAssessment?.id)
            .subscribe({
                next: (response) => {
                    if (response) {
                        navigateToBlobLink(response.response);
                    } else {
                        console.error('Error while retrieving form template');
                    }
                    loadOverlayService.hide();
                },
                error: () => {
                    loadOverlayService.hide();
                },
            });

        setSubscriptions((prev) => [...prev, pdfSub]);
    };

    useEffect(() => {
        const sub = submittedHRA$.subscribe((display) => {
            return setSuccessModal(display ? true : false);
        });

        return () => {
            sub.unsubscribe();
        };
    }, []);
    const getMemberExistingHRA = () => {
        setLoading(true);
        const assessmentSub = healthRiskAssessmentService
            .getMemberAssessments$(member?.member_number)
            .subscribe({
                next: (response: any) => {
                    const healthRiskAssessments = response?.filter(
                        (a: any) => a.type == HRA_FORM_TYPE
                    );
                    if (healthRiskAssessments?.length == 0) {
                        setHasMemberAssessment(false);
                    } else {
                        const completeAssessment = healthRiskAssessments.find(
                            (a: any) => a.status == COMPLETE_STATUS
                        );
                        if (completeAssessment) {
                            setHasMemberAssessment('Completed');
                            setMemberAssessment(completeAssessment);
                        } else {
                            setHasMemberAssessment('Started');
                        }

                    }
                    setLoading(false);
                },
                error: () => {
                    setLoading(false);
                },
            });
        setSubscriptions((prev) => [...prev, assessmentSub]);
    };

    useEffect(() => {
        if (member) {
            getMemberExistingHRA();
        }
    }, [member]);

    useEffect(() => {
        return () => {
            subscriptions.forEach((sub) => sub.unsubscribe());
        };
    }, []);

    if (loading) return <Loading />;

    const getAssessmentInfo = () => {
        if (hasMemberAssessment == 'Completed') {
            return (
                <>
                    <label style={{ marginRight: '10px' }}>
                        {`You have successfully submitted your Health Risk Assessment on ${moment(
                            memberAssessment?.lastUpdatedAt
                        ).format(
                            'YYYY-MM-DD'
                        )}. You can download the PDF to review your responses by clicking 'Download PDF' button below. `}
                    </label>
                    <Button onClick={getHRAPDF} variant="primary" size="lg">
                        Download PDF
                    </Button>
                </>
            );
        } else {
            return (
                <>
                    <label style={{ marginRight: '10px' }}>
                        You have an HRA in progress. Please call <strong>1-833-999-0980</strong>, TTY 711 to complete
                        the assessment.
                    </label>
                </>
            );
        }
    };

    const onCloseModal = () => {
        submittedHRA$.next(false);
    };
    if(member && !member.isSNP) {
        return <Navigate to="/" />
    }

    return (
        <Container>
            <SuccessModal show={successModal} handleAlertClose={onCloseModal} />
            <PageHeader>
                <span className="bold-text">Health Risk Assessment</span>
            </PageHeader>
            <p>
                Keeping you healthy is our top priority. Please allow 15 minutes to complete the
                free health risk assessment below. This confidential questionnaire will help our
                care team better understand your health needs and update your personalized care plan
                accordingly.
            </p>
            <p>
                The health risk assessment asks about your medical history, lifestyle, and any
                health concerns you may have. Your responses will not affect your health coverage in
                any way. We use this information solely to provide you with the best possible care
                and to identify any potential health risks or gaps in care. Call our Care Management
                team at <strong>833-835-5482</strong> if you need help completing your assessment.
            </p>
            <div className="text-center mt-5">
                {hasMemberAssessment ? (
                    getAssessmentInfo()
                ) : (
                    <Button
                        onClick={() => {
                            navigate('/health-risk-assessment-form');
                        }}
                        variant="primary"
                        size="lg"
                    >
                        Start Assessment
                    </Button>
                )}
            </div>
        </Container>
    );
};

export default withMemberData(HealthRiskAssessment);
