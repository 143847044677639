import React from 'react';
import classNames from './DropdownNotification.module.scss';
import { MemberNotification } from 'types/notifications';
import { ReactComponent as PrescrtionNotification } from 'icons/v2/my-prescriptions.svg';
import { ReactComponent as MyHealthNotification } from 'icons/v2/my-health.svg';
import { ReactComponent as AppointmentNotification } from 'icons/v2/get-care.svg';
import moment from 'moment';
import { getDescriptionWithLocalTimezoneDates } from 'common/utility';
import { NotificationDot } from 'components/atoms/NotificationDot/NotificationDot';

interface Props {
    notification: MemberNotification;
    dropdownStyle?: 'legacy' | '';
}

const getNotificationIcon = (
    type: any,
    size = 20,
    isLegacyStyle = false,
    isRead: boolean
): React.ReactNode => {
    if (isLegacyStyle) {
        return isRead ? <div style={{width: '1.1vw', height: '1.1vw'}}/> : <NotificationDot />;
    }
    type NotificationIconType = {
        [key: number]: JSX.Element;
    };

    const NotificationIcon: NotificationIconType = {
        0: (
            <AppointmentNotification
                className={classNames['appointment-icon']}
                style={{ width: size, height: size }}
            />
        ),
        1: (
            <PrescrtionNotification
                className={classNames['prescription-icon']}
                style={{ width: size, height: size }}
            />
        ),
        2: (
            <MyHealthNotification
                className={classNames['my-health-icon']}
                style={{ width: size, height: size }}
            />
        ),
        3: (
            <AppointmentNotification
                className={classNames['appointment-icon']}
                style={{ width: size, height: size }}
            />
        ),
        5: (
            <AppointmentNotification
                className={classNames['appointment-icon']}
                style={{ width: size, height: size }}
            />
        )
    };

    return NotificationIcon[type];
};

export const getNotificationDateFormat = (date: string) => {
    return moment(date).format('MMMM D YYYY, h:mm a');
};

export const DropdownNotification = ({ notification, dropdownStyle = '' }: Props) => {
    const isLegacyStyle = dropdownStyle == 'legacy';
    const iconPosition = isLegacyStyle ? 'center' : 'top';
    return (
        <div className={classNames['notification-container']}>
            <div style={{ display: 'flex', alignItems: iconPosition }}>
                {getNotificationIcon(notification.type, 35, isLegacyStyle, notification.isRead)}{' '}
            </div>
            <div className={classNames['notification-container-description']}>
                <span className={classNames[`notification-title-${dropdownStyle}`]}>
                    {' '}
                    {notification.title}{' '}
                </span>
                <span className={classNames[`notification-description-${dropdownStyle}`]}>
                    {' '}
                    {getDescriptionWithLocalTimezoneDates(notification.description)}{' '}
                </span>
                <span className={classNames[`notification-date-${dropdownStyle}`]}>
                    {' '}
                    {getNotificationDateFormat(notification.date)}{' '}
                </span>
            </div>
        </div>
    );
};
