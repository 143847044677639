import React, {useState} from 'react'
import styles from "./Pagination.module.scss"
import { Row, Col } from 'reactstrap';
import { BiCaretLeft, BiCaretRight } from 'react-icons/bi';
import { handleEnterKey } from 'common/accessibilityHelpers';
import { ReactComponent as PaginationArrow } from 'icons/v2/pagination-arrow.svg';

interface EdgePagerProps {
    component: any;
    onClick: any;
    disabled: any;
    specialStyle?: string;
}
const EdgePager = ({ component: Component, onClick, disabled, specialStyle = '', ...rest }: EdgePagerProps) => (
    <Component
        onClick={onClick}
        className={`${styles['page-movement']} ${disabled ? styles['disabled-icon'] : ''} ${styles[specialStyle]}`}
        {...rest}
        tabIndex={disabled ? '-1' : '0'}
        onKeyUp={handleEnterKey(onClick)}
    />
);

export type PageSetting = { pageSize: number, pageCount: number }

interface Props {
    pageNumber:number;
    setPageNumber: (pageNumber: number)=>void;
    tableSize:number;
    pageSettings:PageSetting;
}


export const Pagination = ({pageNumber, setPageNumber, tableSize, pageSettings}:Props) => {

    const [pageNumbers, setPageNumbers] = useState<any>([])

   const getLastPageNumber = (tableSize:number) => {
        return Math.ceil(tableSize / pageSettings.pageSize);
    };

    const getPageNumbers = (startPageNumber:any) => {
        const { pageCount } = pageSettings;

        if (pageNumbers && pageNumbers.includes(startPageNumber)) {
            return pageNumbers;
        }

        if (startPageNumber === getLastPageNumber(tableSize)) {
            startPageNumber = Math.max(1, startPageNumber - pageCount + 1);
        }

        var maxPageNum = Math.min(
            getLastPageNumber(tableSize),
            startPageNumber + pageCount - 1
        );

        return Array.from(Array(maxPageNum).keys()).slice(startPageNumber - 1).map((index) => index + 1);
    };

    const onClickPageNumber = (pageNumber: number) => {
        if (pageNumber < 1 || pageNumber > getLastPageNumber(tableSize)) {
            return;
        }

        setPageNumber(pageNumber);
    };


    
  return (
      <Row>
          <Col>
              <EdgePager
                  component={PaginationArrow}
                  disabled={pageNumber === 1}
                  onClick={() => onClickPageNumber(1)}
              />
              <EdgePager
                  component={BiCaretLeft}
                  disabled={pageNumber === 1}
                  onClick={() => onClickPageNumber(pageNumber - 1)}
              />
              {getPageNumbers(pageNumber).map((number: number) => (
                  <span
                      className={`${styles['page-num']} ${
                          pageNumber === number ? styles['selected-page']: ''
                      } `}
                      key={number}
                      onClick={() => onClickPageNumber(number)}
                      onKeyUp={handleEnterKey(() => onClickPageNumber(number))}
                      tabIndex={0}
                  >
                      {number}
                  </span>
              ))}
              <EdgePager
                  component={BiCaretRight}
                  disabled={
                      getLastPageNumber(tableSize) === 0 ||
                      pageNumber === getLastPageNumber(tableSize)
                  }
                  onClick={() => onClickPageNumber(pageNumber + 1)}
              />
              <EdgePager
               specialStyle={'last-page-icon'}
                  component={PaginationArrow}
                  disabled={
                      getLastPageNumber(tableSize) === 0 ||
                      pageNumber === getLastPageNumber(tableSize)
                  }
                  onClick={() => onClickPageNumber(getLastPageNumber(tableSize))}
              />
          </Col>
          <Col>
              <span className={styles['page-info']}>
                  {getLastPageNumber(tableSize) === 0 ? 0 : pageNumber} of{' '}
                  {getLastPageNumber(tableSize)} pages ({tableSize} items)
              </span>
          </Col>
      </Row>
  );
}
