import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Icon from '../../atoms/Icon';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import FormHOC from '../../Form/FormHOC';
import loadOverlayService from '../../../services/loadOverlayService';
import jsaAppointmentService from '../../../services/jsaAppointmentService';
import { createdJsaRequest$ } from '../../../services/subjects';
import { getMeetingTimeStringFromDateTime, getTimeZone, getDateTimeFormat } from 'common/utility';
import { pick } from 'lodash';
import CheckboxQuestion from '../../molecules/input/CheckboxQuestion';
import styles from '../ScheduleAppointmentForm.module.scss';
import useJSAData from 'hooks/useJSAData';
import { useNavigate } from 'react-router-dom';

interface Props {
    prevPageAction: any;
    state: any;
    onSubmit: any;
    props: any;
    [key: string]: any;
}
const FormContent = ({ state, prevPageAction, props, ...rest }: Props) => {
    const { control, getValues } = useFormContext();
    const [subscriptions, setSubscriptions] = useState<any>([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const { getJSAData } = useJSAData();
    const navigate = useNavigate();
    useEffect(() => {
        return () => {
            subscriptions.forEach((sub: any) => sub.unsubscribe());
        };
    }, []);

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };

    const handleJsaConfirmRequest = () => {
        const { member } = props;
        const requestData = {
            memberId: member.member_number,
            memberFirstName: member.first_name,
            memberLastName: member.last_name,
            memberFullName: member.fullName,
            memberPhone: state.patientPhone,
            memberEmail: state.patientEmail,
            requestNotes: state.appointmentNotes,
            requestReason: 6, //JSA reason
            Status: 1,
            preferredDate: state.preferredDate,
            preferredTimeOfDay: state.preferredTimeOfDay,
            translatorPreferredLanguage: state.translatorPreferredLanguage,
            translatorRequired: state.translatorRequired,
        };
        loadOverlayService.show('Submitting...');

        const requestSubscription = jsaAppointmentService.createJsaRequest$(requestData).subscribe({
            next: () => {
                getJSAData();
                loadOverlayService.hide();
                // @ts-ignore
                createdJsaRequest$.next(requestData);
                navigate('/virtual-care');
            },
            error: (e) => {
                loadOverlayService.hide();
                setShowErrorModal(true);
            },
        });
        setSubscriptions([...subscriptions, requestSubscription]);
    };

    return (
        <>
            <div className={styles['calendar-container']} data-testid="step4">
                <Row>
                    <Col
                        sm="6"
                        className="text-center d-flex justify-content-center align-items-center"
                    >
                        <Icon icon={faCalendar} className={styles.calendar} />
                    </Col>
                    <Col sm="6">
                        <div>
                            <small>
                                <span className="bold-text">
                                    {state.preferredDate !== undefined && state.preferredDate !== ''
                                        ? 'Requested Date & Time Of Day'
                                        : 'Date & Time'}
                                </span>
                            </small>
                        </div>
                        <div>
                            {state.isAppointmentForm ? (
                                <span
                                    className="bold-text"
                                    data-testid={`dateConfirmation:${getMeetingTimeStringFromDateTime(
                                        state.timeSlot.date,
                                        {
                                            ...state.timeSlot,
                                            duration: state.reason.duration,
                                        }
                                    )}`}
                                >
                                    {getDateTimeFormat(
                                        state.timeSlot.startTime,
                                        'dddd, MMMM D, YYYY'
                                    )}
                                    <br />
                                    {getDateTimeFormat(state.timeSlot.startTime, 'h:mm A')}{' '}
                                    {getTimeZone()}
                                </span>
                            ) : state.preferredDate !== undefined && state.preferredDate !== '' ? (
                                <span>
                                    {`${state.preferredDate.toLocaleString('default', {
                                        month: 'long',
                                        day: 'numeric',
                                        year: 'numeric',
                                    })} - ${state.preferredTimeOfDay.map((time: any) => {
                                        return props.preferredTimes[time];
                                    })}`}
                                </span>
                            ) : (
                                <span>Request Pending</span>
                            )}
                        </div>
                        <br />
                        <div>
                            <small>
                                <span className="bold-text">Appointment Reason</span>
                            </small>
                        </div>
                        <div>
                            <span
                                className="bold-text"
                                data-testid={`reasonConfirmation:6`}
                            >
                                {'Annual Checkup'}
                            </span>
                        </div>
                        <br />
                        <div>
                            <small>
                                <span className="bold-text">Visit Type</span>
                            </small>
                        </div>
                        <div>
                            <span
                                className="bold-text"
                                data-testid={`typeConfirmation:${state.type}`}
                            >
                                {props.visitTypes[state.type]} Appointment
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <article className={`${styles.question} m-5`} aria-label={'consent-checkbox'}>
                        <Controller
                            control={control}
                            name="consent"
                            defaultValue={1}
                            render={({ field: { onChange, onBlur, value }}) => (
                                <CheckboxQuestion
                                    value={value}
                                    onChange={onChange}
                                    question={{
                                        info: (
                                            <>
                                                <span>
                                                    <strong>
                                                        By confirming this appointment, I agree to
                                                        receive care from a provider in person or by
                                                        video and consent to receive health care
                                                        services via telehealth.
                                                    </strong>
                                                </span>
                                                <br />
                                                <br />
                                                <span>
                                                    I understand that I can call 844-215-2443 for
                                                    help with scheduling or to request an
                                                    appointment. Copays may apply to in-person
                                                    visits based on my plan benefit package.
                                                </span>
                                            </>
                                        ),
                                    }}
                                    option={{ label: 'consent', value: 1 }}
                                    disabled={true}
                                />
                            )}
                        />
                    </article>
                </Row>
            </div>
            <div className="text-center">
                <Button
                    onClick={() => {
                        navigate('/virtual-care');
                    }}
                    variant="secondary"
                    size="lg"
                    className="m-4"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => prevPageAction(getValues())}
                    variant="secondary"
                    size="lg"
                    className="m-4"
                >
                    Back
                </Button>
                <Button
                    onClick={handleJsaConfirmRequest}
                    variant="primary"
                    size="lg"
                    className="m-4"
                >
                    Confirm
                </Button>
            </div>
            <Modal centered show={showErrorModal} onHide={handleCloseErrorModal}>
                <Modal.Body>
                    {`We're sorry. There was an issue with booking your appointment. Please try again
                    later.`}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseErrorModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const JsaAppointmentSubmitStep = ({ state, onSubmit, props, ...rest }: Props) => {
    const valuePaths = ['consent'];

    return (
        <FormHOC formDefaultValues={pick(state, valuePaths)}>
            <FormContent onSubmit={onSubmit} state={state} props={props} {...rest} />
        </FormHOC>
    );
};

// @ts-ignore
export default JsaAppointmentSubmitStep;
