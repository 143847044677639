import React, {useState, useContext} from 'react'
import { Container } from 'components/v2/atoms/Container/Container';
import { SectionTitle } from 'components/v2/atoms/SectionTitle/SectionTitle';
import styles from './Notifications.module.scss';
import { NotificationsList } from 'components/organisms/NotificationsList/NotificationsList';
import { MemberPortalConfigContext } from 'contexts/MemberPortalConfigContext';
import { BackButton } from 'components/v2/molecules/BackButton/BackButton';

export const Notifications = () => {
const [archivedView, setArchivedView] = useState(false)
const pageTitle = archivedView ? 'ARCHIVED NOTIFICATIONS' : 'NOTIFICATIONS'
const { archiveNotification, memberNotifications } =
useContext(MemberPortalConfigContext);

 const {archivedNotifications, notifications} = memberNotifications;
  return (
      <Container>
          <BackButton show={archivedView} onClick={() => setArchivedView(!archivedView)} />
          <div className={styles['notifications-header-container']}>
              <SectionTitle as="h1" blueSwoosh={false}>
                  {pageTitle}
              </SectionTitle>
              {!archivedView && ( <span
                  className={styles['notification-switch-text']}
                  onClick={() => setArchivedView(!archivedView)}
              >
                  Archived Notifications
              </span>)}
             
          </div>

          {archivedView ? (
              <NotificationsList
              key={"NotificationsList archived"}
                  onArchiveNotification={archiveNotification}
                  notifications={archivedNotifications}
              />
          ) : (
              <NotificationsList
              key={"NotificationsList all"}
                  onArchiveNotification={archiveNotification}
                  notifications={notifications}
              />
          )}
      </Container>
  );
}

