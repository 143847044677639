import { ModeEdit, Clear, SaveAs } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import withRelationships from 'hoc/withRelationShips';
import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Input, FormGroup } from 'reactstrap';
import { emergencyContactService } from 'services/emergencyContactService';
import AlertMessage from './AlertMessage';
import styles from './EmergencyContactForm.module.scss';

const EmergencyContactForm = (props) => {
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [relationships, setRelationships] = useState([{
        value: '',
        description: 'Select One',
    }]);
    const [formData, setFormData] = useState({
        emergencyContactRelation: null,
        emergencyContactFullName: null,
        emergencyContactPhoneNumber: null,
        emergencyContactPhoneNumberType: null,
        emergencyContactEmail: null,
    });
    const [alertResult, setAlertResult] = useState({
        visible: false,
        color: null,
        onDismiss: () => {
            setAlertResult((currentState) => ({ ...currentState, visible: false }));
        },
        message: null,
    });

    const mapRelationship = (defaultRelationship) => {
        let finalRelationship;
        if (!defaultRelationship) {
            return;
        }

        relationships.map((relationship) => {
            if (relationship.value.toLowerCase() === defaultRelationship.toLowerCase()) {
                finalRelationship = relationship.value;
                return;
            }
        });

        return finalRelationship;
    };

    const onChangeForm = (property, value) => {
        const tempForm = { ...formData };

        tempForm[property] = value;

        setFormData(tempForm);
    };

    const isValidPhone = (phone) => {
        if (phone.length === 0) {
            return true;
        }
        if (phone.length < 10 || phone.length > 14) {
            return false;
        }

        return (
            /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(phone) ||
            /^(\([0-9]{3}\)|[0-9]{3})[0-9]{3}[0-9]{4}$/.test(phone)
        );
    };

    const handleSubmit = () => {
        const finalForm = { ...formData };

        const phoneValidated = isValidPhone(finalForm.emergencyContactPhoneNumber);

        if (!phoneValidated) {
            setAlertResult((currentState) => ({
                ...currentState,
                visible: true,
                color: 'danger',
                message: 'Error! - Please provide a valid US phone number',
            }));
            return;
        }

        if (!finalForm.emergencyContactRelation) {
            setAlertResult((currentState) => ({
                ...currentState,
                visible: true,
                color: 'danger',
                message: 'Error! - Please select one option for field relationship',
            }));
            return;
        }

        if (finalForm.emergencyContactPhoneNumber != null) {
            finalForm.emergencyContactPhoneNumberType = 'Phone';

            setFormData((currentState) => ({
                ...currentState,
                emergencyContactPhoneNumberType: 'phone',
            }));
        }

        // the current relationship is received in a different format than the required for the request
        finalForm.emergencyContactRelation = mapRelationship(finalForm.emergencyContactRelation);

        emergencyContactService.processEmergencyContact$(finalForm, props.memberId).subscribe({
            next: () => {
                setAlertResult((currentState) => ({
                    ...currentState,
                    visible: true,
                    color: 'primary',
                    message:
                        'Done! - Your request has been submitted, but it can take up to 24 hours to see any changes.',
                }));

                setSaveEnabled(!saveEnabled);
            },
            error: () => {
                setAlertResult((currentState) => ({
                    ...currentState,
                    visible: true,
                    color: 'danger',
                    message:
                        'Error! - There was an issue updating you contact, please reach out an agent or try again.',
                }));
            },
        });
    };

    useEffect(() => {
        if (props.relationships) {
            setRelationships([...relationships, ...props.relationships]);
        }
    }, [props.relationships]);

    useEffect(() => {
        if (props.emergencyContact) {
            setFormData({
                emergencyContactRelation: props.emergencyContact.relationship?.toUpperCase(),
                emergencyContactFullName: props.emergencyContact.fullName,
                emergencyContactPhoneNumber: props.emergencyContact.phone,
                emergencyContactEmail: props.emergencyContact.email,
            });
        }
    }, [props.emergencyContact]);
    return (
        <div className="mb-4">
            <div className={styles['container']}>
                <h3>Emergency Contact</h3>
                {saveEnabled ? (
                    <div>
                        <IconButton aria-label="save" onClick={handleSubmit}>
                            <SaveAs fontSize="large" />
                        </IconButton>
                        <IconButton
                            aria-label="cancel"
                            onClick={() => setSaveEnabled(!saveEnabled)}
                        >
                            <Clear />
                        </IconButton>
                    </div>
                ) : (
                    <IconButton
                        aria-label="Update info"
                        onClick={() => setSaveEnabled(!saveEnabled)}
                    >
                        <ModeEdit />
                    </IconButton>
                )}
            </div>
            <Row>
                <Col md="4">
                    <FormGroup>
                        <Label>Relation to member</Label>
                        <Input
                            id={`emergency_contact_relationship`}
                            name="relationship"
                            type="select"
                            value={formData.emergencyContactRelation || ''}
                            disabled={!saveEnabled}
                            onChange={(e) =>
                                onChangeForm('emergencyContactRelation', e.target.value)
                            }
                        >
                            {relationships &&
                                relationships.map((relationship, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={relationship.value.toUpperCase()}
                                        >
                                            {relationship.description.toUpperCase()}
                                        </option>
                                    );
                                })}
                        </Input>
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label>Emergency Contact Name</Label>
                        <Input
                            id="emergency_contact_name"
                            name="name"
                            defaultValue={formData.emergencyContactFullName}
                            disabled={!saveEnabled}
                            onChange={(e) =>
                                onChangeForm('emergencyContactFullName', e.target.value)
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label>Emergency Contact Phone</Label>
                        <Input
                            id="emergency_contact_phone"
                            name="phone"
                            defaultValue={formData.emergencyContactPhoneNumber}
                            type="number"
                            disabled={!saveEnabled}
                            onChange={(e) =>
                                onChangeForm('emergencyContactPhoneNumber', e.target.value)
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label>Emergency Contact Email</Label>
                        <Input
                            id="emergency_contact_email"
                            name="email"
                            defaultValue={formData.emergencyContactEmail}
                            type="email"
                            disabled={!saveEnabled}
                            onChange={(e) => onChangeForm('emergencyContactEmail', e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <AlertMessage
                isOpen={alertResult.visible}
                toggle={alertResult.onDismiss}
                color={alertResult.color}
                message={alertResult.message}
            />
        </div>
    );
};

export default withRelationships(EmergencyContactForm);

export { EmergencyContactForm };
