import { useState, useEffect } from 'react';
import { PageSetting } from 'components/v2/organisms/Pagination/Pagination';
import { Observable } from 'rxjs';

type UseServerPaginationArgs<TData> = {
    initialTake: number;
    getData: (
        skip: number,
        take: number,
        sortBy?: string,
        sortDirection?: 'desc' | 'asc',
    ) => Observable<{ page: TData[]; totalCount: number }>;
    onChangeData?: (data: TData[]) => void;
    initialSortBy?: string;
    initialSortDirection?: 'desc' | 'asc';
};

export const useServerPagination = <TData>({
    initialTake,
    getData,
    onChangeData,
    initialSortBy,
    initialSortDirection,
}: UseServerPaginationArgs<TData>) => {
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [take, setTake] = useState(initialTake);
    const [sortBy, setSortBy] = useState(initialSortBy || 'createdAt');
    const [sortDirection, setSortDirection] = useState<'desc' | 'asc'>(initialSortDirection || 'desc');
    const [data, setData] = useState<TData[]>([]);
    const [dataLoadedOnce, setDataLoadedOnce] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const subscription = getData((page - 1) * take, take, sortBy, sortDirection).subscribe({
            next: (data) => {
                setCount(data.totalCount);
                setData(data.page);
                setDataLoadedOnce(true);
                onChangeData && onChangeData(data.page);
                setLoading(false);
            },
            error: () => {
                setLoading(false);
            }
        });
        return () => subscription.unsubscribe();
    }, [page, take, sortBy, sortDirection]);
    // Sort parameters are still untested please remove this comment once you have an implementation for them

    return {
        page,
        setPage,
        count,
        setCount,
        take,
        setTake,
        sortBy,
        setSortBy,
        sortDirection,
        setSortDirection,
        data,
        setData,
        loading,
        setLoading,
        dataLoadedOnce,
    };
};

export default useServerPagination;
