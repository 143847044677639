import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { jsaMemberInfo$ } from 'services/subjects';
import { AUDITEVENTTYPE } from 'services/trackService';
import { sendCustomEvent } from 'common/utility';
import { useNavigate } from 'react-router-dom';

interface Props {
    member: any;
    displayJSAAlert: boolean;
}
const JsaNotification = ({ member }: Props) => {
    const [visible, setVisible] = useState(false);
    const isAvaHMO = member?.isVirtual && member?.plan_type == 'HMO';
    const redirectTo = isAvaHMO ? '/appointment-form' : '/jsa-appointment-request';
    const fromJSAAlertFlag = true;
    const navigate = useNavigate();

    useEffect(() => {
        const sub = jsaMemberInfo$.subscribe((display) => {

            return setVisible(display.displayAlert);
        });

        return () => {
            sub.unsubscribe();
        };
    }, []);

    if (!member) return <></>;

    return (
        <Alert isOpen={visible} color="success" style={{ marginTop: 16 }}>
            <div style={{ display: 'flex' }}>
                {' '}
                <p style={{ paddingRight: 10 }}>
                    <FontAwesomeIcon icon={faBell} aria-label="jsa-noti" />
                </p>
                <div>
                    <p style={{ fontWeight: 'bold' }}>
                        It's time for your annual no-cost health checkup!{' '}
                    </p>
                    <p>
                        Earn $100 on your ACCESS On-Demand Concierge Card as a thank you for putting
                        your health first. One of the many advantages Alignment Health Plan members
                        receive is a full hour with a provider, helping ensure you're getting the
                        best care and customized benefits to your needs. We can review your health
                        care goals, help remove any barriers in your care, and support care
                        coordination where it's convenient for you - in your home or through a
                        telehealth visit. This service is offered in addition to your routine
                        primary care visits.
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                        Schedule your no-cost annual health assessment online now through your
                        member portal. Don't wait!{' '}
                        <a
                            onClick={() => {
                                sendCustomEvent({
                                    eventType: AUDITEVENTTYPE.MemberPortalJSAAlert,
                                });
                                navigate(redirectTo, {
                                    state: {
                                        data: fromJSAAlertFlag,
                                    },
                                });
                            }}
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                color: 'blue',
                            }}
                        >
                            Click here
                        </a>{' '}
                        to request an appointment with a clinician.
                    </p>{' '}
                    <p>
                        If you need assistance or would like to schedule your appointment with a
                        Jump Start Associate, please call us at (844) 215-2443.
                    </p>
                </div>
            </div>
        </Alert>
    );
};

export default JsaNotification;
