import React from 'react';
import GetCareImg from '../../../assets/Get-Care.png';
import { Banner } from 'components/v2/organisms/Banner/Banner';
import { VirtualCare } from './VirtualCare/VirtualCare';

interface GetCareProps {}

export const GetCare = ({}: GetCareProps) => {
    return (
        <div>
            <Banner
                width={'45%'}
                image={GetCareImg}
                title="GET CARE"
                description="Welcome to your care overview, Your well-being is our priority, and we’re here to ensure that you have the information and resources you need to make informed decisions about your medical care."
                color="#68D0DE"
            />
            <VirtualCare />
        </div>
    );
};
