import React, { useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import useObservable from '../../../../hooks/useObservable';
import { Loader } from '../../atoms/Loader/Loader';
import PageHeader from '../../../molecules/PageHeader';
import { LinkedPost } from '../../../molecules/Post';

import styles from './VirtualCare.module.scss';
import { AppointmentGrids } from './AppointmentGrids/AppointmentGrids';
import { UpcomingAppointmentCards } from './UpcomingAppointmentCards/UpcomingAppointmentCards';
import { virtualCareService } from 'services/virtualCareService';
import { map } from 'rxjs/operators';

interface VirtualCareProps {}

export const VirtualCare = ({}: VirtualCareProps) => {
    const virtualCareInfo$ = useMemo(() => virtualCareService.virtualCareInfo$(), []);

    const canBookWithPcp = useObservable<boolean | null>(
        virtualCareInfo$.pipe(map((info) => info.canBookWithPcp)),
        null
    );

    const pcpName = useObservable<string | null>(
        virtualCareInfo$.pipe(map((info) => info.pcpName)),
        null
    );

    return (
        <Container>
            <Row>
                <Col>
                    <PageHeader className={styles.title} data-testid="getVirtualCareTitle">
                        Get Virtual Care
                    </PageHeader>
                    <div className={styles.section} data-testid="getVirtualCareDescription">
                        <p className={styles.p}>Book an appointment with a doctor now.</p>
                    </div>
                    <div className={styles.section}>
                        <Row className={styles['virtual-care-cards']}>
                            <Col md="4" sm="12" className={styles['virtual-care-card']}>
                                {canBookWithPcp === null ? (
                                    <Loader size="md" />
                                ) : canBookWithPcp ? (
                                    <LinkedPost
                                        icon="doctor-v2"
                                        header="See Your PCP"
                                        subHeader={`Book an Appointment with ${pcpName}`}
                                        to="/appointment-form"
                                    />
                                ) : (
                                    <LinkedPost
                                        icon="doctor-v2"
                                        header="Schedule an appointment"
                                        subHeader="Connect with a Clinician"
                                        to="/appointment-request"
                                    />
                                )}
                            </Col>
                            <Col md="4" sm="12" className={styles['virtual-care-card']}>
                                <LinkedPost
                                    icon="doctor-v2"
                                    header="Get 24/7 Care"
                                    subHeader="Request the Next Available Appointment"
                                    to="/urgent-care-appointment-form"
                                />
                            </Col>
                            <Col md="4" sm="12" className={styles['virtual-care-card']}>
                                <LinkedPost
                                    icon="bell"
                                    header="Talk to Your Virtual Care Team"
                                    subHeader={`Contact an Agent Now`}
                                    to="/message-center"
                                />
                            </Col>
                        </Row>
                    </div>
                    <UpcomingAppointmentCards />
                    <AppointmentGrids />
                </Col>
            </Row>
        </Container>
    );
};
